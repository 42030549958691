import React from 'react';
import { NavLink } from 'react-router-dom';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';

const Paging = ({prevLink, prevTitle, nextLink, nextTitle}) => {

  return (
    <div className='lg:max-w-[1200px] 2xl:max-w-[1380px] m-auto'>
        <div className='grid grid-rows-1 grid-flow-col mx-4 my-8'>
          
          {/* Previous Page Section */}
          <div className='col-span-6'>
            <NavLink to={prevLink}>
              <div className='grid grid-rows-2 float-start'>
                <div>
                  <button className="text-[var(--darkgray)] p-0 hover:text-[var(--notionblue)] float-start">
                    <HiOutlineChevronLeft size={20} />
                  </button>
                </div>
                <div>
                  <h1 className='font-medium sm:text-xl tracking-tight'>{prevTitle}</h1>
                </div>
              </div>
            </NavLink>
          </div>

          {/* Next Page Section */}
          <div className='col-span-6'>
            <NavLink to={nextLink}>
              <div className='grid grid-rows-2 float-end'>
                <div>
                  <button className="text-[var(--darkgray)] p-0 hover:text-[var(--notionblue)] float-end">
                    <HiOutlineChevronRight size={20} />
                  </button>
                </div>
                <div>
                <h1 className='font-medium sm:text-xl tracking-tight'>{nextTitle}</h1>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    )
}

export default Paging