import React, { useEffect, useState, useRef} from 'react'

const CountUp = ({ start = 0, end, className }) => {
    const [count, setCount] = useState(start);
    const elementRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              let currentCount = start;
              const step = (end - start) / 100; // Adjust speed by changing 100
              const interval = setInterval(() => {
                currentCount += step;
                if (currentCount >= end) {
                  clearInterval(interval);
                  setCount(end);
                } else {
                  setCount(Math.ceil(currentCount));
                }
              }, 10); // Adjust speed by changing interval time
            }
          });
        },
        { threshold: 0.5 }
      );

      if (elementRef.current) {
        observer.observe(elementRef.current);
      }

      return () => {
        if (elementRef.current) {
          observer.unobserve(elementRef.current);
        }
      };
    }, [start, end]);

    return (
      <h1 ref={elementRef} className={className}>
        {count}x
      </h1>
    );
  };

export default CountUp