import React from 'react';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import Meta from './Meta'
import Navbar from './Navbar';

const Capabilities = () => {

  return (
    <div>
      <div>
      <Meta
          title="Notionmind® - Innovating Together for Exceptional Outcomes"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

      </div>
      {/* header */}
      <Navbar/>
      {/* header ends */}


      <div className='bg-capabilities bg-contain bg-no-repeat bg-top place-content-center mt-8 py-8 lg:py-12'>

        <div className='max-w-[1380px] flex flex-col justify-center lg:w-8/12 my-8 md:my-16 m-auto'>
          <h1 className='title-theme leading-[1.10em] tracking-[-0.06em] text-4xl sm:text-[64px] lg:text-[86px] font-medium pb-1'>Innovating Together for Exceptional Outcomes</h1>
          <p className='place-self-center mt-8 lg:mt-16 text-lg md:text-3xl text-center text-[var(--darkgray)] font-medium md:font-normal px-3 md:px-0 py-4 tracking-tighter sm:w-[75%] lg:w-[70%]'>Unlock opportunities
          and solve challenges with tailored technology solutions that drive efficiency, scalability, and innovation.
          </p>
        </div>


        <div className='mx-2 lg:mx-4 xl:mx-0 my-6'>
          <div className='lg:max-w-[1200px] 2xl:max-w-[1380px] bg-white rounded-3xl sm:rounded-[50px] m-auto py-2 sm:px-6 lg:py-8 xl:p-8 shadow-lg shadow-[#DFDFDF]'>
            {/* row 1 */}
            <div className="grid gap-6 lg:gap-8 grid-cols-1 md:grid-cols-2 px-4 sm:px-0 my-4 md:my-6 lg:my-8">
              {/* AI & Automation */}
              <div className='lg:ms-10'>
                <div className="bg-[#DFF1F0] rounded-t-xl sm:rounded-t-[30px] shadow-lg p-6 lg:p-8 flex flex-col justify-between">
                  {/* Title Section */}
                  <h2 className="text-[28px] pb-1 md:text-[42px] lg:text-[48px] md:w-[80%] lg:w-8/12 2xl:w-full tracking-tighter font-bold leading-none bg-gradient-to-r from-[#7FC8BF] via-[#018473] via-[20%] to-[#006455] bg-clip-text text-transparent">
                  AI & Automation</h2>

                  {/* Tags */}
                  <div className='justify-items-start py-4 sm:pt-6'>
                    <div className='flex gap-2'>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>ChatGPT</button>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Gemini</button>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>LLM</button>
                    </div>
                  </div>

                  {/* Description */}
                  <div className='my-8 sm:my-10 lg:my-14'>
                    <p className="text-[var(--blacktext)] tracking-tighter leading-snug text-lg lg:text-xl xl:text-2xl sm:h-20 2xl:h-24">
                    Streamline business operations and enhance productivity with intelligent automation and AI solutions.
                    </p>
                  </div>

                  {/* Link */}
                  <Link to="/case-studies" className="text-[var(--notionblue)] tracking-tighter text-sm lg:text-lg hover:underline">
                    View Case Study
                  </Link>
                </div>
                {/* Footer 018473 */}
                <div className="rounded-b-xl sm:rounded-b-[30px] border-b-[6px] border-[#7CC8BF] bg-gradient-to-r from-[#7FC8BF] via-[#018473] via-[20%] to-[#006455] py-4 text-white text-center">
                  <p className='text-white text-center lg:text-lg font-semibold'><span>2x </span>Increase in productivity</p>
                </div>
              </div>
              {/* Data-Driven Insights */}
              <div className='lg:me-10'>
                <div className="bg-[#DBEDFF] rounded-t-xl sm:rounded-t-[30px] shadow-lg p-6 lg:p-8 flex flex-col justify-between">
                  {/* Title Section */}
                  <h2 className="text-[28px] pb-1 md:text-[42px] lg:text-[48px] tracking-tighter font-bold xl:w-[80%] 2xl:w-full leading-none bg-gradient-to-r from-[#79B8FF] via-[#005CC5] via-[20%] to-[#AD7DFF] bg-clip-text text-transparent">
                  Data-Driven Insights</h2>

                  {/* Tags */}
                  <div className='justify-items-start py-4 sm:pt-6'>
                    <div className='flex gap-2'>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Analytics</button>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Modeling</button>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Dashboards</button>
                    </div>
                  </div>

                  {/* Description */}
                  <div className='my-8 sm:my-10 lg:my-14'>
                    <p className="text-[var(--blacktext)] tracking-tighter leading-snug text-lg lg:text-xl xl:text-2xl xl:w-10/12 sm:h-20 2xl:h-24">
                    Make informed decisions with advanced analytics and actionable data insights.
                    </p>
                  </div>
                  {/* Link */}
                  <Link to="/case-studies" className="text-[var(--notionblue)] tracking-tighter text-sm lg:text-lg hover:underline">
                    View Case Study
                  </Link>

                </div>
                {/* Footer */}
                <div className="rounded-b-xl sm:rounded-b-[30px] border-b-[6px] border-[#79B8FF] bg-gradient-to-r from-[#79B8FF] via-[#2188FF] via-[20%] to-[#005CC5] py-4 text-white text-center">
                  <p className='text-white text-center lg:text-lg font-semibold'><span>2.5x </span>Improvement in decision-making</p>
                </div>
              </div>
            </div>

            {/* row 2 */}
            <div className="grid gap-6 lg:gap-8 grid-cols-1 md:grid-cols-2 px-4 sm:px-0 my-8 lg:my-10 xl:my-12">
              {/* No-code and Low-code Card */}
              <div className='lg:ms-10'>
                <div className="bg-[#FFEEF8] rounded-t-xl sm:rounded-t-[30px] shadow-lg p-6 lg:p-8 flex flex-col justify-between">
                  {/* Title Section */}
                  <h2 className="text-[28px] md:text-[42px] lg:text-[48px] lg:w-[80%] 2xl:w-full tracking-tighter font-bold leading-none bg-gradient-to-r from-[#EC6CB9] via-[#D03592] to-[#6F42C1] bg-clip-text text-transparent">
                  No-code & Low-code</h2>

                  {/* Tags */}
                  <div className='justify-items-start py-4 sm:pt-6'>
                    <div className='flex gap-2'>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Quickbase</button>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Power Platform</button>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>MRI Platform</button>
                    </div>
                  </div>

                  {/* Description */}
                  <div className='my-8 sm:my-10 lg:my-14'>
                    <p className="text-[var(--blacktext)] tracking-tighter leading-snug text-lg lg:text-xl xl:text-2xl xl:w-full sm:h-20 lg:h-fit">
                      Accelerate delivery with scalable, flexible platforms requiring minimal coding.
                    </p>
                  </div>

                  {/* Link */}
                  <Link to="/case-studies" className="text-[var(--notionblue)] tracking-tighter text-sm lg:text-lg hover:underline">
                    View Case Study
                  </Link>
                </div>
                {/* Footer D03592 */}
                <div className="rounded-b-xl sm:rounded-b-[30px] border-b-[6px] border-[#F692CE] bg-gradient-to-r from-[#F9B3DD] via-[#EC6CB9] via-[20%] to-[#D03592] py-4 text-white text-center">
                  <p className='text-white text-center lg:text-lg font-semibold'><span>40% </span>Reduction in development time</p>
                </div>
              </div>
              {/* Product Development Card */}
              <div className='lg:me-10'>
                <div className="bg-[#FFEBDA] rounded-t-xl sm:rounded-t-[30px] shadow-lg p-6 lg:p-8 flex flex-col justify-between">
                  {/* Title Section */}
                    <h2 className="text-[28px] md:text-[42px] lg:text-[48px] font-bold xl:w-[80%] 2xl:w-full tracking-tighter leading-none  bg-gradient-to-r from-[#FFAA6D] from-[0%] via-[#E36209] via-[10%] to-[#A04100] bg-clip-text text-transparent">
                    Product Development
                    </h2>
                  {/* Tags */}
                  <div className='justify-items-start py-4 sm:pt-6'>
                    <div className='flex gap-2'>
                    <button className='rounded-md border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Consulting</button>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Strategy</button>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>EOS framework</button>
                    </div>
                  </div>

                  {/* Description */}
                  <div className='my-8 sm:my-10 lg:my-14'>
                    <p className="text-[var(--blacktext)] tracking-tighter leading-snug text-lg lg:text-xl xl:text-2xl lg:w-10/12 xl:w-10/12 sm:h-24 lg:h-fit">
                      Bring ideas to life with rapid MVPs, POCs, and innovative prototypes.
                    </p>
                  </div>
                  {/* Link */}
                  <Link to="/case-studies" className="text-[var(--notionblue)] tracking-tighter text-sm lg:text-lg hover:underline">
                    View Case Study
                  </Link>

                </div>
                {/* Footer */}
                <div className="rounded-b-xl sm:rounded-b-[30px] border-b-[6px] border-[#FFAB70] bg-gradient-to-r from-[#FFD1AC] via-[#FB8532] via-[20%] to-[#C24E00] to-[96%] py-4 text-white text-center">
                  <p className='text-white text-center lg:text-lg font-semibold'><span>2x </span>Faster time-to-market</p>
                </div>
              </div>
            </div>

            {/* row 3 */}
            <div className="grid gap-6 lg:gap-8 grid-cols-1 md:grid-cols-2 px-4 sm:px-0 my-4 md:my-6 lg:my-8">
              {/* Software Strategy & Solutions */}
              <div className='lg:ms-10'>
                <div className="bg-[#BEF5CB] rounded-t-xl sm:rounded-t-[30px] shadow-lg p-6 sm:p-5 lg:p-8 flex flex-col justify-between">
                  {/* Title Section */}
                  <h2 className="text-[28px] md:text-[42px] lg:text-[48px] md:w-9/12 2xl:w-full tracking-tighter font-bold leading-none bg-gradient-to-r from-[#34D058] from-[5%] via-[#22863A] to-[#006455] bg-clip-text text-transparent">
                  Software Strategy & Solutions</h2>

                  {/* Tags */}
                  <div className='justify-items-start py-4 sm:pt-6'>
                    <div className='flex gap-2'>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>R&D</button>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Development</button>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Mobile</button>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Design</button>
                    </div>
                  </div>

                  {/* Description */}
                  <div className='my-8 sm:my-10 lg:my-14'>
                    <p className="text-[var(--blacktext)] tracking-tighter leading-snug text-lg lg:text-xl xl:text-2xl lg:w-9/12 sm:h-20 lg:h-fit">
                    Create tailored solutions to unlock growth and scalability.
                    </p>
                  </div>

                  {/* Link */}
                  <Link to="/case-studies" className="text-[var(--notionblue)] tracking-tighter text-sm lg:text-lg hover:underline">
                    View Case Study
                  </Link>
                </div>
                {/* Footer */}
                <div className="rounded-b-xl sm:rounded-b-[30px] border-b-[6px] border-[#34D058] bg-gradient-to-r from-[#85E89D] via-[#34D058] via-[20%] to-[#22863A] py-4 text-white text-center">
                  <p className='text-white text-center lg:text-lg font-semibold'><span>3x </span>Scalability improvement</p>
                </div>
              </div>

              {/*Digital Transformation & Experience*/}
              <div className='lg:me-10'>
                <div className="bg-[#E6DCFD] rounded-t-xl sm:rounded-t-[30px] shadow-lg p-6 sm:p-5 lg:p-8 flex flex-col justify-between">
                  {/* Title Section */}
                  <h2 className="text-[28px] md:text-[42px] lg:text-[48px] font-bold xl:w-[80%] 2xl:w-full tracking-tighter leading-none bg-gradient-to-r from-[#8A63D2] via-[#8A63D2] to-[#D03592] bg-clip-text text-transparent">
                  Digital Transformation & Experience</h2>

                  {/* Tags */}
                  <div className='justify-items-start py-4 sm:pt-6'>
                    <div className='flex gap-2'>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Martech</button>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>ROAS Analytics</button>
                    <button className='rounded-md justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>AI SEO</button>
                    </div>
                  </div>

                  {/* Description */}
                  <div className='my-8 sm:my-10 lg:my-14'>
                    <p className="text-[var(--blacktext)] tracking-tighter leading-snug text-lg lg:text-xl xl:text-2xl xl:w-11/12 sm:h-20 lg:h-fit">
                    Transform customer engagement with cutting-edge marketing technologies.
                    </p>
                  </div>
                  {/* Link */}
                  <Link to="/case-studies" className="text-[var(--notionblue)] tracking-tighter text-sm lg:text-lg hover:underline">
                    View Case Study
                  </Link>

                </div>
                {/* Footer 794dcc */}
                <div className="rounded-b-xl sm:rounded-b-[30px] border-b-[6px] border-[#B48DD9] bg-gradient-to-r from-[#D1BCF9] via-[#8A63D2] via-[20%] to-[#5A32A3] py-4 text-white text-center">
                  <p className='text-white text-center lg:text-lg font-semibold'><span>30% </span>Increase in operational efficiency</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* CTA section */}
      <div className='lg:max-w-[1200px] 2xl:max-w-[1380px] m-auto'>
        <div className='mx-3 xl:mx-0 mt-8 xl:my-8 p-5 md:p-10 lg:flex justify-between items-center rounded-3xl sm:rounded-[50px] shadow-lg shadow-[#DFDFDF] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#4D565C] from-[0%] via-[#2B3135] to-[#090A0B] to-[60%]'>
            <h2 className='w-full md:w-[80%] lg:w-[75%] 2xl:w-[60%] pb-1 text-3xl sm:text-5xl lg:text-[40px] xl:text-5xl tracking-[-0.06em] bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-[#AD7DFF] from-[0%] via-[#2188FF] to-[#79B8FF] to-[60%] bg-clip-text text-transparent'>Your Next Breakthrough Starts Here.</h2>
            <div className='pt-4 lg:pt-0'>
            <Link to="/getintouch"><button className="relative button-theme group">
                  <span className="relative px-8 py-3 btn-span-theme">
                  Get in Touch
                  </span>
              </button></Link>
            </div>
        </div>
      </div>
        {/* CTA section ends */}

        {/* Footer */}
        <div><Footer/></div>
        {/* Footer ends */}
        {/* main div ends  */}
    </div>
  )
}

export default Capabilities