import React, { useState, useRef, useEffect } from 'react';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../services/Firebase';
import Meta from './Meta';
import { HiOutlineChevronDown } from "react-icons/hi2";
import Navbar from './Navbar'

const Getintouch = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    company: "",
    service: "",
    description: "",
    budget: "",
    deadline: "",
    createdAt: new Date(),
    ipAddress: "",
  });

  // Update service in formData when selectedOptions changes
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      service: selectedOptions, // Assign new service value
    }));
  }, [selectedOptions]); // Dependency array ensures it runs only when selectedOptions changes


  useEffect(() => {
    // Fetch the user's IP address
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setFormData((prevState) => ({
          ...prevState,
          ipAddress: data.ip, // Update with the fetched IP
        }));
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the button
    try {
      // Save form data to Firestore
      await addDoc(collection(db, "contactus"), formData);

      navigate('/thankyou'); // Redirect to /thankyou

    } catch (error) {
      setIsSubmitting(false); // Re-enable the button after submission
      console.error("Error submitting message: ", error);
      alert("Failed to submit the message!");
    }
  };


  // Toggles dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Updates selected options
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedOptions((prev) =>
      prev.includes(value)
        ? prev.filter((option) => option !== value) // Remove option if unchecked
        : [...prev, value] // Add option if checked
    );

    //setFormData({ ...formData, service: selectedOptions });

  };
  // Closes dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div>
        <Meta
          title="Notionmind® - CONNECT WITH US"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

      </div>
      {/* header */}
      <Navbar />
      {/* header ends */}
      <div className='mb-12 lg:mb-20'>
        <div className='heroSection max-w-[1380px] flex flex-col justify-center m-auto'>
          <p className='justify-center lg:mt-8 sm:text-xl text-center text-[var(--blacktext)] font-medium md:font-normal px-3 md:px-0 py-4 tracking-wider'>CONNECT WITH US</p>
          <h1 className='title-theme leading-[1.10em] tracking-[-0.06em] px-2 text-[34px] sm:text-[50px] lg:text-[70px] font-[700px] pb-2'>Let's Make Things Happen</h1>
        </div>
      </div>

      <div className='mx-2 lg:mx-4 xl:mx-0 my-8'>
        <div className='lg:max-w-[1200px] 2xl:max-w-[1380px] bg-white rounded-3xl sm:rounded-[50px] m-auto py-6 px-2 sm:p-8 lg:py-16 shadow-lg shadow-[#DFDFDF]'>

          <div className='justify-center place-items-center mb-12 flex flex-col'>
            <p className='text-[var(--blacktext)] mt-4 w-full sm:w-8/12 lg:w-6/12 xl:w-7/12 2xl:w-5/12 place-self-center text-base md:text-2xl xl:text-3xl text-center md:font-normal px-3 md:px-0 tracking-tighter'>
              Take the first step towards transforming your business—reach out today!
            </p>
          </div>

          <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-3 py-8 lg:py-12 md:px-16'>
            <form className="mx-2 md:mx-8 lg:mx-20 xl:mx-24" onSubmit={handleSubmit}>
              <div className='mb-4 md:mb-6'>
                <label htmlFor="fullName" className="block text-sm md:text-base text-[var(--graytext)]">
                  Full Name
                </label>
                <input
                  type="text"
                  id="fullName"
                  className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg sm:rounded-xl shadow-sm focus:border-[var(--notionblue)] focus:ring-[var(--notionblue)]"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className='mb-4 md:mb-6'>
                <label htmlFor="email" className="block text-sm md:text-base text-[var(--graytext)]">
                  Business Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg sm:rounded-xl shadow-sm focus:border-[var(--notionblue)] focus:ring-[var(--notionblue)]"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className='mb-4 md:mb-6'>
                <label htmlFor="phone" className="text-sm md:text-base text-[var(--graytext)]">
                  Phone Number <span className="text-xs text-gray-500 ms-4">We value your privacy. No spam, just updates.</span>
                </label>
                <input
                  type="tel"
                  id="phone"
                  className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg sm:rounded-xl shadow-sm focus:border-[var(--notionblue)] focus:ring-[var(--notionblue)]"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>

              <div className='mb-4 md:mb-6'>
                <label htmlFor="company" className="block text-sm md:text-base text-[var(--graytext)]">
                  Company Name
                </label>
                <input
                  type="text"
                  id="company"
                  className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg sm:rounded-xl shadow-sm focus:border-[var(--notionblue)] focus:ring-[var(--notionblue)]"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-4 md:mb-6 relative">
                <label htmlFor="service" className="block text-sm md:text-base text-[var(--graytext)]">
                  Service of Interest
                </label>
                {/* dropdown */}
                <div className="relative w-full" ref={dropdownRef}>
                    <div className="absolute inset-y-0 right-0 flex items-center px-4 pointer-events-none">
                      <HiOutlineChevronDown size={16} />
                    </div>
                  {/* Input Field */}
                  <input
                    type="text"
                    value={selectedOptions.join(', ') || 'Select options'}
                    placeholder="Select options"
                    readOnly
                    onClick={toggleDropdown}
                    name="service"
                    required
                    className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg shadow-sm focus:ring-2 focus:ring-[var(--blacktext)] focus:outline-none cursor-pointer"
                  />

                  {isDropdownOpen && (
                    <div className="absolute mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                      <div className="p-4 space-y-2">
                        {['AI & Business Automation', 'Enterprise Integration', 'Data Driven Insights', 'Software Strategy & Solutions', 'Low/No-code Development', 'Digital Transformation & Experience', 'Others'].map((option) => (
                          <label key={option} className="flex items-center space-x-2">
                            <input
                              type="checkbox"
                              value={option}
                              checked={selectedOptions.includes(option)}
                              onChange={handleCheckboxChange}
                              className="form-checkbox text-blue-500 rounded"
                            />
                            <span>{option}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

              </div>

              <div className='mb-4 md:mb-6'>
                <label htmlFor="description" className="block text-sm md:text-base text-[var(--graytext)]">
                  Describe your project or how we can help
                </label>
                <textarea
                  id="description"
                  rows="4"
                  className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg sm:rounded-xl shadow-sm focus:border-[var(--notionblue)] focus:ring-[var(--notionblue)]"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className='mb-4 md:mb-6'>
                <label htmlFor="budget" className="block text-sm md:text-base text-[var(--graytext)] mb-2">
                Estimated Budget
                </label>
                <div className="flex flex-wrap gap-4">
                  {[
                    { value: "$15K to 30K", label: "$15K to 30K" },
                    { value: "$30K to 50K", label: "$30K to 50K" },
                    { value: "More than $50K", label: "More than $50K" }
                  ].map((option) => (
                    <label
                      key={option.value}
                      className={`px-4 py-2 rounded-lg cursor-pointer border ${
                        formData.budget === option.value
                          ? "bg-[var(--notionblue)] text-white border-[var(--notionblue)]"
                          : "bg-white text-[var(--blacktext)] border-gray-300 hover:bg-gray-100"
                      }`}
                    >
                      <input
                        type="radio"
                        name="budget"
                        value={option.value}
                        checked={formData.budget === option.value}
                        onChange={handleChange}
                        required
                        className="sr-only" // Use screen-reader friendly class
                      />
                      {option.label}
                    </label>
                  ))}
                </div>
              </div>

              <div className='mb-4 md:mb-6'>
                <label htmlFor="deadline" className="block text-sm md:text-base text-[var(--graytext)] mb-2">
                Timeframe/Deadline
                </label>
                <div className="flex flex-wrap gap-4">
                  {[
                    { value: "ASAP", label: "ASAP" },
                    { value: "Next Month", label: "Next Month" },
                    { value: "3 Months", label: "3 Months" },
                    { value: "Not Sure", label: "Not Sure" },
                  ].map((option) => (
                    <label
                      key={option.value}
                      className={`px-4 py-2 rounded-lg cursor-pointer border ${
                        formData.deadline === option.value
                          ? "bg-[var(--notionblue)] text-white border-[var(--notionblue)]"
                          : "bg-white text-[var(--blacktext)] border-gray-300 hover:bg-gray-100"
                      }`}
                    >
                      <input
                        type="radio"
                        name="deadline"
                        value={option.value}
                        checked={formData.deadline === option.value}
                        onChange={handleChange}
                        required
                        className="sr-only" // Use screen-reader friendly class
                      />
                      {option.label}
                    </label>
                  ))}
                </div>
              </div>

              <div className='place-self-center flex py-4 xl:py-8'>
                <button disabled={isSubmitting} className="relative button-theme group">
                  <span className="relative px-8 py-2.5 btn-span-theme">
                    {isSubmitting ? "Submitting..." : "Submit Inquiry"}
                  </span>
                </button>
              </div>

              <div className='place-items-center'>
                <p className='text-center text-sm text-[var(--blacktext)]'>By submitting this form, you agree to our Terms & Conditions and acknowledge that we may collect and process your data as outlined in our Privacy Policy</p>
              </div>

            </form>
          </div>


        </div>
      </div>

      {/* Footer */}
      <div><Footer /></div>
      {/* Footer ends */}
      {/* main div ends  */}
    </div>
  )
}

export default Getintouch