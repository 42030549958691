import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Meta from '../Meta'
import Paging from '../Paging';
import GetInTouchBtn from '../GetInTouchBtn';

const HobbyIdeas = () => {

  return (
    <div>
      <div>
        <Meta
          title="Notionmind® - Digital Platform Development: HobbyIdeas"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

      </div>
      {/* header */}
      <div><Navbar /></div>
      {/* header ends */}

      <div className='my-12'>
        <h4 className='text-[var(--blacktext)] text-center m-auto tracking-wider'>CASE STUDY</h4>
        <h1 className='sm:w-[58%] xl:w-[70%] text-3xl md:text-4xl xl:text-6xl pt-4 lg:pt-6 pb-8 px-2 sm:px-0 text-center m-auto tracking-[-0.06em] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] via-[#103FB8] to-[#2B3135] to-[60%] bg-clip-text text-transparent'>Digital Platform Development: HobbyIdeas</h1>
      </div>

      <div className='mx-2 lg:mx-4 xl:mx-0 mt-4 mb-4 lg:mb-8'>
        <div className="lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-lg shadow-[#DFDFDF] bg-white rounded-3xl sm:rounded-[50px] m-auto p-2 md:p-8 lg:p-16">
          <div className="p-6 lg:px-8 mb-8 lg:mb-20">
            <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
              {/* Left Section */}
              <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
                <div className='justify-items-start pt-2'>
                  <div className='flex-col gap-2'>
                    <div>
                      <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                      <h4 className='mt-4 text-2xl'>Pidilite Industries Ltd</h4>
                    </div>
                    <div className='my-8'>
                      <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                      <h4 className='mt-4 text-2xl'>Arts & Crafts, E-learning, Community Platform</h4>
                    </div>
                    <div>
                      <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Project</button>
                      <h4 className='mt-4 text-2xl'>Comprehensive Digital Platform for Art & Craft Community</h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* Right Section */}
              <div className="space-y-8 place-content-center">
                {/* Problem */}
                <div>
                  <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
                  <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug">
                    HobbyIdeas.in, developed for Pidilite Industries, is a comprehensive digital platform connecting over 4000 art teachers with students, offering content sharing, workshop management, and an e-commerce marketplace for handcrafted items. The platform promotes community building, enhances product awareness, and facilitates skill development through interactive features and a scalable infrastructure. The solution integrates content management, community engagement, and business opportunities, driving growth for both educators and the brand.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
            <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>                    
                    <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>The Challenge</h2>
                </div>
                <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                Pidilite Industries sought to develop a digital ecosystem for their art and craft community but faced several key challenges:
                </p>

                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Scattered Information:</p>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Art-related content and resources were spread across multiple platforms, making it hard for users to access relevant information.</p>

                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Limited Visibility:</p>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Art teachers and workshops had minimal exposure, hindering their ability to reach potential students.</p>

                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Lack of Content Sharing Platform:</p>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>There was no centralized platform for sharing DIY tutorials, craft instructions, and creative projects.</p>

                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Inefficient Workshop Management:</p>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Workshop scheduling, attendance, and analytics were manually managed, leading to inefficiencies.</p>
                
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Product Awareness:</p>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Pidilite needed to educate users about their products while promoting their marketplace for handcrafted items.</p>

                </div>
            </div>
          </div>

          <div className='my-12 lg:mb-8 xl:mb-12'>
            <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-4 pt-8 pb-2 lg:py-12 md:px-16'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Solution Implemented</h1>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Workshop Management System</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li>Location-based discovery of workshops.</li>
                  <li>Detailed teacher profiles with credentials.</li>
                  <li>Booking, scheduling, and attendance tracking.</li>
                  <li>Workshop analytics dashboard for insights.</li>
                </ul>
              </div>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Content Management System</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li>DIY project tutorials and craft instructions.</li>
                  <li>Video content integration for step-by-step guides.</li>
                  <li>Blog management for content sharing.</li>
                  <li>Downloadable design sheets for users.</li>
                </ul>
              </div>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Community Features</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li>User profiles for sharing creations and engaging with others.</li>
                  <li>Community interaction through posts, comments, and sharing.</li>
                  <li>Expert recognition and social media integration.</li>
                </ul>
              </div>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">E-commerce Integration</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li>Handcrafted items marketplace for buying and selling.</li>
                  <li>Product catalog showcasing art supplies and handcrafted goods.</li>
                  <li>Secure payment processing and order management.</li>
                </ul>
              </div>
            </div>
          </div>


          <div className='mt-8 lg:mt-16 2xl:mt-20 mb-8'>
            <div className='place-items-center flex flex-col justify-center items-center'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Technical Implementation</h1>
              <h5 className='text-center flex justify-center lg:font-bold xl:w-9/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>The platform was developed with a responsive, mobile-first frontend, a scalable backend architecture, and a robust database to manage user data, content, and analytics. Cloud hosting ensured reliable performance, while a content delivery network optimized global access and real-time updates were integrated for enhanced user experience.</h5>
            </div>
          </div>
          <div className='md:mb-16 xl:mb-20'>
            <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                    <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Frontend</h2>                    
                </div>
                <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                  <ul className="leading-none tracking-tighter text-[var(--blacktext)] px-4 text-base md:text-[20px] lg:text-[24px] list-outside space-y-2 text-left list-item">
                    <li>Responsive design with a mobile-first approach.</li>
                    <li>Interactive UI elements and real-time updates.</li>
                  </ul>
                </div>
            </div>
            <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                    <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>For Surgery Centers</h2>                    
                </div>
                <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                  <ul className="leading-none tracking-tighter text-[var(--blacktext)] px-4 text-base md:text-[20px] lg:text-[24px] list-outside space-y-2 text-left list-item">
                    <li>Scalable architecture.</li>
                    <li>Secure user authentication.</li>
                    <li>Integrated analytics system.</li>
                  </ul>
                </div>
            </div>
            <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                    <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>For Physicians</h2>                    
                </div>
                <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                  <ul className="leading-none tracking-tighter text-[var(--blacktext)] px-4 text-base md:text-[20px] lg:text-[24px] list-outside space-y-2 text-left list-item">
                    <li>Workshop tracking.</li>
                    <li>User management and content repository.</li>
                    <li>Analytics storage.</li>
                  </ul>
                </div>
            </div>
          </div>

          <div className='my-12 lg:mb-8 xl:mb-12'>
            <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-4 pt-8 pb-2 lg:py-12 md:px-16'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Results & Benefits</h1>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">For Art Teachers</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li>Increased workshop visibility and better student engagement.</li>
                  <li>Simplified workshop management and enhanced credibility.</li>
                  <li>Access to resources for teaching and community support.</li>
                </ul>
              </div>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">For Students</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li>Easy discovery of workshops and access to quality content.</li>
                  <li>Enhanced community interaction and skill development.</li>
                  <li>Product guidance and hands-on learning.</li>
                </ul>
              </div>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">For Pidilite</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li>Improved market insights and data-driven decision-making.</li>
                  <li>Enhanced brand visibility and community engagement.</li>
                  <li>Increased product awareness and targeted marketing opportunities.</li>
                </ul>
              </div>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Key Metrics</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li>4000+ active art teachers.</li>
                  <li>Nationwide workshop coverage and increased participation.</li>
                  <li>Higher community engagement and content interaction.</li>
                  <li>Enhanced product awareness and customer education.</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-20'>
            <div className='place-items-center flex flex-col justify-center items-center'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
              <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>The implementation of HobbyIdeas.in successfully addressed the challenges of scattered information, limited visibility, and inefficient workshop management. By creating a unified digital platform with integrated content sharing, community features, and e-commerce capabilities, it enabled seamless interaction between art teachers, students, and enthusiasts. The platform not only enhanced brand visibility and product awareness for Pidilite but also fostered a thriving community of craft lovers, contributing to skill development and business growth. The scalable, data-driven solution positions the platform for continued expansion and innovation.</h5>
            </div>
          </div>

        </div>
      </div>

      {/* Next Previous CS section */}
      <Paging
        prevLink="/case-studies/city-of-willoughby"
        prevTitle="City of Willoughby"
        nextLink="/case-studies/go-to-work-america"
        nextTitle="Go to work America" />
      {/* Next Previous CS section ends*/}

      {/* Get In Touch Button */}
      <GetInTouchBtn />
      {/* Get In Touch Button ends */}

      {/* Footer */}
      <div><Footer /></div>
      {/* Footer ends */}
      {/* main div ends  */}
    </div>
  )
}

export default HobbyIdeas