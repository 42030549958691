import React from 'react'

const CloudStorage = () => {
  return (
    <>
      <div className="sm:px-4 lg:px-8 mb-8 lg:mb-20">
        <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Left Section */}
            <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
                <div className='justify-items-start pt-2'>
                    <div className='flex-col gap-2'>
                    <div className='my-4 lg:my-8'>
                        <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                        <h4 className='mt-2 tracking-tighter lg:text-xl xl:text-2xl'>Enterprise</h4>
                    </div>
                    <div className='my-4 lg:my-8'>
                        <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                        <h4 className='mt-2 tracking-tighter lg:text-xl xl:text-2xl'>Real Estate & Property Management</h4>
                    </div>
                    </div>
                </div>
            </div>
            {/* Right Section */}
            <div className="space-y-8 place-content-center">
            {/* Problem */}
            <div>
                <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
                <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug tracking-tighter">
                Developed a sophisticated file management and storage integration solution for a major
                 real estate company managing thousands of properties. The system automates file transfers
                 between QuickBase and SharePoint, enabling efficient document management, secure storage,
                 and streamlined access across multiple business verticals.
                </p>
            </div>
            </div>
        </div>
      </div>

      <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
        <div className='grid lg:grid-cols-5 gap-4 mt-4 xl:mx-8'>
            <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-4 xl:px-2'>
                <h2 className='text-3xl sm:text-5xl tracking-tighter text-[var(--blacktext)]'>The Challenge</h2>
            </div>
            <div className='col-span-3 leading-tight tracking-tighter text-[var(--blacktext)] py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-6 sm:px-8'>
            <p className='text-base md:text-[24px]'>
            Facilities managers faced multiple challenges in managing documents across systems:
            </p>

            <p className='text-base md:text-[24px] font-bold mt-9'>Inefficient Storage Management:</p>
            <ul className="mt-4 lg:mt-8 text-base md:text-[24px] px-3 list-outside space-y-1 sm:space-y-2 text-left list-disc">
              <li>Growing QuickBase storage costs</li>
              <li>Slow document retrieval times</li>
              <li>Performance impact on core systems</li>
            </ul>
            <p className='text-base md:text-[24px] font-bold mt-9'>Storage System Limitations:</p>
            <ul className="mt-4 lg:mt-8 text-base md:text-[24px] px-3 list-outside space-y-1 sm:space-y-2 text-left list-disc">
              <li>Limited storage capacity in QuickBase</li>
              <li>Manual file transfer processes</li>
              <li>Disconnected storage systems</li>
            </ul>

            <p className='text-base md:text-[24px] font-bold mt-9'>Document Access Issues:</p>
            <ul className="mt-4 lg:mt-8 text-base md:text-[24px] px-3 list-outside space-y-1 sm:space-y-2 text-left list-disc">
              <li>Complex authorization requirements</li>
              <li>Scattered document locations</li>
              <li>Inconsistent access controls</li>
            </ul>

            <p className='text-base md:text-[24px] font-bold mt-9'>Performance Concerns:</p>
            <ul className="mt-4 lg:mt-8 text-base md:text-[24px] px-3 list-outside space-y-1 sm:space-y-2 text-left list-disc">
              <li>System slowdowns during file operations</li>
              <li>Bandwidth constraints</li>
              <li>Scale limitations</li>
            </ul>

            </div>
        </div>
      </div>

      <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
        <div className='grid lg:grid-cols-5 gap-4 mt-4 xl:mx-8'>
            <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-4 xl:px-2'>
                <h2 className='text-3xl sm:text-5xl tracking-tighter text-[var(--blacktext)]'>Our Solution</h2>
            </div>
            <div className='col-span-3 tracking-tighter leading-tight text-[var(--blacktext)] py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-6 sm:px-8'>

            <p className='text-base md:text-[24px] font-bold mt-4'>Automated File Transfer System:</p>
            <ul className="mt-4 lg:mt-8 text-base md:text-[24px] px-3 list-outside space-y-1 sm:space-y-2 text-left list-disc">
              <li>Developed automated daily file processing pipeline</li>
              <li>Implemented secure file transfer protocols</li>
              <li>Created metadata synchronization system</li>
            </ul>
            <p className='text-base md:text-[24px] font-bold mt-9'>Secure Authentication Framework:</p>
            <ul className="mt-4 lg:mt-8 text-base md:text-[24px] px-3 list-outside space-y-1 sm:space-y-2 text-left list-disc">
              <li>Integrated OAuth 2.0 authentication</li>
              <li>Implemented role-based access control</li>
              <li>Developed secure URL generation</li>
            </ul>

            <p className='text-base md:text-[24px] font-bold mt-9'>Real-time Processing Module:</p>
            <ul className="mt-4 lg:mt-8 text-base md:text-[24px] px-3 list-outside space-y-1 sm:space-y-2 text-left list-disc">
              <li>Created event-driven architecture</li>
              <li>Implemented automatic file routing</li>
              <li>Developed status tracking system</li>
            </ul>

            <p className='text-base md:text-[24px] font-bold mt-9'>Error Handling System:</p>
            <ul className="mt-4 lg:mt-8 text-base md:text-[24px] px-3 list-outside space-y-1 sm:space-y-2 text-left list-disc">
              <li>Built comprehensive error detection</li>
              <li>Implemented automatic recovery procedures</li>
              <li>Created audit logging system</li>
            </ul>

            </div>
        </div>
      </div>

      <div className='mt-8 lg:mt-16 2xl:mt-20 mb-8'>
        <div className='place-items-center flex flex-col justify-center items-center'>
        <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Technical Implementation</h1>
        </div>
      </div>
      <div className='md:mb-16 xl:mb-20'>
          <div className='grid sm:grid-cols-5 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
              <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                  <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Integration Layer</h2>
              </div>
              <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
              <ul className="tracking-tighter leading-none text-[var(--blacktext)] text-base md:text-[20px] lg:text-[24px] list-outside space-y-1 sm:space-y-2 text-left list-item">
                <li>REST API endpoints for system communication</li>
                <li>Webhook receivers for event processing</li>
                <li>Custom API wrappers for third-party services</li>
              </ul>
              </div>
          </div>
          <div className='grid sm:grid-cols-5 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
              <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                  <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Web Dashboard</h2>
              </div>
              <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
              <ul className="tracking-tighter leading-none text-[var(--blacktext)] text-base md:text-[20px] lg:text-[24px] list-outside space-y-1 sm:space-y-2 text-left list-item">
                <li>Secure admin interface</li>
                <li>Document management tools</li>
                <li>Real-time status monitoring</li>
              </ul>
              </div>
          </div>
          <div className='grid sm:grid-cols-5 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
              <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                  <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Cloud-based Backend</h2>
              </div>
              <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
                <ul className="tracking-tighter leading-none text-[var(--blacktext)] text-base md:text-[20px] lg:text-[24px] list-outside space-y-1 sm:space-y-2 text-left list-item">
                  <li>Scalable microservices architecture</li>
                  <li>Asynchronous processing capabilities</li>
                  <li>Real-time data synchronization</li>
                </ul>
              </div>
          </div>
      </div>

      <div className='my-12 lg:mb-8 xl:mb-12'>
        <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-6 pt-8 pb-2 lg:py-12 md:px-16'>
            <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Results & Benefits</h1>

            <div className='tracking-tighter my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Operational Improvements</h2>
                <ul className="mt-2 md:text-2xl px-3 text-[var(--darkgray)] list-outside space-y-1 sm:space-y-2 text-left list-disc">
                    <li>75% reduction in storage costs</li>
                    <li>60% faster document retrieval</li>
                    <li>95% decrease in manual processing</li>
                    <li>80% improvement in system performance</li>
                </ul>
            </div>

            <div className='tracking-tighter my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Business Impact</h2>
                <ul className="mt-2 md:text-2xl px-3 text-[var(--darkgray)] list-outside space-y-1 sm:space-y-2 text-left list-disc">
                    <li>Improved resource utilization</li>
                    <li>Enhanced system reliability</li>
                    <li>Increased user satisfaction</li>
                    <li>Reduced operational costs</li>
                </ul>
            </div>

            <div className='tracking-tighter my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">ROI Metrics</h2>
                <ul className="mt-2 md:text-2xl px-3 text-[var(--darkgray)] list-outside space-y-1 sm:space-y-2 text-left list-disc">
                    <li>6-month ROI achievement</li>
                    <li>40% reduction in IT support tickets</li>
                    <li>70% increase in processing capacity</li>
                </ul>
            </div>
        </div>
      </div>

      <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-20'>
        <div className='tracking-tighter place-items-center flex flex-col justify-center items-center'>
        <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
        <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>The Cloud Storage API Integration Platform successfully solved the client's document management challenges through automated processes, secure integrations, and efficient storage optimization. This case study demonstrates how well-architected API solutions can transform document management for long-term business success.</h5>
        </div>
      </div>

    </>
  )
}

export default CloudStorage