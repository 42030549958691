import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Meta from '../Meta';
import Paging from '../Paging';
import GetInTouchBtn from '../GetInTouchBtn';

const Alcon = () => {

  return (
    <div>
      <div>
      <Meta
          title="Notionmind® - Healthcare Technology: Verisurg Platform"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

      </div>
      {/* header */}
      <div><Navbar/></div>
      {/* header ends */}

      <div className='my-12'>
            <h4 className='text-[var(--blacktext)] text-center m-auto tracking-wider'>CASE STUDY</h4>
            <h1 className='sm:w-[58%] xl:w-[70%] text-3xl md:text-4xl xl:text-6xl pt-4 lg:pt-6 pb-8 px-2 sm:px-0 text-center m-auto tracking-[-0.06em] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] via-[#103FB8] to-[#2B3135] to-[60%] bg-clip-text text-transparent'>Healthcare Technology: Verisurg Platform</h1>
        </div>

        <div className='mx-2 lg:mx-4 xl:mx-0 mt-4 mb-4 lg:mb-8'>
            <div className="lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-lg shadow-[#DFDFDF] bg-white rounded-3xl sm:rounded-[50px] m-auto p-2 md:p-8 lg:p-16">
                <div className="p-6 lg:px-8 mb-8 lg:mb-20">
                    <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {/* Left Section */}
                        <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
                            <div className='justify-items-start pt-2'>
                                <div className='flex-col gap-2'>
                                <div>
                                    <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                                    <h4 className='mt-4 text-2xl'>Verisurg/Alcon</h4>
                                </div>
                                <div className='my-8'>
                                    <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                                    <h4 className='mt-4 text-2xl'>Healthcare/Medical Technology</h4>
                                </div>
                                <div>
                                    <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Project</button>
                                    <h4 className='mt-4 text-2xl'>Surgery Management Platform for Clinics and ASCs</h4>
                                </div>
                                </div>
                            </div>
                        </div>
                        {/* Right Section */}
                        <div className="space-y-8 place-content-center">
                        {/* Problem */}
                        <div>
                            <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
                            <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug">
                            The Verisurg platform exemplifies the transformative potential of healthcare technology by addressing inefficiencies and streamlining operations across clinics and ambulatory surgery centers (ASCs). This case study highlights how Verisurg/Alcon modernized their surgical management platform to overcome critical operational challenges, improve patient care, and ensure secure and compliant data management.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>

                <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                    <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                        <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>                    
                            <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>The Challenge</h2>
                        </div>
                        <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                        The Verisurg/Alcon team faced several critical challenges in modernizing their platform for surgery
                        management, particularly in addressing inefficiencies and meeting evolving healthcare standards.
                        Below is a detailed breakdown of the issues:
                        </p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Complex Communication Between Clinics and ASCs:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Fragmented communication channels caused delays, data duplication, and coordination errors. This lack of integration hindered seamless updates and collaboration between stakeholders.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Manual Surgery Scheduling:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Labor-intensive scheduling processes led to overlapping or missed bookings, reduced efficiency, and limited scalability for managing higher surgical volumes.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Inefficient Inventory Management:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Manual tracking methods resulted in overstocking, shortages, and lack of predictive insights, disrupting surgery readiness and increasing operational costs.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Paper-Based Consent Forms:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Managing patient consents on paper was time-consuming, error-prone, and insecure, complicating storage, retrieval, and compliance with data protection regulations.</p>
                        
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Limited Real-Time Operational Visibility:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Absence of real-time tracking tools made it challenging to monitor patient flow, surgery progress, and OR availability, leading to inefficiencies and delays in decision-making.</p>

                        </div>
                    </div>
                </div>

                <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                    <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                        <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>                    
                            <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>Our Solution</h2>
                        </div>
                        <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                        The Verisurg platform was designed and deployed to tackle these challenges with robust, scalable components and innovative features:
                        </p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Surgery Management System:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Developed a role-based web application to streamline patient registration, surgery scheduling, digital consent management, and automated workflows for clinics and ASCs.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Inventory Management System:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Integrated real-time inventory tracking with MyAlcon360, enabling predictive ordering, usage analytics, and automated reordering to ensure readiness and cost efficiency.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Real-Time Operational Visibility:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Implemented tools for OR time tracking, patient flow monitoring, and real-time status updates, improving coordination and reducing delays.</p>
                        
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Security and Compliance Framework:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Ensured HIPAA compliance with secure data handling, role-based access controls, audit trails, and encrypted transmission to protect sensitive patient information.</p>

                        </div>
                    </div>
                </div>

                <div className='mt-8 lg:mt-16 2xl:mt-20 mb-8'>
                    <div className='place-items-center flex flex-col justify-center items-center'>
                    <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Key Features</h1>
                    </div>
                </div>

                <div className='md:mb-16 xl:mb-20'>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>For Clinics</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                                Simplified patient registration, digital consents, and surgery scheduling.
                            </h5>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>For Surgery Centers</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            Enhanced OR scheduling, inventory management, and patient tracking.
                            </h5>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>For Physicians</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            Streamlined preferences, digital documentation, and performance analytics.
                            </h5>
                        </div>
                    </div>
                </div>

                <div className='my-12 lg:mb-8 xl:mb-12'>
                    <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-4 pt-8 pb-2 lg:py-12 md:px-16'>
                       <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Results & Benefits</h1>

                       <div className='my-12'>
                            <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Operational Improvements</h2>
                            <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                                <li>Enhanced scheduling efficiency</li>
                                <li>Reduced administrative overhead</li>
                                <li>Optimized inventory management</li>
                                <li>Better patient tracking and resource utilization</li>
                            </ul>
                        </div>

                        <div className='my-12'>
                            <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Clinical Impact</h2>
                            <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                                <li>Improved patient care quality</li>
                                <li>Reduced errors in processes</li>
                                <li>Strengthened communication between stakeholders</li>
                            </ul>
                        </div>

                        <div className='my-12'>
                            <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Business Benefits</h2>
                            <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                                <li>Significant cost reductions</li>
                                <li>Greater operational efficiency</li>
                                <li>Data-driven insights for strategic decisions</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-20'>
                    <div className='place-items-center flex flex-col justify-center items-center'>
                    <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
                    <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>The Verisurg platform showcases how a well-designed, comprehensive healthcare management system can overcome operational bottlenecks while maintaining stringent security and compliance standards. This project not only optimized surgical workflows but also delivered measurable improvements in efficiency, cost savings, and patient care quality.</h5>
                    </div>
                </div>
                
            </div>
        </div>

      {/* Next Previous CS section */}
      <Paging
          prevLink="/case-studies/abad"
          prevTitle="ABAD"
          nextLink="/case-studies/evolv"
          nextTitle="Evolv"/>
        {/* Next Previous CS section ends*/}

        {/* Get In Touch Button */}
          <GetInTouchBtn />
        {/* Get In Touch Button ends */}

      {/* Footer */}
      <div><Footer/></div>
      {/* Footer ends */}
      {/* main div ends  */}
    </div>
  )
}

export default Alcon