import React from 'react';
import Footer from './Footer';
import Meta from './Meta';
import Navbar from './Navbar';

const Thankyou = () => {

  return (
    <div className='min-h-screen flex flex-col'>
        <div>
        <Meta
          title="Notionmind® - Thank You"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

        </div>
        {/* header */}
        <Navbar />
        {/* header ends */}

        <div className='heroSection max-w-[1380px] flex-grow flex-col justify-center place-content-center my-8 md:my-16 m-auto'>
          <h1 className='title-theme text-[34px] sm:text-[64px] lg:text-[86px] tracking-tighter font-[700px] pb-1'>Thank You!</h1>
          <div className='m-auto flex justify-center'>
          <p className='flex justify-center sm:w-10/12 lg:w-8/12 lg:mt-12 sm:text-2xl lg:text-3xl text-center text-[var(--darkgray)] font-medium md:font-normal px-3 md:px-0 tracking-tight'>
          We appreciate you reaching out to us. Your message has been received, and our team will get back to you shortly.
          </p>
          </div>
        </div>


      {/* Footer */}
      <div className='m-auto justify-center flex'>
      <Footer/>
      </div>
      {/* Footer ends */}
      {/* main div ends  */}
    </div>
  )
}

export default Thankyou