import React from 'react';
import Footer from './Footer';
import Meta from './Meta';
import Navbar from './Navbar'

const TermsOfUse = () => {

  return (
    <div>
        <div>
        <Meta
          title="Notionmind® - Terms of Use"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

        </div>
        {/* header */}
        <Navbar />
        {/* header ends */}


        {/* hero section */}
        <div className='bg-hero bg-no-repeat bg-contain m-auto place-content-center my-2 bg-left'>
        <div className='heroSection max-w-[1380px] flex flex-col justify-center sm:my-8 m-auto'>
            <h1 className='title-theme sm:px-12 pb-1 text-[34px] sm:text-[64px] tracking-tighter font-[700px]'>Terms of Use</h1>
        </div>
        </div>
        {/* hero section ends */}

        <div className='mx-3 lg:mx-4 xl:mx-0 my-8'>
            <div className='lg:max-w-[1200px] 2xl:max-w-[1380px] m-auto bg-white rounded-3xl sm:rounded-[50px] py-12 sm:p-20 shadow-md shadow-[#DFDFDF]'>

            <div className='mx-8 sm:mx-24 lg:mx-56 xl:mx-72'>

            <section>
                <h2 className='font-bold my-2'>1. Form Submissions and Communications</h2>
                <p>By submitting inquiries through our website forms, you:</p>
                <ul>
                    <li>Confirm all provided information is accurate</li>
                    <li>Agree to receive communications related to your inquiry</li>
                    <li>Acknowledge that submissions don't create binding agreements</li>
                    <li>Accept that project details may be used for proposal preparation</li>
                </ul>
            </section>

            <section>
                <h2 className='font-bold my-2'>2. Project Information</h2>
                <p>When submitting project details, you:</p>
                <ul>
                    <li>Grant us permission to evaluate your requirements</li>
                    <li>Understand that budget ranges are for estimation purposes</li>
                    <li>Agree that timeframes are subject to final project scope</li>
                    <li>Accept that service recommendations may vary based on assessment</li>
                </ul>
            </section>

            <section>
                <h2 className='font-bold my-2'>3. Service Engagement</h2>
                <p>Our service engagement process includes:</p>
                <ul>
                    <li>Initial assessment of submitted requirements</li>
                    <li>Project scope definition</li>
                    <li>Resource allocation planning</li>
                    <li>Timeline and budget finalization</li>
                </ul>
            </section>

            <section>
                <h2 className='font-bold my-2'>4. Intellectual Property</h2>
                <h3 className=' my-2'>4.1 Your Content</h3>
                <ul>
                    <li>You retain rights to information you submit</li>
                    <li>Grant us license to use for service delivery</li>
                    <li>Allow use for internal business purposes</li>
                </ul>

                <h3 className=' my-2'>4.2 Our Content</h3>
                <p>We retain all rights to:</p>
                <ul>
                    <li>Website content and design</li>
                    <li>Service descriptions and methodologies</li>
                    <li>Proprietary tools and processes</li>
                    <li>Marketing materials</li>
                </ul>
            </section>

            <section>
                <h2 className='font-bold my-2'>5. Confidentiality</h2>
                <p>We protect your information through:</p>
                <ul>
                    <li>Strict confidentiality protocols</li>
                    <li>Secure data storage</li>
                    <li>Limited access controls</li>
                    <li>Non-disclosure agreements</li>
                </ul>
            </section>

            <section>
                <h2 className='font-bold my-2'>6. Service Limitations</h2>
                <p>Our services are:</p>
                <ul>
                    <li>Subject to resource availability</li>
                    <li>Based on accurate project information</li>
                    <li>Dependent on mutual cooperation</li>
                    <li>Governed by separate service agreements</li>
                </ul>
            </section>

            <section>
                <h2 className='font-bold my-2'>7. Geographic Scope</h2>
                <p>With locations in:</p>
                <ul>
                    <li>Ahmedabad, Mumbai (India)</li>
                    <li>USA</li>
                    <li>Spain</li>
                </ul>
                <p>We provide services globally while complying with local laws.</p>
            </section>

            <section>
                <h2 className='font-bold my-2'>8. Changes to Terms</h2>
                <p>We may update these terms:</p>
                <ul>
                    <li>With notice on our website</li>
                    <li>Through email notifications</li>
                    <li>Maintaining previous versions</li>
                    <li>Preserving user rights</li>
                </ul>
            </section>

            <section class="contact-info">
                <h2 className='font-bold my-2'>9. Contact Information</h2>
                <p>For terms-related inquiries:</p>
                <p>Email: info@notionmind.com</p>
                <p>Phone: +91 98985 58679 (India) +1 844.360.7360 (USA)</p>
            </section>

            <section>
                <h2 className='font-bold my-2'>10. Governing Law</h2>
                <p>These terms are governed by applicable laws in our operating jurisdictions, with specific provisions for:</p>
                <ul>
                    <li>India</li>
                    <li>United States</li>
                    <li>European Union</li>
                </ul>
            </section>

            </div>
            </div>
        </div>

        {/* Footer */}
        <div><Footer/></div>
        {/* Footer ends */}
    </div>
  )
}

export default TermsOfUse