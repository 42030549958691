import React, { useState } from "react";
import team from '../images/t1.webp';
import team2 from '../images/t2.webp';
import team3 from '../images/t3.webp';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi2";
import { useSwipeable } from 'react-swipeable';

const Gallery = ({images}) => {

  if (!images || images.length === 0) {
    images = [
        { id: 1, src: team, alt: 'Image 1' },
        { id: 2, src: team2, alt: 'Image 2' },
        { id: 3, src: team3, alt: 'Image 3' },
        ];
      }

      const [current, setCurrent] = useState(0);
      const handleNext = () => {
        setCurrent((prev) => (prev + 1) % images.length);
      };

      const handlePrev = () => {
        setCurrent((prev) => (prev - 1 + images.length) % images.length);
      };
      // Swipe Handlers
    const swipeHandlers = useSwipeable({
      onSwipedLeft: handlePrev,
      onSwipedRight: handleNext,
      preventScrollOnSwipe: true,
    //   trackMouse: true, // For testing swipes on desktop
    });

  return (
    <div className="flex flex-col items-center place-items-center">
      <div className="relative overflow-hidden w-[350px] md:w-[700px] lg:w-[974px] xl:w-[1200px] 2xl:w-[1290px] h-[160px] md:h-[320px] lg:h-[460px] xl:h-[580px] 2xl:h-[620px]"
      {...swipeHandlers}>
        {images.map((image, index) => (
          <div
            key={image.id}
            className={`absolute top-0 w-full h-full flex justify-center transition-opacity duration-500 ${
              index === current ? "opacity-100 z-10" : "opacity-0 z-0"
            }`}
          >
            <img
              src={image.src}
              alt={image.alt}
              className=""
            />
          </div>
        ))}
        {/* Previous Button */}
        <h4
          onClick={handlePrev}
          className="hidden sm:flex cursor-pointer absolute left-4 top-1/2 transform -translate-y-1/2 z-20 bg-white rounded-full text-[var(--blacktext)] w-8 h-8 items-center justify-center shadow-lg"
        >
          <HiOutlineChevronLeft size={20} />
        </h4>

        {/* Next Button */}
        <h4
          onClick={handleNext}
          className="hidden sm:flex cursor-pointer absolute right-4 top-1/2 transform -translate-y-1/2 z-20 bg-white rounded-full text-[var(--blacktext)] w-8 h-8 items-center justify-center shadow-lg"
        >
          <HiOutlineChevronRight size={20} />
        </h4>
      </div>

       {/* Dots for Slide Indicators */}
    <div className="flex justify-center space-x-2 mt-6 md:mt-8">
      {images.map((_, index) => (
        <span
        key={index}
        onClick={() => setCurrent(index)}
        className={`cursor-pointer w-6 md:w-8 h-[2px] rounded-full transition-colors duration-300 ${
          index === current ? "bg-[var(--blacktext)]" : "bg-gray-300"
        }`}
      />
      ))}
    </div>
    </div>
  )
}

export default Gallery