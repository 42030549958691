import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    essential: true,
    analytics: false,
    functional: false,
    marketing: false
  });

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAcceptAll = () => {
    setCookiePreferences({
      essential: true,
      analytics: true,
      functional: true,
      marketing: true
    });
    localStorage.setItem('cookieConsent', 'all');
    setIsVisible(false);
  };

  const handleSavePreferences = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(cookiePreferences));
    setIsVisible(false);
    setShowPreferences(false);
  };

  const handleRejectNonEssential = () => {
    setCookiePreferences({
      essential: true,
      analytics: false,
      functional: false,
      marketing: false
    });
    localStorage.setItem('cookieConsent', 'essential');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50">
      {!showPreferences ? (
        <div className="bg-white border-t border-gray-200 p-4 md:p-6 shadow-lg">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col items-center justify-between gap-4">
              <div className="text-[12px] text-center w-10/12 py-2 text-gray-600 md:pr-8">
                We use cookies to enhance your experience on our website. By clicking "Accept All,"
                you consent to the use of all cookies. You can also manage your preferences.
              </div>
              <div className="flex flex-col sm:flex-row gap-2 w-full md:w-auto">
              
                <button
                  onClick={() => setShowPreferences(true)}
                  className="px-4 py-1 text-[11px] font-medium text-[var(--blacktext)] border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  Preferences
                </button>
                <button
                  onClick={handleRejectNonEssential}
                  className="px-4 py-1 text-[11px] font-medium text-[var(--blacktext)] border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  Reject Non-Essential
                </button>
                <button
                  onClick={handleAcceptAll}
                  className="px-4 py-1 text-[11px] font-medium text-white bg-[#0887FF] hover:bg-[#103FB8] rounded-lg transition-colors"
                >
                  Accept All
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white border-t border-gray-200 p-6 shadow-lg">
          <div className="max-w-3xl mx-auto">
            <h3 className="text-[12px] font-semibold mb-4">Cookie Preferences</h3>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div>
                  <div className="font-medium text-[12px]">Essential Cookies</div>
                  <div className="text-[11px] text-gray-500">Required for the website to function properly.</div>
                </div>
                <div className="bg-gray-100 px-3 py-1 rounded text-[11px]">Always Active</div>
              </div>

              {Object.entries(cookiePreferences)
                .filter(([key]) => key !== 'essential')
                .map(([key, value]) => (
                  <div key={key} className="flex items-center justify-between">
                    <div>
                      <div className="font-medium capitalize text-[12px]">{key} Cookies</div>
                      <div className="text-[11px] text-gray-500">
                        Used for {key === 'analytics' ? 'website analytics' :
                                 key === 'functional' ? 'enhanced functionality' :
                                 'marketing purposes'}.
                      </div>
                    </div>
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={(e) => setCookiePreferences(prev => ({
                          ...prev,
                          [key]: e.target.checked
                        }))}
                        className="sr-only peer"
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#103fb8]"></div>
                    </label>
                  </div>
                ))}
            </div>

            <div className="mt-6 flex justify-end gap-2">
              <button
                onClick={handleSavePreferences}
                className="px-4 py-1 text-[11px] font-medium text-white bg-[#0887FF] hover:bg-[#103fb8] rounded-lg transition-colors"
              >
                Save Preferences
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookieConsent;