import React from 'react';
import Footer from '../Footer';
import Meta from '../Meta';
import Navbar from '../Navbar';
import Paging from '../Paging';
import GetInTouchBtn from '../GetInTouchBtn';

const AntennaPortal = () => {

  return (
    <div>
      <div>
        <Meta
          title="Notionmind® - Transforming Telecommunications Construction Management: The Antenna Portal Solution "
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

      </div>
      {/* header */}
      <div><Navbar /></div>
      {/* header ends */}

      <div className='my-12'>
        <h4 className='text-[var(--blacktext)] text-center m-auto tracking-wider'>CASE STUDY</h4>
        <h1 className='sm:w-[58%] xl:w-[70%] text-3xl md:text-4xl xl:text-6xl pt-4 lg:pt-6 pb-8 px-2 sm:px-0 text-center m-auto tracking-[-0.06em] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] via-[#103FB8] to-[#2B3135] to-[60%] bg-clip-text text-transparent'>Transforming Telecommunications Construction Management: The Antenna Portal Solution </h1>
      </div>

      <div className='mx-2 lg:mx-4 xl:mx-0 mt-4 mb-4 lg:mb-8'>
        <div className="lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-lg shadow-[#DFDFDF] bg-white rounded-3xl sm:rounded-[50px] m-auto p-2 md:p-8 lg:p-16">
          <div className="p-6 lg:px-8 mb-8 lg:mb-20">
            <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 tracking-tighter">
              {/* Left Section */}
              <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
                <div className='justify-items-start pt-2'>
                  <div className='flex-col gap-2'>
                    <div>
                      <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                      <h4 className='mt-4 text-2xl'>Global Mobility Services (GMS)</h4>
                    </div>
                    <div className='my-8'>
                      <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                      <h4 className='mt-4 text-2xl'>Telecom and Infrastructure</h4>
                    </div>
                    <div>
                      <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Project</button>
                      <h4 className='mt-4 text-2xl'>Web-based Management and Reporting Platform</h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* Right Section */}
              <div className="space-y-8 place-content-center">
                {/* Problem */}
                <div>
                  <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
                  <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug">
                  A major telecommunications provider faced challenges in managing construction closeouts, antenna platform validations, and antenna asset operations. They required a centralized solution to streamline workflows, improve data accuracy, and enhance collaboration between construction teams, contractors, and network assurance teams. This case study highlights how our consulting team addressed these needs by implementing a custom web-based portal that standardized processes, improved decision-making, and fostered seamless stakeholder collaboration.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
            <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8 tracking-tighter'>
                <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>
                    <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>The Challenge</h2>
                </div>
                <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                The client's network infrastructure construction projects involved multiple stakeholders - construction admins, contractors, and network assurance teams - all working with different processes and systems. This fragmented approach led to several critical challenges:
                </p>
                <ul className="leading-tight tracking-tighter text-[var(--blacktext)] mt-4 px-4 text-base md:text-[24px] list-outside space-y-2 text-left list-disc">
                  <li>Inconsistent construction closeout documentation</li>
                  <li>Delays in RET and E-tilt script generation</li>
                  <li>Manual validation of antenna platform details</li>
                  <li>Poor visibility into project status</li>
                  <li>Communication gaps between teams</li>
                  <li>Lack of standardized reporting</li>
                  <li>No centralized historical data</li>
                  <li>Quality control issues</li>
                  <li>Inefficient data management</li>
                  <li>Inconsistent reporting</li>
                  <li>Limited visibility into operations</li>
                  <li>Time-consuming manual processes</li>
                  <li>Increased risk of errors</li>
                </ul>

                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Key Objectives:</p>
                <ul className="leading-tight tracking-tighter text-[var(--blacktext)] mt-4 px-4 text-base md:text-[24px] list-outside space-y-2 text-left list-disc">
                  <li>Create a unified platform for construction closeout reporting and validation</li>
                  <li>Automate RET and E-tilt script generation</li>
                  <li>Implement robust quality control measures</li>
                  <li>Standardize processes across all stakeholders</li>
                  <li>Enable real-time project status tracking</li>
                  <li>Improve collaboration between teams</li>
                  <li>Create comprehensive audit trails</li>
                  <li>Reduce project completion times</li>
                </ul>
                </div>
            </div>
          </div>

          <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
            <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8 tracking-tighter'>
                <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>
                    <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>Solution Approach</h2>
                </div>
                <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold'>
                Discovery Phase
                </p>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-9'>Our consulting team conducted:</p>

                <ul className="leading-tight tracking-tighter text-[var(--blacktext)] mt-4 px-4 text-base md:text-[24px] list-outside space-y-2 text-left list-disc">
                  <li>Stakeholder interviews</li>
                  <li>Process analysis</li>
                  <li>Systems assessment</li>
                  <li>Requirements gathering</li>
                  <li>Gap analysis</li>
                </ul>
                </div>
            </div>
          </div>

          <div className='mt-8 lg:mt-16 2xl:mt-20 mb-8'>
            <div className='place-items-center flex flex-col justify-center items-center'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Strategic Recommendations</h1>
              <h5 className='text-center tracking-tighter flex justify-center lg:font-bold xl:w-9/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>Based on our findings, we recommended:</h5>
            </div>
          </div>

          <div className='md:mb-16 xl:mb-10'>
            <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                    <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Centralized Management Platform</h2>
                </div>
                <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                  <ul className="leading-none tracking-tighter text-[var(--blacktext)] sm:px-4 text-base md:text-[20px] lg:text-[24px] list-outside space-y-2 text-left list-item">
                    <li>Web-based portal accessible from anywhere</li>
                    <li>Single source of truth for all antenna data</li>
                    <li>Integrated reporting capabilities</li>
                  </ul>
                </div>
            </div>
            <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                    <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Process Optimization</h2>
                </div>
                <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                  <ul className="leading-none tracking-tighter text-[var(--blacktext)] sm:px-4 text-base md:text-[20px] lg:text-[24px] list-outside space-y-2 text-left list-item">
                    <li>Automated workflows</li>
                    <li>Standardized procedures</li>
                    <li>Real-time data updates</li>
                  </ul>
                </div>
            </div>
            <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                    <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Role-Based Access</h2>
                </div>
                <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                  <ul className="leading-none tracking-tighter text-[var(--blacktext)] sm:px-4 text-base md:text-[20px] lg:text-[24px] list-outside space-y-2 text-left list-item">
                    <li>Customized user permissions</li>
                    <li>Secure data access</li>
                    <li>Audit trail capabilities</li>
                  </ul>
                </div>
            </div>
          </div>

          <div className='my-12 lg:mb-8 xl:mb-12'>
            <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-6 pt-8 pb-2 lg:py-12 md:px-16'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Results and Impact</h1>
              <div className='my-12 tracking-tighter'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Quantitative Benefits</h2>
                <ul className="mt-2 md:text-2xl px-2 text-gray-700 list-outside space-y-2 text-left list-disc">
                  <li> 40% reduction in data entry time</li>
                  <li> 60% faster report generation</li>
                  <li> 90% decrease in data errors</li>
                  <li> 50% reduction in manual processing</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='my-12 lg:mb-8 xl:mb-12'>
            <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-6 pt-8 pb-2 lg:py-12 md:px-16'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Qualitative Improvements</h1>
              <div className='my-12 tracking-tighter'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Enhanced Operations</h2>
                <ul className="mt-2 md:text-2xl px-2 text-gray-700 list-outside space-y-2 text-left list-disc">
                  <li> Streamlined workflows</li>
                  <li> Better data visibility</li>
                  <li> Improved collaboration</li>
                </ul>
              </div>
              <div className='my-12 tracking-tighter'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Better Decision Making</h2>
                <ul className="mt-2 md:text-2xl px-2 text-gray-700 list-outside space-y-2 text-left list-disc">
                  <li> Real-time data access</li>
                  <li> Comprehensive reporting</li>
                  <li> Trend analysis capabilities</li>
                </ul>
              </div>
              <div className='my-12 tracking-tighter'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Increased User Satisfaction</h2>
                <ul className="mt-2 md:text-2xl px-2 text-gray-700 list-outside space-y-2 text-left list-disc">
                  <li> Intuitive interface</li>
                  <li> Faster processes</li>
                  <li> Reduced frustration</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-10'>
            <div className='place-items-center flex flex-col justify-center items-center'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
              <h5 className='text-center tracking-tighter lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>The Antenna Portal project demonstrates how a well-planned digital transformation initiative can dramatically improve operational efficiency and data management. By focusing on user needs and business objectives, the solution delivered tangible benefits while establishing a foundation for future growth and innovation.</h5>
            </div>
          </div>

        </div>
      </div>

      {/* Next Previous CS section */}
      <Paging
        prevLink="/case-studies/all-choices-matter"
        prevTitle="Community Platform"
        nextLink="/case-studies/granger-delivery"
        nextTitle="Granger Delivery" />
      {/* Next Previous CS section ends*/}

      {/* Get In Touch Button */}
      <GetInTouchBtn />
      {/* Get In Touch Button ends */}

      {/* Footer */}
      <div><Footer /></div>
      {/* Footer ends */}
      {/* main div ends  */}
    </div>
  )
}

export default AntennaPortal