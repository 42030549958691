import React from 'react'

const CalendarSystem = () => {
  return (
    <>
      <div className="sm:px-4 lg:px-8 mb-8 lg:mb-20">
        <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Left Section */}
            <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
                <div className='justify-items-start pt-2'>
                    <div className='flex-col gap-2'>
                    <div className='my-4 lg:my-8'>
                        <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                        <h4 className='mt-2 lg:text-xl xl:text-2xl'>Mid-size Enterprise</h4>
                    </div>
                    <div className='my-4 lg:my-8'>
                        <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                        <h4 className='mt-2 lg:text-xl xl:text-2xl'>Property Management Services</h4>
                    </div>
                    </div>
                </div>
            </div>
            {/* Right Section */}
            <div className="space-y-8 place-content-center">
            {/* Problem */}
            <div>
                <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
                <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug tracking-tighter">
                Engineered a seamless calendar system migration from Gmail to Outlook for a property management company, enabling efficient vendor scheduling and appointment management across multiple locations.
                </p>
            </div>
            </div>
        </div>
      </div>

      <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
        <div className='grid lg:grid-cols-5 gap-4 mt-4 xl:mx-8'>
            <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-4 xl:px-2'>
                <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>The Challenge</h2>
            </div>
            <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-6 sm:px-8'>
            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
            Calendar management teams faced multiple challenges:
            </p>

            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Migration Concerns:</p>
            <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
              <li>Complex data transfer requirements</li>
              <li>System compatibility issues</li>
              <li>Continuity needs</li>
            </ul>
            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Integration Issues:</p>
            <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
              <li>Multiple system dependencies</li>
              <li>Sync requirements</li>
              <li>Event management complexity</li>
            </ul>

            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Operational Risks:</p>
            <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
              <li>Business disruption potential</li>
              <li>Data loss concerns</li>
              <li>User adoption challenges</li>
            </ul>

            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Technical Limitations:</p>
            <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
              <li>API compatibility issues</li>
              <li>Authentication complexity</li>
              <li>Performance requirements</li>
            </ul>

            </div>
        </div>
      </div>

      <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
        <div className='grid lg:grid-cols-5 gap-4 mt-4 xl:mx-8'>
            <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-4 xl:px-2'>
                <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>Our Solution</h2>
            </div>
            <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-6 sm:px-8'>

            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-4'>Migration Engine:</p>
            <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
              <li>Microsoft Graph API integration</li>
              <li>Custom Python implementation</li>
              <li>Zapier workflow automation</li>
            </ul>
            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Synchronization System:</p>
            <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
              <li>Real-time calendar updates</li>
              <li>Conflict detection</li>
              <li>Event management</li>
            </ul>

            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Integration Framework:</p>
            <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
              <li>Automated event creation</li>
              <li>Notification system</li>
              <li>Error handling</li>
            </ul>

            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Validation System:</p>
            <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
              <li>Data integrity checks</li>
              <li>Automated testing</li>
              <li>Rollback capabilities</li>
            </ul>

            </div>
        </div>
      </div>

      <div className='mt-8 lg:mt-16 2xl:mt-20 mb-8'>
        <div className='place-items-center flex flex-col justify-center items-center'>
        <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Technical Implementation</h1>
        </div>
      </div>
      <div className='md:mb-16 xl:mb-20'>
          <div className='grid sm:grid-cols-5 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
              <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                  <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>API Layer</h2>
              </div>
              <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
              <ul className="leading-none text-[var(--blacktext)] text-base md:text-[20px] lg:text-[24px] list-outside space-y-2 text-left list-item">
                <li>REST endpoints</li>
                <li>Authentication handlers</li>
                <li>Event processors</li>
              </ul>
              </div>
          </div>
          <div className='grid sm:grid-cols-5 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
              <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                  <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Management Interface</h2>
              </div>
              <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
              <ul className="leading-none text-[var(--blacktext)] text-base md:text-[20px] lg:text-[24px] list-outside space-y-2 text-left list-item">
                <li>Administrative dashboard</li>
                <li>Calendar management tools</li>
                <li>Status monitoring</li>
              </ul>
              </div>
          </div>
          <div className='grid sm:grid-cols-5 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
              <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                  <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Backend System</h2>
              </div>
              <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
                <ul className="leading-none text-[var(--blacktext)] text-base md:text-[20px] lg:text-[24px] list-outside space-y-2 text-left list-item">
                  <li>Event-driven architecture</li>
                  <li>Real-time processing</li>
                  <li>Data synchronization</li>
                </ul>
              </div>
          </div>
      </div>

      <div className='my-12 lg:mb-8 xl:mb-12'>
        <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-6 pt-8 pb-2 lg:py-12 md:px-16'>
            <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Results & Benefits</h1>

            <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Operational Improvements</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                    <li>55% fewer scheduling conflicts</li>
                    <li>70% faster appointment management</li>
                    <li>90% faster calendar updates</li>
                    <li>65% reduced sync errors</li>
                </ul>
            </div>

            <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Business Impact</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                    <li>Improved vendor coordination</li>
                    <li>Enhanced scheduling efficiency</li>
                    <li>Reduced administrative overhead</li>
                    <li>Better resource management</li>
                </ul>
            </div>

            <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">ROI Metrics</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                    <li>30% increased vendor satisfaction</li>
                    <li>45% fewer missed appointments</li>
                    <li>25% better resource utilization</li>
                </ul>
            </div>
        </div>
      </div>

      <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-20'>
        <div className='place-items-center flex flex-col justify-center items-center'>
        <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
        <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>The Calendar System Migration successfully transformed scheduling operations through efficient API integration and automated workflows. This implementation demonstrates how well-planned system migrations can enhance operational efficiency while maintaining business continuity.</h5>
        </div>
      </div>

    </>
  )
}

export default CalendarSystem