import React from 'react';
import Footer from '../Footer';
import Meta from '../Meta';
import Navbar from '../Navbar';
import Paging from '../Paging';
import GetInTouchBtn from '../GetInTouchBtn';

const SfrConsulting = () => {
    return (
        <div>
          <div>
            <Meta
              title="Notionmind® - Digital Transformation for Community Impact: Expanding Reach Through Mobile Innovation"
              description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
              keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
            />

          </div>
          {/* header */}
          <div><Navbar /></div>
          {/* header ends */}

          <div className='my-12'>
            <h4 className='text-[var(--blacktext)] text-center m-auto tracking-wider'>CASE STUDY</h4>
            <h1 className='sm:w-[64%] xl:w-[70%] text-3xl md:text-4xl xl:text-6xl pt-4 lg:pt-6 pb-8 px-2 sm:px-0 text-center m-auto tracking-[-0.06em] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] via-[#103FB8] to-[#2B3135] to-[60%] bg-clip-text text-transparent'>
            Enterprise Property Analytics Platform: Technical Consulting Case Study</h1>
          </div>

          <div className='mx-2 lg:mx-4 xl:mx-0 mt-4 mb-4 lg:mb-8'>
            <div className="lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-lg shadow-[#DFDFDF] bg-white rounded-3xl sm:rounded-[50px] m-auto p-2 md:p-8 lg:p-16">
              <div className="p-6 lg:px-8 mb-8 lg:mb-20">
                <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 tracking-tighter">
                  {/* Left Section */}
                  <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
                    <div className='justify-items-start pt-2'>
                      <div className='flex-col gap-2'>
                        <div>
                          <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                          <h4 className='mt-4 text-2xl'>Conrex Property Management (now Maymont Homes)</h4>
                        </div>
                        <div className='my-8'>
                          <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                          <h4 className='mt-4 text-2xl'>Real Estate Management</h4>
                        </div>
                        <div>
                          <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Project</button>
                          <h4 className='mt-4 text-2xl'>Enterprise Digital Transformation</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Right Section */}
                  <div className="space-y-8 place-content-center">
                    {/* Problem */}
                    <div>
                      <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
                      <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug">
                      Our technical consulting team designed and implemented a sophisticated property data analytics platform for a leading Real Estate Investment firm. Through deep analysis of business requirements and technical constraints, we delivered a scalable solution that transformed raw property data into actionable market intelligence utilizing SCD Type 2 methodology for comprehensive historical tracking.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-8 lg:mt-16 2xl:mt-20 md:mb-10'>
                <div className='place-items-center flex flex-col justify-center items-center'>
                <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Initial Assessment & Strategic Recommendations</h1>
                </div>
              </div>

              <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                  <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                      <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2 2xl:px-4'>
                          <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>Current State Analysis</h2>
                      </div>
                      <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-6 sm:px-8 lg:px-2 2xl:px-4 tracking-tighter'>
                      <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                      After thorough technical discovery sessions with the client's team, we identified several critical limitations:
                      </p>
                      <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] mt-3 px-2 list-outside space-y-2 text-left list-disc">
                        <li>Fragmented data collection processes</li>
                        <li>Lack of historical data preservation</li>
                        <li>Inconsistent property matching across sources</li>
                        <li>Limited analytical capabilities</li>
                        <li>Performance bottlenecks in data processing</li>
                      </ul>
                      </div>
                  </div>
              </div>

              <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                  <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                      <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2 2xl:px-4'>
                          <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>Technology Strategy Insights</h2>
                      </div>
                      <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-6 sm:px-8 tracking-tighter'>
                      <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                      Our technical consulting team proposed a comprehensive solution architecture focused on:
                      </p>
                      <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] mt-4 2xl:mt-8 px-2 list-outside space-y-2 text-left list-disc">
                        <li>Implementation of SCD Type 2 methodology for historical tracking</li>
                        <li>Development of location-based property matching algorithm</li>
                        <li>Creation of scalable ETL pipeline</li>
                        <li>Design of real-time analytics framework</li>
                        <li>Implementation of performance monitoring systems</li>
                      </ul>
                      </div>
                  </div>
              </div>

              <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                  <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                      <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2 2xl:px-4'>
                          <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>Technical Solution Design</h2>
                      </div>
                      <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-6 sm:px-8 tracking-tighter'>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                        Our solution architects designed a sophisticated multi-tier architecture:
                        </p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-4 2xl:mt-8'>Data Collection & Validation Layer</p>
                        <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] mt-3 px-2 list-outside space-y-2 text-left list-disc">
                          <li>Intelligent scraping system with automated rate limiting</li>
                          <li>Real-time data validation framework</li>
                          <li>Source reliability scoring mechanism</li>
                          <li>Dynamic proxy management system</li>
                        </ul>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-4 2xl:mt-8'>Data Processing & Storage Layer</p>
                        <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] mt-3 px-2 list-outside space-y-2 text-left list-disc">
                          <li>Optimized table structures for property master data</li>
                          <li>Sophisticated indexing strategies for location-based queries</li>
                          <li>Advanced partitioning for multi-market scalability</li>
                          <li>Efficient change tracking mechanism</li>
                        </ul>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-4 2xl:mt-8'>Analytics Engine</p>
                        <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] mt-3 px-2 list-outside space-y-2 text-left list-disc">
                          <li>Custom-built market analysis algorithms</li>
                          <li>Real-time trend detection systems</li>
                          <li>Predictive pricing models</li>
                          <li>Competitive position analysis framework</li>
                        </ul>

                      </div>
                  </div>
              </div>

             <div className='my-12 lg:mb-8 xl:mb-12 tracking-tighter'>
                <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-6 pt-8 pb-2 lg:py-12 md:px-16'>
                  <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Investment and Returns</h1>
                  <div className='my-12'>
                    <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Measured Returns</h2>
                    <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                        <li>75% reduction in data processing time</li>
                        <li>60% improvement in analysis speed</li>
                        <li>40% reduction in storage costs</li>
                        <li>99.98% data accuracy achievement</li>
                    </ul>
                  </div>
                  <div className='my-12'>
                    <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Business Impact</h2>
                    <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                        <li>Real-time market insights availability</li>
                        <li>Enhanced competitive analysis capabilities</li>
                        <li>Improved decision-making accuracy</li>
                        <li>Superior market positioning</li>
                    </ul>
                  </div>
                  <div className='my-12'>
                    <h2 className="text-xl sm:text-2xl px-2 font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Consulting Team Expertise</h2>
                    <h2 className="text-xl sm:text-2xl mb-4 lg:mb-8 text-[var(--darkgray)]">Our technical consulting team leveraged:</h2>
                    <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                        <li>Deep data architecture expertise</li>
                        <li>Extensive real estate analytics experience</li>
                        <li>Advanced database optimization knowledge</li>
                        <li>Enterprise solution implementation mastery</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className='my-12 md:mt-16 xl:mt-20'>
                <div className='place-items-center flex flex-col justify-center items-center'>
                  <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
                  <h5 className='text-center tracking-tighter lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>This implementation showcases our technical consulting team's ability to deliver sophisticated solutions while maintaining focus on business value and performance optimization.</h5>
                </div>
              </div>

            </div>
          </div>

          {/* Next Previous CS section */}
          <Paging
            prevLink="/case-studies/granger-delivery"
            prevTitle="Granger Delivery"
            nextLink="/case-studies/cran-planning"
            nextTitle="CRAN Planning" />
          {/* Next Previous CS section ends*/}

          {/* Get In Touch Button */}
          <GetInTouchBtn />
          {/* Get In Touch Button ends */}

          {/* Footer */}
          <div><Footer /></div>
          {/* Footer ends */}
          {/* main div ends  */}
        </div>
      )
}

export default SfrConsulting