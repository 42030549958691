import React from 'react';
import {
  Routes,
  Route,
  // Navigate,
} from "react-router-dom";
import Home from './components/Home';
import CaseStudy from './components/CaseStudy';
import Capabilities from './components/Capabilities';
import Enterprise from './components/Enterprise';
import Partnership from './components/Partnership';
import About from './components/About';
import Getintouch from './components/Getintouch';
import ConrexCS from './components/CaseStudies/Conrex';
import AlconCS from './components/CaseStudies/Alcon';
import AbadCS from './components/CaseStudies/Abad';
import EvolvCS from './components/CaseStudies/Evolv'
import CityofWilloughbyCS from './components/CaseStudies/CityofWilloughby'
import HobbyIdeasCS from './components/CaseStudies/HobbyIdeas'
import Thankyou from './components/thankyou';
import CookieConsent from './components/CookieConsent'
import PrivacyPolicy from './components/PrivacyPolicy'
import TermsOfUse from './components/TermsOfUse';
import ScrollToTop from './components/ScrollToTop';
import GoToWorkAmerica from './components/CaseStudies/GoToWorkAmerica';
import DailyMentalHealthCheck from './components/CaseStudies/DailyMentalHealthCheck';
import Integration from './components/CaseStudies/Integration';
import AllChoicesMatter from './components/CaseStudies/AllChoicesMatter';
import AntennaPortal from './components/CaseStudies/AntennaPortal';
import GrangerDelivery from './components/CaseStudies/GrangerDelivery';
import CranPlanning from './components/CaseStudies/CranPlanning';
import SfrConsulting from './components/CaseStudies/SfrConsulting';
import IncidentManagement from './components/CaseStudies/IncidentManagement';

function App() {
  return (
        <div>
          {/* Cookie consent component */}
          <CookieConsent />
          <ScrollToTop/>
          {/* Routes */}
          <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/capabilities' element={<Capabilities/>}/>
            <Route path='/enterprise' element={<Enterprise/>} />
            <Route path='/partnership' element={<Partnership/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/getintouch' element={<Getintouch/>} />
            <Route path='/thankyou' element={<Thankyou/>} />
            <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
            <Route path='/terms-of-use' element={<TermsOfUse/>} />
            <Route path='/case-studies' element={<CaseStudy/>} />
            <Route path='/case-studies/conrex' element={<ConrexCS/>}/>
            <Route path='/case-studies/abad' element={<AbadCS/>} />
            <Route path='/case-studies/verisurg' element={<AlconCS/>} />
            <Route path='/case-studies/evolv' element={<EvolvCS/>} />
            <Route path='/case-studies/hobby-ideas' element={<HobbyIdeasCS/>} />
            <Route path='/case-studies/city-of-willoughby' element={<CityofWilloughbyCS/>} />
            <Route path='/case-studies/go-to-work-america' element={<GoToWorkAmerica/>} />
            <Route path='/case-studies/daily-mental-health-check' element={<DailyMentalHealthCheck/>} />
            <Route path='/case-studies/integration' element={<Integration/>} />
            <Route path='/case-studies/all-choices-matter' element={<AllChoicesMatter/>} />
            <Route path='/case-studies/antenna-portal' element={<AntennaPortal/>} />
            <Route path='/case-studies/granger-delivery' element={<GrangerDelivery/>} />
            <Route path='/case-studies/cran-planning' element={<CranPlanning/>} />
            <Route path='/case-studies/property-analytics-platform' element={<SfrConsulting/>} />
            <Route path='/case-studies/incident-management' element={<IncidentManagement/>} />
          </Routes>
        </div>
  );
}

export default App;
