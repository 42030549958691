import React from 'react'

const HealthCare = () => {
  return (
    <>
      <div className="sm:px-4 lg:px-8 mb-8 lg:mb-20">
        <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Left Section */}
            <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
                <div className='justify-items-start pt-2'>
                    <div className='flex-col gap-2'>
                    <div className='my-4 lg:my-8'>
                        <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                        <h4 className='mt-2 lg:text-xl xl:text-2xl'>Multi-specialty Medical Practice</h4>
                    </div>
                    <div className='my-4 lg:my-8'>
                        <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                        <h4 className='mt-2 lg:text-xl xl:text-2xl'>Healthcare</h4>
                    </div>
                    </div>
                </div>
            </div>
            {/* Right Section */}
            <div className="space-y-8 place-content-center">
            {/* Problem */}
            <div>
                <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
                <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug tracking-tighter">
                Created a comprehensive patient data management system for a multi-specialty healthcare practice,
                 integrating various healthcare information systems through secure API connections.
                 The solution streamlines patient data handling while ensuring HIPAA compliance and data security.
                </p>
            </div>
            </div>
        </div>
      </div>

      <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
        <div className='grid lg:grid-cols-5 gap-4 mt-4 xl:mx-8'>
            <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-4 xl:px-2'>
                <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>The Challenge</h2>
            </div>
            <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-6 sm:px-8'>
            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
            Healthcare providers faced multiple challenges in managing patient data:
            </p>

            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Data Management Issues:</p>
            <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
              <li>Manual data entry requirements</li>
              <li>Duplicate patient records</li>
              <li>Inconsistent information across systems</li>
            </ul>
            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Integration Limitations:</p>
            <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
              <li>Disconnected healthcare systems</li>
              <li>Complex data synchronization needs</li>
              <li>Limited real-time access</li>
            </ul>

            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Compliance Concerns:</p>
            <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
              <li>HIPAA requirements</li>
              <li>Data security mandates</li>
              <li>Audit trail needs</li>
            </ul>

            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Operational Inefficiencies:</p>
            <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
              <li>Slow patient registration</li>
              <li>Delayed information access</li>
              <li>Resource-intensive processes</li>
            </ul>

            </div>
        </div>
      </div>

      <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
        <div className='grid lg:grid-cols-5 gap-4 mt-4 xl:mx-8'>
            <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-4 xl:px-2'>
                <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>Our Solution</h2>
            </div>
            <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-6 sm:px-8'>

            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-4'>Patient Data API Suite:</p>
            <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
              <li>Comprehensive REST API implementation</li>
              <li>Secure data transmission protocols</li>
              <li>Real-time synchronization system</li>
            </ul>
            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Security Framework:</p>
            <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
              <li>HIPAA-compliant architecture</li>
              <li>End-to-end encryption</li>
              <li>Advanced access controls</li>
            </ul>

            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Integration Engine:</p>
            <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
              <li>Multi-system data synchronization</li>
              <li>Automated validation rules</li>
              <li>Error handling protocols</li>
            </ul>

            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Reporting Module:</p>
            <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
              <li>Custom analytics dashboard</li>
              <li>Automated report generation</li>
              <li>Real-time monitoring tools</li>
            </ul>

            </div>
        </div>
      </div>

      <div className='mt-8 lg:mt-16 2xl:mt-20 mb-8'>
        <div className='place-items-center flex flex-col justify-center items-center'>
        <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Technical Implementation</h1>
        </div>
      </div>
      <div className='md:mb-16 xl:mb-20'>
          <div className='grid sm:grid-cols-5 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
              <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                  <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>API Layer</h2>
              </div>
              <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
              <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] list-outside space-y-2 text-left list-item">
                <li>RESTful endpoints for all operations</li>
                <li>OAuth 2.0 authentication</li>
                <li>Custom middleware solutions</li>
              </ul>
              </div>
          </div>
          <div className='grid sm:grid-cols-5 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
              <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                  <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Web Interface</h2>
              </div>
              <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
              <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] list-outside space-y-2 text-left list-item">
                <li>User-friendly admin dashboard</li>
                <li>Patient data management tools</li>
                <li>Reporting and analytics features</li>
              </ul>
              </div>
          </div>
          <div className='grid sm:grid-cols-5 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
              <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                  <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Backend System</h2>
              </div>
              <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
                <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] list-outside space-y-2 text-left list-item">
                  <li>Spring Boot implementation</li>
                  <li>Event-driven architecture</li>
                  <li>Real-time processing capabilities</li>
                </ul>
              </div>
          </div>
      </div>

      <div className='my-12 lg:mb-8 xl:mb-12'>
        <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-6 pt-8 pb-2 lg:py-12 md:px-16'>
            <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Results & Benefits</h1>

            <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Operational Improvements</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                    <li>45% faster patient registration</li>
                    <li>60% reduction in data errors</li>
                    <li>30% increase in daily patient capacity</li>
                    <li>85% improvement in data consistency</li>
                </ul>
            </div>

            <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Business Impact</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                    <li>Enhanced patient experience</li>
                    <li>Improved staff efficiency</li>
                    <li>Reduced operational costs</li>
                    <li>Better resource utilization</li>
                </ul>
            </div>

            <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">ROI Metrics</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                    <li>35% improvement in patient satisfaction</li>
                    <li>50% reduction in wait times</li>
                    <li>40% decrease in administrative overhead</li>
                </ul>
            </div>
        </div>
      </div>

      <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-20'>
        <div className='place-items-center flex flex-col justify-center items-center'>
        <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
        <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>The Healthcare Data Integration Platform successfully transformed patient data management through secure API integrations, automated workflows, and efficient data synchronization. This case study showcases how strategic API implementation can revolutionize healthcare operations while maintaining strict compliance and security standards.</h5>
        </div>
      </div>

    </>
  )
}

export default HealthCare