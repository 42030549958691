import React from 'react';
import Footer from '../Footer';
import Meta from '../Meta';
import Navbar from '../Navbar';
import Paging from '../Paging';
import GetInTouchBtn from '../GetInTouchBtn';

const AllChoicesMatter = () => {

  return (
    <div>
      <div>
        <Meta
          title="Notionmind® - Digital Transformation for Community Impact: Expanding Reach Through Mobile Innovation"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

      </div>
      {/* header */}
      <div><Navbar /></div>
      {/* header ends */}

      <div className='my-12'>
        <h4 className='text-[var(--blacktext)] text-center m-auto tracking-wider'>CASE STUDY</h4>
        <h1 className='sm:w-[64%] xl:w-[70%] text-3xl md:text-4xl xl:text-6xl pt-4 lg:pt-6 pb-8 px-2 sm:px-0 text-center m-auto tracking-[-0.06em] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] via-[#103FB8] to-[#2B3135] to-[60%] bg-clip-text text-transparent'>Digital Transformation for Community Impact: Expanding Reach Through Mobile Innovation</h1>
      </div>

      <div className='mx-2 lg:mx-4 xl:mx-0 mt-4 mb-4 lg:mb-8'>
        <div className="lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-lg shadow-[#DFDFDF] bg-white rounded-3xl sm:rounded-[50px] m-auto p-2 md:p-8 lg:p-16">
          <div className="p-6 lg:px-8 mb-8 lg:mb-20">
            <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 tracking-tighter">
              {/* Left Section */}
              <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
                <div className='justify-items-start pt-2'>
                  <div className='flex-col gap-2'>
                    <div>
                      <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                      <h4 className='mt-4 text-2xl'>All Choices Matter</h4>
                    </div>
                    <div className='my-8'>
                      <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                      <h4 className='mt-4 text-2xl'>Community and Nonprofit</h4>
                    </div>
                    <div>
                      <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Project</button>
                      <h4 className='mt-4 text-2xl'>Mobile & Web-based Platform</h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* Right Section */}
              <div className="space-y-8 place-content-center">
                {/* Problem */}
                <div>
                  <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
                  <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug">
                  Two community-focused organizations, "All Choices Matter" and "We Thrive Together," sought to expand their reach and impact through digital transformation. This case study examines how we helped transform their web-based platforms into engaging mobile experiences, significantly expanding their ability to serve youth and combat social isolation.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-8 lg:mt-16 2xl:mt-20 mb-8'>
            <div className='place-items-center flex flex-col justify-center items-center'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Client Background</h1>
            </div>
          </div>

          <div className='md:mb-16 xl:mb-20'>
            <div className='grid lg:grid-cols-6 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 xl:px-12'>
                    <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>All Choices Matter</h2>
                </div>
                <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 xl:px-12'>
                  <ul className="leading-tight tracking-tighter text-[var(--blacktext)] text-base md:text-[24px] list-outside space-y-2 text-left list-item">
                    <li>Youth development platform</li>
                    <li>800+ educational videos</li>
                    <li>Career and life skills focus</li>
                    <li>Character development resources</li>
                    <li>Critical life challenges support</li>
                  </ul>
                </div>
            </div>
            <div className='grid lg:grid-cols-6 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 xl:px-12'>
                    <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>We Thrive Together</h2>
                </div>
                <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 xl:px-12'>
                  <ul className="leading-tight tracking-tighter text-[var(--blacktext)] text-base md:text-[24px] list-outside space-y-2 text-left list-item">
                    <li> Virtual community platform</li>
                    <li>6,000+ virtual programs</li>
                    <li>Cross-border engagement</li>
                    <li>Social connection focus</li>
                    <li>Diverse activity offerings</li>
                  </ul>
                </div>
            </div>
          </div>

          <div className='mt-8 lg:mt-16 2xl:mt-20 mb-8'>
            <div className='place-items-center flex flex-col justify-center items-center'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Business Challenge</h1>
            </div>
          </div>

          <div className='md:mb-16 xl:mb-20'>
            <div className='grid lg:grid-cols-6 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 xl:px-12'>
                    <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Initial Situation</h2>
                </div>
                <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 xl:px-12'>
                  <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mb-4'>Both organizations faced:</p>
                  <ul className="leading-tight tracking-tighter text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
                    <li>Limited mobile accessibility</li>
                    <li>Platform engagement barriers</li>
                    <li>Geographic reach constraints</li>
                    <li>User experience limitations</li>
                    <li>Content delivery challenges</li>
                    <li>Community building hurdles</li>
                    <li>Resource access issues</li>
                    <li>Engagement tracking difficulties</li>
                  </ul>
                </div>
            </div>
          </div>


          <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
            <div className='grid lg:grid-cols-6 gap-4 mt-4 xl:mx-8 px-2 sm:px-6 lg:px-0'>
                <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-6 xl:px-2'>
                    <h2 className='text-3xl sm:text-5xl tracking-tighter text-[var(--blacktext)]'>Strategic Objectives</h2>
                </div>

                <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 xl:px-8 tracking-tighter'>
                  <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold'>Enhanced Accessibility:</p>
                  <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] mt-3 px-2 list-outside space-y-2 text-left list-disc">
                    <li>Mobile-first experience</li>
                    <li>Seamless content access</li>
                    <li>Cross-platform availability</li>
                    <li>Offline capabilities</li>
                  </ul>

                  <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Improved Engagement:</p>
                  <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] mt-3 px-2 list-outside space-y-2 text-left list-disc">
                    <li>Interactive features</li>
                    <li>Social connectivity</li>
                    <li>Real-time participation</li>
                    <li>Community building</li>
                  </ul>

                  <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Extended Reach:</p>
                  <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] mt-3 px-2 list-outside space-y-2 text-left list-disc">
                    <li>Geographic expansion</li>
                    <li>Demographic diversity</li>
                    <li>Institution partnerships</li>
                    <li>Community integration</li>
                  </ul>
                </div>
            </div>
          </div>

          <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-20'>
            <div className='place-items-center flex flex-col justify-center items-center'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Solution Approach</h1>
              <h5 className='text-center lg:w-8/12 px-4 text-base md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>Digital Transformation Strategy</h5>
            </div>
          </div>

          <div className='m-auto tracking-tighter'>
            {/* row 1 */}
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 px-4 sm:px-0 mt-6">

              <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">
                {/* Title Section */}
                <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                Platform Integration</p>

                <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                  <li>Unified content management</li>
                  <li>Synchronized experiences</li>
                  <li>Cross-platform consistency</li>
                  <li>Real-time updates</li>
                </ul>
              </div>

              <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">
                {/* Title Section */}
                <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                User Experience Design</p>

                <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                  <li>Age-appropriate interfaces</li>
                  <li>Intuitive navigation</li>
                  <li>Engaging interactions</li>
                  <li>Accessibility compliance</li>
                </ul>
              </div>

              <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">
                {/* Title Section */}
                <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                Community Features</p>

                <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                  <li>Event participation</li>
                  <li>Content discovery</li>
                  <li>Social connections</li>
                  <li>Resource access</li>
                </ul>
              </div>

            </div>
          </div>


          <div className='my-8 md:mt-16 xl:mt-8 2xl:mt-10'>
            <div className='place-items-center flex flex-col justify-center items-center'>
              <h5 className='text-center lg:w-8/12 px-4 text-base md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>Implementation Highlights</h5>
            </div>
          </div>

          <div className='m-auto tracking-tighter'>
            {/* row 1 */}
            <div className="grid gap-4 lg:gap-8 grid-cols-1 md:grid-cols-2 px-4 sm:px-0 mt-4">

              <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">
                {/* Title Section */}
                <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-9/12 text-[var(--blacktext)] leading-none">
                All Choices Matter App</p>
                <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                  <li>Video library integration</li>
                  <li>Topic-based navigation</li>
                  <li>Career exploration tools</li>
                  <li>Life skills resources</li>
                  <li>Youth safety features</li>
                </ul>
              </div>

              <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">
                {/* Title Section */}
                <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-9/12 text-[var(--blacktext)] leading-none">
                User Experience Design</p>
                <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                  <li>Virtual event platform</li>
                  <li>Real-time participation</li>
                  <li>Community engagement</li>
                  <li>Activity scheduling</li>
                  <li>Social connection tools</li>
                </ul>
              </div>

            </div>



          </div>


          <div className='my-12 md:mt-16 xl:mt-8 2xl:mt-10'>
            <div className='place-items-center flex flex-col justify-center items-center'>
            <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Business Impact</h1>
            </div>
          </div>


          <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
            <div className='grid lg:grid-cols-6 gap-4 mt-4 xl:mx-8 tracking-tighter'>
                <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 xl:px-2'>
                    <h2 className='text-3xl lg:text-4xl xl:text-5xl tracking-tight text-[var(--blacktext)] lg:w-8/12'>Quantitative Results</h2>
                </div>
                <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-4 list-outside space-y-2 text-left list-disc">
                  <li>300% increase in mobile engagement</li>
                  <li>200% growth in user base</li>
                  <li>150% rise in content access</li>
                  <li>400% increase in virtual event participation</li>
                </ul>
                </div>
            </div>
          </div>

          <div className='my-12 lg:mb-8 xl:mb-12 tracking-tighter'>
            <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-6 pt-8 pb-2 lg:py-12 md:px-16'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Qualitative Improvements</h1>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Youth Engagement</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                    <li>Better resource access</li>
                    <li>Increased participation</li>
                    <li>Enhanced learning</li>
                    <li>Stronger connections</li>
                </ul>
              </div>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Community Building</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                    <li>Reduced isolation</li>
                    <li>Expanded reach</li>
                    <li>Deeper engagement</li>
                    <li>Stronger relationships</li>
                </ul>
              </div>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl px-2 font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Program Delivery</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                    <li>Streamlined access</li>
                    <li>Better organization</li>
                    <li>Enhanced tracking</li>
                    <li>Improved outcomes</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-10'>
            <div className='place-items-center flex flex-col justify-center items-center'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
              <h5 className='text-center tracking-tighter lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>This digital transformation project demonstrates how thoughtful mobile innovation can amplify community impact and social connection. By focusing on accessibility and engagement, we delivered solutions that help these organizations better serve their communities and achieve their missions.</h5>
            </div>
          </div>

        </div>
      </div>

      {/* Next Previous CS section */}
      <Paging
        prevLink="/case-studies/integration"
        prevTitle="System Integration"
        nextLink="/case-studies/antenna-portal"
        nextTitle="Antenna Portal" />
      {/* Next Previous CS section ends*/}

      {/* Get In Touch Button */}
      <GetInTouchBtn />
      {/* Get In Touch Button ends */}

      {/* Footer */}
      <div><Footer /></div>
      {/* Footer ends */}
      {/* main div ends  */}
    </div>
  )
}

export default AllChoicesMatter