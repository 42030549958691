import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Meta from '../Meta'
import Paging from '../Paging';
import GetInTouchBtn from '../GetInTouchBtn';

const CityofWilloughby = () => {

  return (
    <div>
      <div>
      <Meta
          title="Notionmind® - Smart City Mobile Application: City of Willoughby"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />
     
      </div>
      {/* header */}
      <div><Navbar/></div>
      {/* header ends */}

      <div className='my-12'>
            <h4 className='text-[var(--blacktext)] text-center m-auto tracking-wider'>CASE STUDY</h4>
            <h1 className='sm:w-[58%] xl:w-[70%] text-3xl md:text-4xl xl:text-6xl pt-4 lg:pt-6 pb-8 px-2 sm:px-0 text-center m-auto tracking-[-0.06em] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] via-[#103FB8] to-[#2B3135] to-[60%] bg-clip-text text-transparent'>Smart City Mobile Application: City of Willoughby</h1>
        </div>

        <div className='mx-2 lg:mx-4 xl:mx-0 mt-4 mb-4 lg:mb-8'>
            <div className="lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-lg shadow-[#DFDFDF] bg-white rounded-3xl sm:rounded-[50px] m-auto p-2 md:p-8 lg:p-16">
                <div className="p-6 md:px-4 lg:px-8 mb-8 lg:mb-20">
                    <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {/* Left Section */}
                        <div className='lg:border-r-2 lg:border-[#e7e7e7] place-content-center'>
                            <div className='justify-items-start pt-2'>
                                <div className='flex-col gap-2'>
                                <div>
                                    <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                                    <h4 className='mt-4 text-2xl'>City of Willoughby</h4>
                                </div>
                                <div className='my-8'>
                                    <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                                    <h4 className='mt-4 text-2xl'>Government/Smart City</h4>
                                </div>
                                <div>
                                    <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Project</button>
                                    <h4 className='mt-4 text-2xl'>Cross-Platform Mobile Application Development</h4>
                                </div>
                                </div>
                            </div>
                        </div>
                        {/* Right Section */}
                        <div className="space-y-8 place-content-center">
                        {/* Problem */}
                        <div>
                            <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
                            <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug">
                            The City of Willoughby, located in Ohio, USA, embarked on a cross-platform mobile application project to modernize its citizen engagement and enhance access to city services.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>

                <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                    <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                        <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>                    
                            <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>The Challenge</h2>
                        </div>
                        <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                        The City of Willoughby faced challenges with limited digital access to services, inefficient citizen communication, outdated incident reporting, and lack of centralized public WiFi management, all of which hindered effective service delivery and data-driven decision-making.
                        </p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Limited Digital Access to Services:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Citizens had to rely on traditional methods to access city services, leading to inefficiencies and delays in service delivery.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Inefficient Citizen Communication:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Fragmented communication channels made it difficult for the city to deliver timely updates and engage with residents effectively.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Outdated Incident Reporting:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>The manual, outdated process for reporting incidents lacked real-time tracking, leading to delayed responses and poor resource management.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Lack of Centralized Public WiFi Management:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>The city lacked a unified system to manage public WiFi access, secure authentication, and monitor usage, affecting network performance and security.</p>
                        
                        </div>
                    </div>
                </div>

                <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                    <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                        <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>                    
                            <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>Our Solution</h2>
                        </div>
                        <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                        The solution addressed these challenges with a comprehensive mobile application, including core components such as:
                        </p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Public WiFi Infrastructure:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Secure WiFi access, usage tracking, and analytics.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Incident Reporting System:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Real-time incident submission, photo attachment, and GPS location integration.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>City Information Hub:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Easy access to city news, meeting schedules, and a document library.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Citizen Engagement Features:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Push notifications, feedback collection, and direct communication channels.</p>
                        
                        </div>
                    </div>
                </div>

                <div className='mt-8 lg:mt-16 2xl:mt-20 mb-8'>
                    <div className='place-items-center flex flex-col justify-center items-center'>
                    <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Technical Implementation</h1>
                    <h5 className='text-center flex justify-center lg:font-bold xl:w-9/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>Our long-term partnerships cut costs, speed up delivery, and drive innovation—keeping your business ready for the future..</h5>
                    </div>
                </div>
                <div className='md:mb-16 xl:mb-20'>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Mobile Application Development</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            The mobile app was designed for both iOS and Android platforms, ensuring accessibility for a wide range of devices. It featured a responsive design, offline functionality, and real-time updates to keep citizens connected to city services even in areas with limited internet access.                            
                            </h5>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Backend Systems</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            A robust backend was created to manage user authentication, store and retrieve content, and track usage data. This system also enabled seamless integration with third-party services and handled the management of push notifications to keep citizens informed.
                            </h5>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Public WiFi Management</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            The implementation included features for secure user authentication, usage tracking, and data analytics, allowing the city to monitor network performance and ensure a secure and stable WiFi connection for users in public spaces.
                            </h5>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Incident Reporting System</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            The system enabled citizens to report issues directly from the app, attaching photos, providing GPS coordinates, and tracking the status of their reports. Incidents were routed to the appropriate departments for timely resolution, with automatic notifications sent to users regarding updates.                            
                            </h5>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Admin Portal</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            An administrative interface was developed to allow city staff to manage content, monitor citizen interactions, track engagement metrics, and generate reports. This portal provided tools to analyze performance, oversee WiFi usage, and ensure timely responses to citizen inquiries.                            
                            </h5>
                        </div>
                    </div>
                </div>
                
                <div className='my-12 lg:mb-8 xl:mb-12'>
                    <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-4 pt-8 pb-2 lg:py-12 md:px-16'>
                       <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Results & Benefits</h1>
                       <div className='text-center my-12 place-items-center flex flex-col justify-center'>
                            <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Citizen Impact</h2>
                            <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>Enhanced access to city services, faster incident resolution, and increased engagement with city officials.</h5>
                        </div>
                        <div className='text-center my-12 place-items-center flex flex-col justify-center'>
                            <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Operational Improvements</h2>
                            <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>More efficient incident management, better resource allocation, and faster response times.</h5>
                        </div>
                        <div className='text-center my-12 place-items-center flex flex-col justify-center'>
                            <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Key Metrics</h2>
                            <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>Improved citizen engagement, faster response times, and higher service satisfaction.</h5>
                        </div>
                    </div>
                </div>

                <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-20'>
                    <div className='place-items-center flex flex-col justify-center items-center'>
                    <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
                    <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>The Smart City Mobile Application for the City of Willoughby successfully modernized citizen engagement and service delivery. By addressing critical challenges such as inefficient communication, outdated incident reporting, and lack of centralized WiFi management, the solution improved accessibility, responsiveness, and operational efficiency. The implementation fostered stronger connections between citizens and city officials, empowered data-driven decision-making, and enhanced the overall quality of life for residents, laying a solid foundation for future growth in smart city initiatives.</h5>
                    </div>
                </div>

            </div>
        </div>

      {/* Next Previous CS section */}
      <Paging
        prevLink="/case-studies/evolv"
        prevTitle="Evolv Technology"
        nextLink="/case-studies/hobby-ideas"
        nextTitle="HobbyIdeas" />
      {/* Next Previous CS section ends*/}

      {/* Get In Touch Button */}
      <GetInTouchBtn />
      {/* Get In Touch Button ends */}

      {/* Footer */}
      <div><Footer/></div>
      {/* Footer ends */}
      {/* main div ends  */}
    </div>
  )
}

export default CityofWilloughby