import React from 'react';
import Footer from '../Footer';
import Meta from '../Meta';
import Navbar from '../Navbar';
import Paging from '../Paging';
import GetInTouchBtn from '../GetInTouchBtn';

const CranPlanning = () => {
    return (
        <div>
          <div>
            <Meta
              title="Notionmind® - Digital Transformation for Community Impact: Expanding Reach Through Mobile Innovation"
              description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
              keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
            />

          </div>
          {/* header */}
          <div><Navbar /></div>
          {/* header ends */}

          <div className='my-12'>
            <h4 className='text-[var(--blacktext)] text-center m-auto tracking-wider'>CASE STUDY</h4>
            <h1 className='sm:w-[64%] xl:w-[70%] text-3xl md:text-4xl xl:text-6xl pt-4 lg:pt-6 pb-8 px-2 sm:px-0 text-center m-auto tracking-[-0.06em] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] via-[#103FB8] to-[#2B3135] to-[60%] bg-clip-text text-transparent'>
                Transforming Network Infrastructure Planning: A Digital Evolution Story</h1>
          </div>

          <div className='mx-2 lg:mx-4 xl:mx-0 mt-4 mb-4 lg:mb-8'>
            <div className="lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-lg shadow-[#DFDFDF] bg-white rounded-3xl sm:rounded-[50px] m-auto p-2 md:p-8 lg:p-16">
              <div className="p-6 lg:px-8 mb-8 lg:mb-20">
                <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 tracking-tighter">
                  {/* Left Section */}
                  <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
                    <div className='justify-items-start pt-2'>
                      <div className='flex-col gap-2'>
                        <div>
                          <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                          <h4 className='mt-4 text-2xl'>Global Mobility Services (GMS)</h4>
                        </div>
                        <div className='my-8'>
                          <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                          <h4 className='mt-4 text-2xl'>Telecom</h4>
                        </div>
                        <div>
                          <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Project</button>
                          <h4 className='mt-4 text-2xl'>Web-based Automation & Management Platform</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Right Section */}
                  <div className="space-y-8 place-content-center">
                    {/* Problem */}
                    <div>
                      <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
                      <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug">
                      A major telecommunications provider operating across eight U.S. states faced significant challenges in managing their 4G and 5G network infrastructure planning. Global Mobility Services (GMS) delivered a transformative solution that revolutionized how the client manages network resource allocation, resulting in improved accuracy, enhanced efficiency, and significant cost savings.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-8 lg:mt-16 2xl:mt-20 md:mb-10'>
                <div className='place-items-center flex flex-col justify-center items-center'>
                <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Business Challenge</h1>
                </div>
              </div>

              <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                <div className='grid lg:grid-cols-6 gap-4 mt-4 xl:mx-8 px-2 sm:px-6 lg:px-0'>
                    <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-6 xl:px-2'>
                        <h2 className='text-3xl sm:text-5xl tracking-tighter text-[var(--blacktext)]'>Initial Situation</h2>
                    </div>

                    <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 xl:px-8 tracking-tighter'>
                      <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold'>The client struggled with:</p>
                      <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] mt-3 px-2 list-outside space-y-2 text-left list-disc">
                        <li>Manual spreadsheet-based port management</li>
                        <li>Inconsistent planning processes across regions</li>
                        <li>Poor visibility into resource availability</li>
                        <li>Risk of errors in network planning</li>
                        <li>Time-consuming resource allocation</li>
                        <li>Lack of standardization across markets</li>
                        <li>Inefficient collaboration between teams</li>
                        <li>Limited real-time tracking capabilities</li>
                      </ul>
                    </div>
                </div>
              </div>

              <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                <div className='grid lg:grid-cols-6 gap-4 mt-4 xl:mx-8 px-2 sm:px-6 lg:px-0'>
                    <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-6 xl:px-2'>
                        <h2 className='text-3xl sm:text-5xl tracking-tighter text-[var(--blacktext)]'>Strategic Objectives</h2>
                    </div>

                    <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 xl:px-8 tracking-tighter'>
                      <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] mt-3 px-2 list-outside space-y-2 text-left list-disc">
                        <li>Standardize network planning processes nationwide</li>
                        <li>Eliminate manual tracking dependencies</li>
                        <li>Improve resource allocation accuracy</li>
                        <li>Enable real-time visibility</li>
                        <li>Enhance cross-team collaboration</li>
                        <li>Reduce planning cycle time</li>
                        <li>Minimize integration errors</li>
                        <li>Support rapid network expansion</li>
                      </ul>
                    </div>
                </div>
              </div>

              <div className='my-4 lg:my-12 md:mt-16 lg:mt-20'>
              <div className='place-items-center flex flex-col justify-center items-center'>
                <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Solution Approach</h1>
              </div>
              </div>

              <div className='m-auto tracking-tighter'>
                <div className="grid gap-4 2xl:gap-6 grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 px-4 sm:px-0 mt-6">
                  <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                    <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                    Process Standardization</p>

                    <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                      <li>Unified planning methodology</li>
                      <li>Standardized workflows</li>
                      <li>Best practice implementation</li>
                      <li>Quality control measures</li>
                    </ul>
                  </div>

                  <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                    <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                    Resource Visualization</p>

                    <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                      <li>Interactive mapping capabilities</li>
                      <li>Real-time status tracking</li>
                      <li>Visual resource allocation</li>
                      <li>Capacity planning tools</li>
                    </ul>
                  </div>

                  <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                    <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                    Information Integration</p>

                    <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                      <li>Centralized data management</li>
                      <li>Automated data synchronization</li>
                      <li>Cross-system compatibility</li>
                      <li>Real-time updates</li>
                    </ul>
                  </div>
                  <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                    <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                    Operational Excellence</p>

                    <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                      <li>Streamlined planning processes</li>
                      <li>Automated resource tracking</li>
                      <li>Enhanced accuracy</li>
                      <li>Reduced manual effort</li>
                    </ul>
                  </div>
                  <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                    <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                    Decision Support</p>

                    <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                      <li>Real-time insights</li>
                      <li>Visual planning tools</li>
                      <li>Resource optimization</li>
                      <li>Predictive analytics</li>
                    </ul>
                  </div>

                  <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                    <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                    Risk Mitigation</p>

                    <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                      <li>Error reduction</li>
                      <li>Compliance management</li>
                      <li>Data integrity</li>
                      <li>Change control</li>
                    </ul>
                  </div>

                </div>
              </div>

              <div className='mt-8 lg:mt-16 2xl:mt-20 md:mb-10'>
                  <div className='place-items-center flex flex-col justify-center items-center'>
                  <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Implementation Approach</h1>
                  </div>
              </div>
              <div className='md:mb-16 xl:mb-20 tracking-tighter'>
                  <div className='grid sm:grid-cols-6 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                      <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                          <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Phase 1: Foundation</h2>
                      </div>
                      <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
                          <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] sm:px-4 list-outside space-y-2 text-left list-item">
                            <li>Stakeholder engagemen</li>
                            <li>Process assessment</li>
                            <li>Requirements gathering</li>
                            <li>Solution design</li>
                          </ul>
                      </div>
                  </div>
                  <div className='grid sm:grid-cols-6 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                      <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                          <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Phase 2: Deployment</h2>
                      </div>
                      <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
                          <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] sm:px-4 list-outside space-y-2 text-left list-item">
                            <li>Pilot market launch</li>
                            <li>User training</li>
                            <li>Process integration</li>
                            <li>Performance monitoring</li>
                          </ul>
                      </div>
                  </div>
                  <div className='grid sm:grid-cols-6 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                      <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                          <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Phase 3: Expansion</h2>
                      </div>
                      <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
                          <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] sm:px-4 list-outside space-y-2 text-left list-item">
                            <li>Multi-market rollout</li>
                            <li>Process refinement</li>
                            <li>Advanced feature deployment</li>
                            <li>Performance optimization</li>
                          </ul>
                      </div>
                  </div>
              </div>

              <div className='my-12 md:mt-16 xl:mt-8 2xl:mt-10'>
                <div className='place-items-center flex flex-col justify-center items-center'>
                <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Business Impact</h1>
                </div>
              </div>
              <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                <div className='grid lg:grid-cols-6 gap-4 mt-4 xl:mx-8 tracking-tighter'>
                    <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 xl:px-2'>
                        <h2 className='text-3xl lg:text-4xl xl:text-5xl tracking-tight text-[var(--blacktext)] lg:w-8/12'>Quantitative Results</h2>
                    </div>
                    <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                    <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] sm:px-4 list-outside space-y-2 text-left list-item">
                      <li>70% reduction in planning time</li>
                      <li>90% decrease in allocation errors</li>
                      <li>50% faster resource assignment</li>
                      <li>100% elimination of manual tracking</li>
                    </ul>
                    </div>
                </div>
              </div>

              <div className='my-12 lg:mb-8 xl:mb-12 tracking-tighter'>
                <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-6 pt-8 pb-2 lg:py-12 md:px-16'>
                  <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Qualitative Improvements</h1>
                  <div className='my-12'>
                    <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Enhanced Operations</h2>
                    <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                    <li>Standardized processes</li>
                    <li>Improved visibility</li>
                    <li>Better collaboration</li>
                    <li>Faster decision-making</li>
                    </ul>
                  </div>
                  <div className='my-12'>
                    <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Risk Reduction</h2>
                    <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                     <li>Fewer planning errors</li>
                     <li>Better compliance</li>
                     <li>Improved tracking</li>
                     <li>Enhanced security</li>
                    </ul>
                  </div>
                  <div className='my-12'>
                    <h2 className="text-xl sm:text-2xl px-2 font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Team Efficiency</h2>
                    <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                     <li>Simplified workflows</li>
                     <li>Better communication</li>
                     <li>Reduced frustration</li>
                     <li>Increased productivity</li>
                    </ul>
                  </div>
                </div>
              </div>

            <div className='my-4 md:mt-16 lg:mt-20'>
              <div className='place-items-center flex flex-col justify-center items-center'>
                <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Success Factors</h1>
              </div>
            </div>

          <div className='m-auto tracking-tighter'>
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 px-4 sm:px-0 mt-6">
              <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                Change Management</p>

                <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                  <li>Comprehensive training</li>
                  <li>Stakeholder engagement</li>
                  <li>Clear communication</li>
                  <li>User support</li>
                </ul>
              </div>

              <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                Process Excellence</p>

                <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                  <li>Standardized workflows</li>
                  <li>Best practices</li>
                  <li>Quality controls</li>
                  <li>Performance metrics</li>
                </ul>
              </div>

              <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                Solution Design</p>

                <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                  <li>User-centric approach</li>
                  <li>Intuitive interfaces</li>
                  <li>Flexible architecture</li>
                  <li>Scalable platform</li>
                </ul>
              </div>

            </div>
          </div>

              <div className='my-12 md:mt-16 xl:mt-20'>
                <div className='place-items-center flex flex-col justify-center items-center'>
                  <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
                  <h5 className='text-center tracking-tighter lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>The CRAN Planning solution demonstrates how strategic digital transformation can revolutionize complex operational processes. By focusing on business outcomes and user needs, GMS delivered a solution that not only solved immediate challenges but also created a foundation for future growth and innovation.</h5>
                </div>
              </div>

            </div>
          </div>

          {/* Next Previous CS section */}
          <Paging
            prevLink="/case-studies/property-analytics-platform"
            prevTitle="Property Analytics"
            nextLink="/case-studies/incident-management"
            nextTitle="Incident Management" />
          {/* Next Previous CS section ends*/}

          {/* Get In Touch Button */}
          <GetInTouchBtn />
          {/* Get In Touch Button ends */}

          {/* Footer */}
          <div><Footer /></div>
          {/* Footer ends */}
          {/* main div ends  */}
        </div>
      )
}

export default CranPlanning