import React from 'react';
import { Link } from 'react-router-dom';
import OutlineLogo from '../images/Outline-logo.svg';
import ScrollButton from './ScrollButton';

const Footer = () => {
  return (
    <>

    <ScrollButton/>

    <div className='mx-3 lg:mx-4 xl:mx-0'>
    <div className='lg:max-w-[1200px] 2xl:max-w-[1380px] m-auto mt-8 px-4 md:px-12 pt-12 -pb-4 bg-white rounded-t-3xl sm:rounded-t-[50px]'>
        <div className='grid md:grid-cols-3 lg:grid-flow-col gap-4'>
            <div>
                <div className='lg:m-auto'>
                    <h5 className='text-[#606060] mb-2'>Location</h5>
                    <h2 className='text-base'>Ahmedabad, Mumbai</h2>
                    <h2 className='text-base'>USA, Spain</h2>
                </div>
            </div>
            <div>
                <div className='lg:m-auto'>
                    <h5 className='text-[#606060] mb-2'>Get in touch</h5>
                    <h4 className='text-base underline md:decoration-white hover:text-[var(--notionblue)]'>+91 98985 58679</h4>
                    <h4 className='text-base underline md:decoration-white hover:text-[var(--notionblue)]'>+1 844.360.7360</h4>
                </div>
            </div>
            <div>
                <div className='lg:m-auto'>
                    <h5 className='text-[#606060] mb-2'>Contact us</h5>
                    <Link to="mailto:info@notionmind.com"><h2 className='text-base hover:underline hover:text-[var(--notionblue)]'>info@notionmind.com</h2></Link>
                    <Link to="https://calendar.app.google/8tnXSPfQKnhBvnMn7"><h2 className='text-base hover:underline hover:text-[var(--notionblue)]'>Book a call</h2></Link>
                </div>
            </div>
            <div className='lg:me-8 xl:me-20'>
                <div className='lg:m-auto'>
                    <h5 className='text-[#606060] mb-2'>Find us on</h5>
                    <Link to="https://in.linkedin.com/company/notionmind"><h2 className='text-base hover:underline hover:text-[var(--notionblue)]'>LinkedIn</h2></Link>
                    <Link to="https://www.instagram.com/notionmind/"><h2 className='text-base hover:underline hover:text-[var(--notionblue)]'>Instagram</h2></Link>
                </div>
            </div>
            <div>
                <div className='lg:m-auto'>
                    <h2 className='text-[#606060] mb-2'>© 2025</h2>
                    <h2 className='text-base'><Link to='https://notionmind.com/' className='hover:underline hover:text-[var(--notionblue)]'>Notionmind<sup>&reg;</sup></Link>, <Link to='https://knowtion-inc.com/' className='hover:underline hover:text-[var(--notionblue)]'>Knowtion, Inc.</Link></h2>
                    <h2 className='text-base'>All rights reserved.</h2>
                    <h2 className='text-sm'><Link to='/privacy-policy' className='hover:underline hover:text-[var(--notionblue)]'>Privacy Policy</Link>, <Link to='/terms-of-use' className='hover:underline hover:text-[var(--notionblue)]'>Terms of Use</Link></h2>
                </div>
            </div>
        </div>
        <div className='mt-8 md:mt-12 flex justify-center'>
            <img src={OutlineLogo} alt="" />
        </div>
    </div>
    </div>
    </>
  )
}

export default Footer