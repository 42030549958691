import React from 'react';
import Footer from '../Footer';
import Meta from '../Meta';
import Navbar from '../Navbar';
import Paging from '../Paging';
import GetInTouchBtn from '../GetInTouchBtn';

const Abad = () => {

  return (
    <div>
      <div>
        <Meta
          title="Notionmind® - ABAD Asset Management Platform"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

      </div>
      {/* header */}
      <div><Navbar /></div>
      {/* header ends */}

      <div className='my-12'>
        <h4 className='text-[var(--blacktext)] text-center m-auto tracking-wider'>CASE STUDY</h4>
        <h1 className='sm:w-[58%] xl:w-[70%] text-3xl md:text-4xl xl:text-6xl pt-4 lg:pt-6 pb-8 px-2 sm:px-0 text-center m-auto tracking-[-0.06em] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] via-[#103FB8] to-[#2B3135] to-[60%] bg-clip-text text-transparent'>ABAD Asset Management Platform</h1>
      </div>

      <div className='mx-2 lg:mx-4 xl:mx-0 mt-4 mb-4 lg:mb-8'>
        <div className="lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-lg shadow-[#DFDFDF] bg-white rounded-3xl sm:rounded-[50px] m-auto p-2 md:p-8 lg:p-16">
          <div className="p-6 lg:px-8 mb-8 lg:mb-20">
            <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
              {/* Left Section */}
              <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
                <div className='justify-items-start pt-2'>
                  <div className='flex-col gap-2'>
                    <div>
                      <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                      <h4 className='mt-4 text-2xl'>Jammin Technology</h4>
                    </div>
                    <div className='my-8'>
                      <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                      <h4 className='mt-4 text-2xl'>Facility Management & Asset Tracking</h4>
                    </div>
                    <div>
                      <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Project</button>
                      <h4 className='mt-4 text-2xl'>Mobile & Web-based Asset Management Solution</h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* Right Section */}
              <div className="space-y-8 place-content-center">
                {/* Problem */}
                <div>
                  <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
                  <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug">
                    Jammin Technology, a facility management and asset tracking company based in the United States, faced significant challenges in maintaining assets across multiple locations. Inefficiencies in issue reporting, asset tracking, and maintenance planning caused delays and increased operational costs.
                    To address these pain points, the ABAD Asset Management Platform was designed as a mobile and web-based solution, offering seamless asset tracking, maintenance planning, and issue resolution capabilities.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
            <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>                    
                    <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>The Challenge</h2>
                </div>
                <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                Facilities managers faced multiple challenges in maintaining assets across various locations.
                </p>

                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Inefficient Issue Reporting:</p>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Reporting delays led to slow response times and unresolved issues.</p>

                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Asset Tracking Difficulties:</p>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Limited visibility into asset locations and statuses.</p>

                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Lack of Centralized Documentation:</p>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Important records scattered across disparate systems.</p>

                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Maintenance Scheduling Gaps:</p>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Reactive maintenance increased downtime and costs.</p>
                
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Poor Asset Lifecycle Visibility:</p>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Lack of data for informed decision-making.</p>

                </div>
            </div>
          </div>

          <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
            <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>                    
                    <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>Our Solution</h2>
                </div>
                <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                Empower your projects with innovative solutions and adaptable collaboration, designed to drive growth and deliver measurable outcomes.
                </p>

                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>QR Code Management System:</p>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Enabled quick asset identification and issue reporting through custom QR codes.</p>

                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Geolocation Tracking:</p>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Delivered real-time asset monitoring and historical tracking.</p>

                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Rapid Reporting System:</p>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Reduced issue reporting time to 20 seconds with intuitive tools and no app requirement.</p>

                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Preventative Maintenance Module:</p>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Facilitated proactive maintenance scheduling and warranty management.</p>

                </div>
            </div>
          </div>

          <div className='mt-8 lg:mt-16 2xl:mt-20 mb-8'>
            <div className='place-items-center flex flex-col justify-center items-center'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Technical Implementation</h1>
              <h5 className='text-center flex justify-center lg:font-bold xl:w-9/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>Our long-term partnerships cut costs, speed up delivery, and drive innovation—keeping your business ready for the future..</h5>
            </div>
          </div>
          
          <div className='md:mb-16 xl:mb-20'>
            <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                    <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Mobile App</h2>                    
                </div>
                <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                    <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                    Cross-platform functionality with GPS and offline access.
                    </h5>
                </div>
            </div>
            <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                    <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Web Dashboard</h2>                    
                </div>
                <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                    <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                    Provided analytics, document management, and user administration tools.
                    </h5>
                </div>
            </div>
            <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                    <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Cloud-based Backend</h2>                    
                </div>
                <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                    <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                    Secure, scalable architecture with real-time processing and API integrations.
                    </h5>
                </div>
            </div>
          </div>

          <div className='my-12 lg:mb-8 xl:mb-12'>
            <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-4 pt-8 pb-2 lg:py-12 md:px-16'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Results & Benefits</h1>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Operational Improvements</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li> 75% faster report submission  </li>
                  <li> 40% quicker issue resolution </li>
                  <li> 30% cost reduction in maintenance</li>
                  <li> 50% enhancement in tracking accuracy</li>
                </ul>
              </div>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Business Impact</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li> Improved resource utilization and reduced downtime  </li>
                  <li> Enhanced brand consistency and customer satisfaction </li>
                </ul>
              </div>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl px-2 font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">ROI Metrics</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li> Increased asset longevity  </li>
                  <li> Reduced emergency repair costs </li>
                  <li> Enhanced workforce productivity </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-20'>
            <div className='place-items-center flex flex-col justify-center items-center'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
              <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>The ABAD Asset Management Platform successfully transformed Jammin Technology’s facility management process, achieving significant operational efficiencies, cost savings, and customer satisfaction. This case study highlights how technology-driven solutions can modernize asset management for long-term business success.</h5>
            </div>
          </div>
          
        </div>
      </div>

      {/* Next Previous CS section */}
      <Paging
        prevLink="/case-studies/conrex"
        prevTitle="Conrex"
        nextLink="/case-studies/verisurg"
        nextTitle="Verisurg" />
      {/* Next Previous CS section ends*/}

      {/* Get In Touch Button */}
      <GetInTouchBtn />
      {/* Get In Touch Button ends */}

      {/* Footer */}
      <div><Footer /></div>
      {/* Footer ends */}
      {/* main div ends  */}
    </div>
  )
}

export default Abad