import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Meta from '../Meta'
import Paging from '../Paging';
import GetInTouchBtn from '../GetInTouchBtn';

const Evolv = () => {

  return (
    <div>
      <div>
        <Meta
          title="Notionmind® - Evolv Beacon Mobile App"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

      </div>
      {/* header */}
      <div><Navbar /></div>
      {/* header ends */}

      <div className='my-12'>
        <h4 className='text-[var(--blacktext)] text-center m-auto tracking-wider'>CASE STUDY</h4>
        <h1 className='sm:w-[58%] xl:w-[70%] text-3xl md:text-4xl xl:text-6xl pt-4 lg:pt-6 pb-8 px-2 sm:px-0 text-center m-auto tracking-[-0.06em] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] via-[#103FB8] to-[#2B3135] to-[60%] bg-clip-text text-transparent'>Evolv Beacon Mobile App</h1>
      </div>

      <div className='mx-2 lg:mx-4 xl:mx-0 mt-4 mb-4 lg:mb-8'>
        <div className="lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-lg shadow-[#DFDFDF] bg-white rounded-3xl sm:rounded-[50px] m-auto p-2 md:p-8 lg:p-16">
          <div className="p-6 lg:px-8 mb-8 lg:mb-20">
            <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
              {/* Left Section */}
              <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
                <div className='justify-items-start pt-2'>
                  <div className='flex-col gap-2'>
                    <div>
                      <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                      <h4 className='mt-4 text-2xl'>Evolv Technology</h4>
                    </div>
                    <div className='my-8'>
                      <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                      <h4 className='mt-4 text-2xl'>Event Security & Access Management</h4>
                    </div>
                    <div>
                      <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Project</button>
                      <h4 className='mt-4 text-2xl'>Smart Venue Ticketing and Access Control System</h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* Right Section */}
              <div className="space-y-8 place-content-center">
                {/* Problem */}
                <div>
                  <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Executive Summary</h2>
                  <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug">
                    The Evolv Beacon mobile app was developed to revolutionize how guests enter and navigate venues while providing enhanced security features and personalized experiences. This application seamlessly integrates mobile ticketing, venue navigation, security verification, and location-based services to create a comprehensive guest management solution.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
            <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>
                    <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>The Challenge</h2>
                </div>
                <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                Venues faced multiple challenges in managing guest entry and experience:
                </p>
                <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
                  <li>Complex entry processes requiring multiple verification steps</li>
                  <li>Difficulty in managing large crowds efficiently</li>
                  <li>Limited ability to provide personalized guest experiences</li>
                  <li>Need for enhanced security measures without compromising convenience</li>
                  <li>Lack of integration between ticketing and security systems</li>
                </ul>
                </div>
            </div>
          </div>

          <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
            <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>
                    <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>Solution</h2>
                </div>
                <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                  The Evolv Beacon app was designed as a multi-stage solution that guides guests from approach to entry while providing venue staff with critical information:
                </p>

                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Stage 1: Venue Approach:</p>
                <ul className="mt-2 lg:mt-4 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
                  <li>Location-based triggers for nearest VIP gate guidance</li>
                  <li>Pre-loaded ticket configuration and information</li>
                  <li>Intuitive interface showing entry type, row, and seat information</li>
                  <li>Quick access to parking directions and VIP access features</li>
                </ul>
                
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Stage 2: Venue Entry:</p>
                <ul className="mt-2 lg:mt-4 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
                  <li>Intelligent gate routing based on seat location or queue length</li>
                  <li>Step-by-step navigation instructions with both text and map views</li>
                  <li>Integration with venue mapping systems for real-time guidance</li>
                  <li>Option to select preferred entry point based on wait times</li>
                </ul>

                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Stage 3: Security Integration:</p>
                <ul className="mt-2 lg:mt-4 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
                  <li>Automatic user verification and priority access alerts</li>
                  <li>Integration with the Evolv Portal for security staff notifications</li>
                  <li>Real-time ticket authentication and validation</li>
                  <li>Visual status indicators (green for verified, red for issues)</li>
                  <li>Accessibility accommodations clearly displayed</li>
                </ul>

                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Additional Features:</p>
                <ul className="mt-2 lg:mt-4 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
                  <li>Location-based retail promotions and discounts</li>
                  <li>VIP area access management</li>
                  <li>Digital ticket display with clear group size indicators</li>
                  <li>Integration with venue security systems</li>
                  <li>Support for multiple entry types and special access requirements</li>
                </ul>
                </div>
            </div>
          </div>

          <div className='my-12 lg:mb-8 xl:mb-12'>
            <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-4 pt-8 pb-2 lg:py-12 md:px-16'>
              <h1 className='text-center text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Key Benefits</h1>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">For Venues</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li>Streamlined entry process reducing bottlenecks</li>
                  <li>Enhanced security through integrated verification</li>
                  <li>Improved guest flow management</li>
                  <li>Better data collection and analytics</li>
                  <li>Reduced staff requirements for routine tasks</li>
                </ul>
              </div>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">For Guests</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li>Simplified venue entry experience</li>
                  <li>Real-time navigation assistance</li>
                  <li>Access to exclusive offers and promotions</li>
                  <li>Clear communication of important information</li>
                  <li>Reduced wait times through optimized routing</li>
                </ul>
              </div>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">For Security Staff</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li>Automated verification processes</li>
                  <li>Real-time guest information access</li>
                  <li>Priority access management</li>
                  <li>Integration with existing security protocols</li>
                  <li>Enhanced communication capabilities</li>
                </ul>
              </div>
            </div>
          </div>


          <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-20'>
            <div className='place-items-center flex flex-col justify-center items-center'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
              <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>The Evolv Beacon app represents a significant advancement in venue management technology, successfully balancing security requirements with guest experience. Its comprehensive approach to entry management, combined with value-added features for guests, provides a blueprint for modern venue operations.</h5>
            </div>
          </div>

        </div>
      </div>

      {/* Next Previous CS section */}
      <Paging
        prevLink="/case-studies/verisurg"
        prevTitle="Verisurg"
        nextLink="/case-studies/city-of-willoughby"
        nextTitle="City of Willoughby" />
      {/* Next Previous CS section ends*/}

      {/* Get In Touch Button */}
      <GetInTouchBtn />
      {/* Get In Touch Button ends */}


      {/* Footer */}
      <div><Footer /></div>
      {/* Footer ends */}
      {/* main div ends  */}
    </div>
  )
}

export default Evolv