import React from 'react';
import Footer from '../Footer';
import Meta from '../Meta';
import Navbar from '../Navbar';
import Paging from '../Paging';
import GetInTouchBtn from '../GetInTouchBtn';

const GrangerDelivery = () => {

  return (
    <div>
      <div>
        <Meta
          title="Notionmind® - Transforming Local Delivery Operations: The Granger Delivery Digital Journey"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

      </div>
      {/* header */}
      <div><Navbar /></div>
      {/* header ends */}

      <div className='my-12'>
        <h4 className='text-[var(--blacktext)] text-center m-auto tracking-wider'>CASE STUDY</h4>
        <h1 className='sm:w-[58%] xl:w-[70%] text-3xl md:text-4xl xl:text-6xl pt-4 lg:pt-6 pb-8 px-2 sm:px-0 text-center m-auto tracking-[-0.06em] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] via-[#103FB8] to-[#2B3135] to-[60%] bg-clip-text text-transparent'>Transforming Local Delivery Operations: The Granger Delivery Digital Journey</h1>
      </div>

      <div className='mx-2 lg:mx-4 xl:mx-0 mt-4 mb-4 lg:mb-8'>
        <div className="lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-lg shadow-[#DFDFDF] bg-white rounded-3xl sm:rounded-[50px] m-auto p-2 md:p-8 lg:p-16">
          <div className="p-6 lg:px-8 mb-8 lg:mb-20">
            <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 tracking-tighter">
              {/* Left Section */}
              <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
                <div className='justify-items-start pt-2'>
                  <div className='flex-col gap-2'>
                    <div>
                      <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                      <h4 className='mt-4 text-2xl'>Granger LLC</h4>
                    </div>
                    <div className='my-8'>
                      <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                      <h4 className='mt-4 text-2xl'>Supply Chain and E-Commerce</h4>
                    </div>
                    <div>
                      <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Project</button>
                      <h4 className='mt-4 text-2xl'>Web-based Delivery & Order Management Platform</h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* Right Section */}
              <div className="space-y-8 place-content-center">
                {/* Problem */}
                <div>
                  <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
                  <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug">
                  Granger Delivery, a local delivery service provider, sought to modernize their customer engagement and delivery management processes. Through strategic digital transformation, we helped them transition from manual operations to an automated, customer-centric delivery platform that streamlined their business operations while maintaining their personalized service approach.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
            <div className='grid lg:grid-cols-5 gap-4 mt-4 xl:mx-8'>
                <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>
                    <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>Initial Situation</h2>
                </div>
                <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-6 sm:px-8'>
                <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold'>
                Granger Delivery faced several operational challenges:
                </p>
                <ul className="leading-tight tracking-tighter text-[var(--blacktext)] mt-3 lg:mt-6 text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
                  <li>Manual order taking process</li>
                  <li>Inconsistent pricing calculations</li>
                  <li>Limited customer communication channels</li>
                  <li>No centralized order tracking</li>
                  <li>Time-consuming quote generation</li>
                  <li>Inefficient order management</li>
                  <li>Missing performance analytics</li>
                  <li>No historical data tracking</li>
                </ul>
                </div>
            </div>
          </div>

          <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
            <div className='grid lg:grid-cols-5 gap-4 mt-4 xl:mx-8'>
                <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>
                    <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)] lg:w-11/12'>Strategic Objectives</h2>
                </div>
                <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-6 sm:px-8'>
                  <ul className="leading-tight tracking-tighter text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
                    <li>Streamline order intake process</li>
                    <li>Standardize pricing structure</li>
                    <li>Improve customer communication</li>
                    <li>Enable real-time order tracking</li>
                    <li>Maintain personal service touch</li>
                    <li>Reduce operational overhead</li>
                    <li>Create data-driven insights</li>
                    <li>Scale business operations</li>
                  </ul>
                </div>
            </div>
          </div>

          <div className='my-4 lg:my-12 md:mt-16 lg:mt-20'>
            <div className='place-items-center flex flex-col justify-center items-center'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Solution Approach</h1>
            </div>
          </div>

          <div className='m-auto tracking-tighter'>
            <div className="grid gap-4 2xl:gap-6 grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 px-4 sm:px-0 mt-6">
              <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                Customer Experience Enhancement</p>
                <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                  <li>Simple ordering process</li>
                  <li>Transparent pricing</li>
                  <li>Immediate confirmation</li>
                  <li>Seamless communication</li>
                </ul>
              </div>

              <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                Operational Efficiency</p>
                <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                  <li>Automated price calculation</li>
                  <li>Standardized data collection</li>
                  <li>Centralized order management</li>
                  <li>Performance tracking</li>
                </ul>
              </div>

              <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                Business Intelligence</p>
                <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                  <li>Order analytics</li>
                  <li>Customer insights</li>
                  <li>Performance metrics</li>
                  <li>Trend analysis</li>
                </ul>
              </div>
              <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                Customer Satisfaction</p>
                <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                  <li>Easy-to-use interface</li>
                  <li>Clear pricing structure</li>
                  <li>Quick response times</li>
                  <li>Multiple communication channels</li>
                </ul>
              </div>
              <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                Operational Excellence</p>
                <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                  <li>Automated workflows</li>
                  <li>Standardized processes</li>
                  <li>Error reduction</li>
                  <li>Time savings</li>
                </ul>
              </div>

              <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                Business Growth</p>
                <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                  <li>Scalable operations</li>
                  <li>Data-driven decisions</li>
                  <li>Market insights</li>
                  <li>Resource optimization</li>
                </ul>
              </div>

            </div>
          </div>

          <div className='mt-8 lg:mt-16 2xl:mt-20 mb-8'>
            <div className='place-items-center flex flex-col justify-center items-center'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Technical Implementation</h1>
              <h5 className='text-center flex justify-center lg:font-bold xl:w-9/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>Our long-term partnerships cut costs, speed up delivery, and drive innovation—keeping your business ready for the future..</h5>
            </div>
          </div>

          <div className='md:mb-16 xl:mb-20 tracking-tighter'>
            <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                    <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Mobile App</h2>
                </div>
                <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                    <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                    Cross-platform functionality with GPS and offline access.
                    </h5>
                </div>
            </div>
            <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                    <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Web Dashboard</h2>
                </div>
                <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                    <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                    Provided analytics, document management, and user administration tools.
                    </h5>
                </div>
            </div>
            <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                    <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Cloud-based Backend</h2>
                </div>
                <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                    <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                    Secure, scalable architecture with real-time processing and API integrations.
                    </h5>
                </div>
            </div>
          </div>

          <div className='my-12 lg:mb-8 xl:mb-12 tracking-tighter'>
            <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-6 pt-8 pb-2 lg:py-12 md:px-16'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Results & Benefits</h1>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Operational Improvements</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li> 75% faster report submission  </li>
                  <li> 40% quicker issue resolution </li>
                  <li> 30% cost reduction in maintenance</li>
                  <li> 50% enhancement in tracking accuracy</li>
                </ul>
              </div>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Business Impact</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li> Improved resource utilization and reduced downtime  </li>
                  <li> Enhanced brand consistency and customer satisfaction </li>
                </ul>
              </div>
              <div className='my-12'>
                <h2 className="text-xl sm:text-2xl px-2 font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">ROI Metrics</h2>
                <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li> Increased asset longevity  </li>
                  <li> Reduced emergency repair costs </li>
                  <li> Enhanced workforce productivity </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-10'>
            <div className='place-items-center flex flex-col justify-center items-center'>
              <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
              <h5 className='text-center tracking-tighter lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>The Granger Delivery transformation demonstrates how thoughtful digitization can enhance rather than replace the personal service that makes small businesses special. By focusing on customer experience and operational efficiency, we delivered a solution that positions Granger Delivery for sustainable growth while maintaining their unique value proposition.</h5>
            </div>
          </div>

        </div>
      </div>

      {/* Next Previous CS section */}
      <Paging
        prevLink="/case-studies/antenna-portal"
        prevTitle="Antenna Portal"
        nextLink="/case-studies/property-analytics-platform"
        nextTitle="Property Analytics" />
      {/* Next Previous CS section ends*/}

      {/* Get In Touch Button */}
      <GetInTouchBtn />
      {/* Get In Touch Button ends */}

      {/* Footer */}
      <div><Footer /></div>
      {/* Footer ends */}
      {/* main div ends  */}
    </div>
  )
}

export default GrangerDelivery