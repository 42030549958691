import React from 'react';
import Footer from '../Footer';
import Meta from '../Meta';
import Navbar from '../Navbar';
import Paging from '../Paging';
import GetInTouchBtn from '../GetInTouchBtn';

const IncidentManagement = () => {
    return (
        <div>
          <div>
            <Meta
              title="Notionmind® - Digital Transformation for Community Impact: Expanding Reach Through Mobile Innovation"
              description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
              keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
            />

          </div>
          {/* header */}
          <div><Navbar /></div>
          {/* header ends */}

          <div className='my-12'>
            <h4 className='text-[var(--blacktext)] text-center m-auto tracking-wider'>CASE STUDY</h4>
            <h1 className='sm:w-[64%] xl:w-[70%] text-3xl md:text-4xl xl:text-6xl pt-4 lg:pt-6 pb-8 px-2 sm:px-0 text-center m-auto tracking-[-0.06em] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] via-[#103FB8] to-[#2B3135] to-[60%] bg-clip-text text-transparent'>
            Transforming Urban Infrastructure Management: A Digital Field Service Evolution</h1>
          </div>

          <div className='mx-2 lg:mx-4 xl:mx-0 mt-4 mb-4 lg:mb-8'>
            <div className="lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-lg shadow-[#DFDFDF] bg-white rounded-3xl sm:rounded-[50px] m-auto p-2 md:p-8 lg:p-16">
              <div className="p-6 lg:px-8 mb-8 lg:mb-20">
                <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 tracking-tighter">
                  {/* Left Section */}
                  <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
                    <div className='justify-items-start pt-2'>
                      <div className='flex-col gap-2'>
                        <div>
                          <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                          <h4 className='mt-4 text-2xl'>Citelum India Pvt. Ltd</h4>
                        </div>
                        <div className='my-8'>
                          <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                          <h4 className='mt-4 text-2xl'>Energy Services and Smart City</h4>
                        </div>
                        <div>
                          <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Project</button>
                          <h4 className='mt-4 text-2xl'>Mobile Application and IoT Cloud Integration</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Right Section */}
                  <div className="space-y-8 place-content-center">
                    {/* Problem */}
                    <div>
                      <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
                      <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug">
                      A major urban infrastructure provider needed to modernize their street lighting incident management system. This case study examines how we transformed their manual, paper-based processes into a streamlined digital solution that empowered field technicians and improved service delivery.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                  <div className='grid lg:grid-cols-6 gap-4 mt-4 xl:mx-8'>
                      <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2 2xl:px-4'>
                          <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>The Challenge</h2>
                      </div>
                      <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-6 sm:px-8 lg:px-2 2xl:px-4 tracking-tighter'>
                      <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                      The client struggled with:
                      </p>
                      <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] mt-3 px-2 list-outside space-y-2 text-left list-disc">
                        <li>Paper-based incident reporting</li>
                        <li>Delayed response times</li>
                        <li>Poor incident tracking</li>
                        <li>Limited visibility into field operations</li>
                        <li>Manual workflow management</li>
                        <li>Inefficient resource allocation</li>
                        <li>Communication gaps</li>
                        <li>Missing documentation</li>
                      </ul>

                      <p className='text-[var(--blacktext)] leading-tight text-base mt-8 md:text-[24px]'>
                      Strategic Objectives
                      </p>
                      <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] mt-3 px-2 list-outside space-y-2 text-left list-disc">
                        <li>Digitize field operations</li>
                        <li>Improve response times</li>
                        <li>Enable real-time tracking</li>
                        <li>Enhance documentation</li>
                        <li>Streamline communications</li>
                        <li>Optimize resource allocation</li>
                        <li>Create accountability</li>
                        <li>Build historical data</li>
                      </ul>
                      </div>
                  </div>
              </div>

              <div className='my-4 lg:my-12 md:mt-16 lg:mt-20'>
              <div className='place-items-center flex flex-col justify-center items-center'>
                <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Solution Design</h1>
              </div>
              </div>

              <div className='m-auto tracking-tighter'>
                <div className="grid gap-4 2xl:gap-6 grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 px-4 sm:px-0 mt-6">
                  <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                    <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                    Mobile-First Operations</p>

                    <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                      <li>Digital incident reporting</li>
                      <li>Real-time status updates</li>
                      <li>Photo documentation</li>
                      <li>Location tracking</li>
                    </ul>
                  </div>

                  <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                    <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                    Smart Workflow Management</p>

                    <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                      <li>Automated ticket routing</li>
                      <li>Zone-based assignment</li>
                      <li>Status tracking</li>
                      <li>Work prioritization</li>
                    </ul>
                  </div>

                  <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                    <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                    Integrated Communications</p>

                    <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                      <li>Real-time notifications</li>
                      <li>Team collaboration</li>
                      <li>Status updates</li>
                      <li>Field documentation</li>
                    </ul>
                  </div>
                  <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                    <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                    Incident Management</p>

                    <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                      <li>Structured data collection</li>
                      <li>Automated routing</li>
                      <li>Status tracking</li>
                      <li>Resolution documentation</li>
                    </ul>
                  </div>
                  <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                    <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                    Resource Optimization</p>

                    <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                      <li>Geographic assignment</li>
                      <li>Skill-based routing</li>
                      <li>Workload balancing</li>
                      <li>Performance tracking</li>
                    </ul>
                  </div>

                  <div className="rounded-xl sm:rounded-[30px] p-6 lg:p-8 flex flex-col bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1]">

                    <p className="pb-1 mt-4 mb-6 text-[28px] md:text-[28px] font-bold md:w-[80%] lg:w-8/12 text-[var(--blacktext)] leading-none">
                    Quality Control</p>

                    <ul className="leading-tight text-[var(--blacktext)] text-sm lg:text-xl mt-3 px-4 list-outside space-y-2 text-left list-disc">
                      <li>Photo documentation</li>
                      <li>Timestamp verification</li>
                      <li>Location validation</li>
                      <li>Work history tracking</li>
                    </ul>
                  </div>

                </div>
              </div>

              <div className='mt-8 lg:mt-16 2xl:mt-20 md:mb-10'>
                  <div className='place-items-center flex flex-col justify-center items-center'>
                  <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Implementation Strategy</h1>
                  </div>
              </div>
              <div className='md:mb-16 xl:mb-20 tracking-tighter'>
                  <div className='grid sm:grid-cols-6 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                      <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                          <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Phase 1: Field Operations</h2>
                      </div>
                      <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 sm:px-4 lg:px-8'>
                          <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-4 list-outside space-y-2 text-left list-item">
                            <li>Mobile app deployment</li>
                            <li>Digital forms implementation</li>
                            <li>Photo capture integration</li>
                            <li>GPS location tracking</li>
                          </ul>
                      </div>
                  </div>
                  <div className='grid sm:grid-cols-6 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                      <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                          <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Phase 2: Workflow Enhancement</h2>
                      </div>
                      <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 sm:px-4 lg:px-8'>
                          <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-4 list-outside space-y-2 text-left list-item">
                            <li>Automated routing</li>
                            <li>Status management</li>
                            <li>Team collaboration</li>
                            <li>Performance monitoring</li>
                          </ul>
                      </div>
                  </div>
                  <div className='grid sm:grid-cols-6 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                      <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                          <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Phase 3: Integration</h2>
                      </div>
                      <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 sm:px-4 lg:px-8'>
                          <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-4 list-outside space-y-2 text-left list-item">
                            <li>Systems connection</li>
                            <li>Data synchronization</li>
                            <li>Reporting implementation</li>
                            <li>Analytics setup</li>
                          </ul>
                      </div>
                  </div>

              </div>

              <div className='mt-8 lg:mt-16 2xl:mt-20 md:mb-10'>
                  <div className='place-items-center flex flex-col justify-center items-center'>
                  <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Business Impact</h1>
                  </div>
              </div>
              <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                <div className='grid lg:grid-cols-6 gap-4 mt-4 xl:mx-8 tracking-tighter'>
                    <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 xl:px-2'>
                        <h2 className='text-3xl lg:text-4xl xl:text-5xl tracking-tight text-[var(--blacktext)] lg:w-8/12'>Quantitative Benefits</h2>
                    </div>
                    <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                    <ul className="leading-tight text-[var(--blacktext)] text-base md:text-[24px] sm:px-4 list-outside space-y-2 text-left list-item">
                      <li>60% faster incident reporting</li>
                      <li>45% improved response times</li>
                      <li>80% better documentation compliance</li>
                      <li>100% location accuracy</li>
                    </ul>
                    </div>
                </div>
              </div>

              <div className='my-12 lg:mb-8 xl:mb-12 tracking-tighter'>
                <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-6 pt-8 pb-2 lg:py-12 md:px-16'>
                  <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Key Success Factors</h1>
                  <div className='my-12'>
                    <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">User Adoption</h2>
                    <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                        <li>Simple interface</li>
                        <li>Clear workflows</li>
                        <li>Easy documentation</li>
                        <li>Minimal training</li>
                    </ul>
                  </div>
                  <div className='my-12'>
                    <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Process Excellence</h2>
                    <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                        <li>Standardized procedures</li>
                        <li>Clear responsibilities</li>
                        <li>Quality controls</li>
                        <li>Performance tracking</li>
                    </ul>
                  </div>
                  <div className='my-12'>
                    <h2 className="text-xl sm:text-2xl px-2 font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Integration Success</h2>
                    <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                        <li>Seamless connectivity</li>
                        <li>Real-time updates</li>
                        <li>Reliable operation</li>
                        <li>Data accuracy</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-10'>
                <div className='place-items-center flex flex-col justify-center items-center'>
                  <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
                  <h5 className='text-center tracking-tighter lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>The incident management transformation demonstrates how thoughtful digitization can revolutionize field operations while improving service delivery. By focusing on user needs and operational efficiency, we delivered a solution that enhances both field operations and management oversight.</h5>
                </div>
              </div>

            </div>
          </div>

          {/* Next Previous CS section */}
          <Paging
            prevLink="/case-studies/cran-planning"
            prevTitle="CRAN Planning"
            nextLink="/case-studies"
            nextTitle="NEXT" />
          {/* Next Previous CS section ends*/}

          {/* Get In Touch Button */}
          <GetInTouchBtn />
          {/* Get In Touch Button ends */}

          {/* Footer */}
          <div><Footer /></div>
          {/* Footer ends */}
          {/* main div ends  */}
        </div>
      )
}

export default IncidentManagement