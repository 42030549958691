import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Meta from '../Meta';
import Paging from '../Paging';
import GetInTouchBtn from '../GetInTouchBtn';

const DailyMentalHealthCheck = () => {

  return (
    <div>
      <div>
      <Meta
          title="Notionmind® - Daily Mental Health Check-in Platform"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />
      </div>
      {/* header */}
      <div><Navbar/></div>
      {/* header ends */}

      <div className='my-12'>
            <h4 className='text-[var(--blacktext)] text-center m-auto tracking-wider'>CASE STUDY</h4>
            <h1 className='lg:w-[58%] xl:w-[70%] text-3xl md:text-4xl xl:text-6xl pt-4 lg:pt-6 pb-8 px-6 sm:px-0 text-center m-auto tracking-[-0.06em] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] via-[#103FB8] to-[#2B3135] to-[60%] bg-clip-text text-transparent'>Daily Mental Health Check-in Platform</h1>
        </div>

        <div className='mx-2 lg:mx-4 xl:mx-0 mt-4 mb-4 lg:mb-8'>
            <div className="lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-lg shadow-[#DFDFDF] bg-white rounded-3xl sm:rounded-[50px] m-auto p-2 md:p-8 lg:p-16">
                <div className="p-6 lg:px-8 mb-8 lg:mb-20">
                    <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {/* Left Section */}
                        <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
                            <div className='justify-items-start pt-2'>
                                <div className='flex-col gap-2'>
                                <div>
                                    <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                                    <h4 className='mt-4 text-2xl'>hChoices, LLC</h4>
                                </div>
                                <div className='my-8'>
                                    <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                                    <h4 className='mt-4 text-2xl'>Education/Mental Health Services</h4>
                                </div>
                                <div>
                                    <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Project</button>
                                    <h4 className='mt-4 text-2xl'>Mobile & Web-based Student Mental Health Monitoring Solution</h4>
                                </div>
                                </div>
                            </div>
                        </div>
                        {/* Right Section */}
                        <div className="space-y-8 place-content-center">
                        {/* Problem */}
                        <div>
                            <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
                            <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug">
                            All Choices Matter, partnering with Christopher Milo, developed a comprehensive mental health monitoring solution based in Ohio for educational institutions. The Daily Mental Health Check-in Platform was designed as a mobile and web-based solution featuring student mood tracking, teacher reporting, and proactive intervention capabilities.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>

                <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                    <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                        <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>                    
                            <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>The Challenge</h2>
                        </div>
                        <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                        Facilities managers faced multiple challenges in monitoring student mental health across various locations:                        
                        </p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Inefficient Issue Reporting:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Limited ability to identify struggling students</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Reporting delays leading to delayed interventions</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Unaddressed mental health issues</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Mental Health Tracking Difficulties:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Limited visibility into student emotional states</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Students experiencing widespread distress</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Increasing isolation and stress</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Lack of Centralized Documentation:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Important records scattered across separate systems</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>No unified view of student well-being</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Limited trend analysis capabilities</p>
                        
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Monitoring & Scheduling Gaps:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Reactive rather than proactive approach</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Increased intervention response time</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Limited preventive support</p>
                        
                        </div>
                    </div>
                </div>

                <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                    <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                        <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>                    
                            <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>Our Solution</h2>
                        </div>
                        <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                        Empower educational institutions with innovative solutions and adaptive collaboration, designed to drive student support and deliver improved outcomes.                       
                        </p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Quick Check-in System:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Enabled quick mood identification and issue reporting through visual emotion scale</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Simple 1-10 rating system</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Daily monitoring capabilities</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Real-time Monitoring:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Delivered real-time mood monitoring</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Historical tracking</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Trend analysis</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Rapid Response System:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Reduced issue reporting time to 20 seconds</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Immediate alerts for concerning patterns</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Quick intervention enablement</p>
                        
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Preventative Support Module:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Facilitated proactive intervention scheduling</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Resource management</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Support system integration</p>
                        
                        </div>
                    </div>
                </div>

                <div className='mt-8 lg:mt-16 2xl:mt-20 mb-8'>
                    <div className='place-items-center flex flex-col justify-center items-center'>
                    <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Technical Implementation</h1>
                    <h5 className='text-center flex justify-center lg:font-bold xl:w-9/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>Our deep understanding of educational needs, focus on delivery, and drive innovation - keeping institutions ready for the future.</h5>
                    </div>
                </div>
                <div className='md:mb-16 xl:mb-20'>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Mobile App</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            <ul className="leading-none tracking-tighter text-[var(--blacktext)] px-4 text-base md:text-[20px] lg:text-[24px] list-outside space-y-2 text-left list-item">
                            <li>Cross-platform functionality</li>
                            <li>Online and offline capabilities</li>
                            <li>Simple user interface</li>
                            </ul>
                            </h5>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Web Dashboard</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            <ul className="leading-none tracking-tighter text-[var(--blacktext)] px-4 text-base md:text-[20px] lg:text-[24px] list-outside space-y-2 text-left list-item">
                            <li>Provided analytics</li>
                            <li>Document management</li>
                            <li>User administration tools</li>
                            </ul>
                            </h5>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Cloud-Based Backend</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            <ul className="leading-none tracking-tighter text-[var(--blacktext)] px-4 text-base md:text-[20px] lg:text-[24px] list-outside space-y-2 text-left list-item">
                            <li>Secure, scalable architecture</li>
                            <li>Real-time processing</li>
                            <li>API integrations</li>
                            </ul>
                            </h5>
                        </div>
                    </div>
                </div>

                <div className='my-12 lg:mb-8 xl:mb-12'>
                    <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-4 pt-8 pb-2 lg:py-12 md:px-16'>
                    <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Results & Benefits</h1>
                    <div className='my-12'>
                        <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Operational Improvements</h2>
                        <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                            <li> 75% faster mood monitoring </li>
                            <li> 40% quicker issue resolution</li>
                            <li> 30% reduction in response time</li>
                            <li> 50% enhancement in tracking accuracy</li>
                        </ul>
                    </div>
                    <div className='my-12'>
                        <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Business Impact</h2>
                        <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                            <li> Improved resource utilization</li>
                            <li> Enhanced student support</li>
                            <li> Reduced intervention delays</li>
                            <li> Better stakeholder satisfaction</li>
                        </ul>
                    </div>
                    <div className='my-12'>
                        <h2 className="text-xl sm:text-2xl px-2 font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">ROI Metrics</h2>
                        <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                            <li> Increased early intervention</li>
                            <li> Reduced crisis situations</li>
                            <li> Enhanced educational productivity</li>
                        </ul>
                    </div>
                    </div>
                </div>
                
                <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-20'>
                    <div className='place-items-center flex flex-col justify-center items-center'>
                    <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
                    <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>The Daily Mental Health Check-in Platform successfully helped educational institutions monitor and support student mental health. Utilizing straightforward digital solutions and effective data analysis, schools achieved better student support, improved intervention timing, and enhanced communication. This case study highlights how technology-driven solutions can modernize mental health support for long-term student success.</h5>
                    </div>
                </div>

            </div>
        </div>

      {/* Next Previous CS section */}
      <Paging
          prevLink="/case-studies/go-to-work-america"
          prevTitle="Go to work America"
          nextLink="/case-studies/evolv"
          nextTitle="Evolv"/>
        {/* Next Previous CS section ends*/}

        {/* Get In Touch Button */}
          <GetInTouchBtn />
        {/* Get In Touch Button ends */}

      {/* Footer */}
      <div><Footer/></div>
      {/* Footer ends */}
      {/* main div ends  */}
    </div>
  )
}

export default DailyMentalHealthCheck