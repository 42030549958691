import React, {useState} from 'react';
import { Link, NavLink } from 'react-router-dom';
import { TbMenu } from "react-icons/tb";
import logo from '../images/Notionmind-logo.svg';
import notionIcon from '../images/NotionIcon.svg';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();

  const [nav, setNav] = useState(false)
  const handleNav = () => {
      setNav(!nav)
    }
  const activeLink = 'bg-white text-[var(--notionblue)] shadow-sm font-semibold rounded-full'
  const normalLink = 'text-[var(--blacktext)] tracking-tight'
  return (
    <div>
        <nav className='px-6 sm:px-12 py-8 xl:py-6 flex items-center justify-between flex-wrap'>
        <div className='float-start'>
        <Link to={"/"}><img src={logo} alt="Notionmind-logo"/></Link>
        </div>
        
        <div className='hidden lg:flex justify-center'>
            <ul className='hidden sm:inline-flex text-xl'>
              <NavLink to='/case-studies' className={({isActive}) =>
                 isActive ? activeLink: normalLink }><li className='px-4 py-2 rounded-full transition ease-in hover:font-semibold hover:text-[var(--notionblue)]'>Case Studies</li></NavLink>
              <NavLink to="/capabilities" className={({isActive}) =>
                 isActive ? activeLink: normalLink }><li className='px-4 py-2 rounded-full transition ease-in hover:font-semibold hover:text-[var(--notionblue)]'>Capabilities</li></NavLink>
              <NavLink to='/partnership' className={({isActive}) =>
                 isActive ? activeLink: normalLink }><li className='px-4 py-2 rounded-full transition ease-in hover:font-semibold hover:text-[var(--notionblue)]'>Partnership</li></NavLink>
              <NavLink to="/enterprise" className={({isActive}) =>
                  isActive ? activeLink: normalLink }><li className='px-4 py-2 rounded-full transition ease-in hover:font-semibold hover:text-[var(--notionblue)]'>Enterprise</li></NavLink>
              <NavLink to='/about' className={({isActive}) =>
                 isActive ? activeLink: normalLink }><li className='px-4 py-2 rounded-full transition ease-in hover:font-semibold hover:text-[var(--notionblue)]'>About</li></NavLink>
            </ul>
        </div>
        <div className='hidden xl:flex'>
        {location.pathname !== '/getintouch' && (
        <NavLink to='/getintouch'><button className="relative button-theme group">
          <span className="relative px-5 py-2.5 btn-span-theme">
          Get in Touch
          </span>
        </button></NavLink>
        )}
        {location.pathname === '/getintouch' && (
        <Link to="https://calendar.app.google/8tnXSPfQKnhBvnMn7"><button className="relative button-theme group">
          <span className="relative px-5 py-2.5 btn-span-theme">
          Book a call
          </span>
        </button></Link>
        )}
        </div>

        <div onClick={handleNav} className='lg:hidden z-10'>
          <TbMenu size={20} color='#103FB8' />
        </div>
        <div
        onClick={handleNav}
        className={nav ? 'overflow-y-hidden lg:hidden ease-in duration-300 absolute text-[var(--notionblue)] left-0 top-0 w-full h-screen bg-[#F7F7F7] px-4 py-8 flex flex-col' : 'absolute top-0 h-screen left-[-100%] ease-in duration-500'}>

          <div className='place-content-center flex'>
          <img src={notionIcon} alt="Notionmind logo"/>
          </div>

          <ul className='h-max w-full text-center pt-12'>
              <Link to='/case-studies'><li className='px-4 text-2xl py-4'>Case Studies</li></Link>
              <Link to='/capabilities'><li className='px-4 text-2xl py-4'>Capabilities</li></Link>
              <Link to='/enterprise'><li className='px-4 text-2xl py-4'>Enterprise</li></Link>
              <Link to='/partnership'><li className='px-4 text-2xl py-4'>Partnership</li></Link>
              <Link to="/about"><li className='px-4 text-2xl py-4'>About</li></Link>
          </ul>
          <div className='place-content-center grid grid-flow-row gap-2 place-items-center my-2'>
            <Link to="https://calendar.app.google/8tnXSPfQKnhBvnMn7"><button className="relative button-theme group">
              <span className="relative px-5 py-2.5 btn-span-theme">
              Book a call
              </span>
            </button></Link>
            <button className="button-theme group">
              <span className="relative px-5 py-2.5 btn-span-theme">
              <NavLink to='/getintouch'>Get in Touch</NavLink>
              </span>
            </button>
          </div>
        </div>
        </nav>
    </div>
  )
}

export default Navbar