import React from 'react';
import { Link } from 'react-router-dom';

const GetInTouchBtn = () => {

  return (
          <div className='lg:max-w-[1200px] 2xl:max-w-[1380px] m-auto'>
            <div className='mx-3 xl:mx-0 mt-8 xl:my-8 p-5 md:p-10 lg:flex justify-between items-center rounded-3xl sm:rounded-[50px] shadow-lg shadow-[#DFDFDF] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#4D565C] from-[0%] via-[#2B3135] to-[#090A0B] to-[60%]'>
                <h2 className='w-full md:w-[100%] lg:w-[75%] 2xl:w-[50%] text-3xl sm:text-5xl tracking-[-0.06em] bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-[#AD7DFF] from-[0%] via-[#2188FF] to-[#79B8FF] to-[60%] bg-clip-text text-transparent py-1'>Ready to make a difference and write your next success story?</h2>
                <div className='pt-4 lg:pt-0'>
                <Link to="/getintouch"><button className="relative button-theme group">
                    <span className="relative px-8 py-2.5 btn-span-theme">
                    Get in Touch
                    </span>
                </button></Link>
                </div>
            </div>
          </div>
    )
}

export default GetInTouchBtn