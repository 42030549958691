import React from 'react';
import Footer from './Footer';
import conrex from '../images/Conrex.webp';
import abad from '../images/abad.webp';
import alcon from '../images/Alcon.webp';
import HobbyIdeasImg from '../images/Hobby.webp';
import EvolvImg from '../images/Evolv.webp';
import COWImg from '../images/cow.webp';
import GoToWork from '../images/GotoWork.webp';
import DailyMentalHealth from '../images/DailyMentalHealth.webp';
import SystemIntegration from '../images/Integration.webp';
import AllChoices from '../images/all-choices-matter.webp';
import Antenna from '../images/antenna-portal.webp';
import Granger from '../images/granger.webp';
import CranPlaning from '../images/cran-planning.webp';
import PropertyAnalytics from '../images/Property-analytics.webp';
import Incident from '../images/incident-management.webp';
import { NavLink } from 'react-router-dom';
import Navbar from './Navbar';
import GetInTouchBtn from './GetInTouchBtn';
import Meta from './Meta';

const CaseStudy = () => {

  return (
    <div>
        <Meta
          title="Notionmind® - Innovative Solutions in Action"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

        {/* header */}
        <div>
          <Navbar/>
        </div>
        {/* header ends */}


         {/* content section */}
        <div className='heroSection max-w-[1380px] flex flex-col justify-center my-8 md:my-16 m-auto'>
                <h1 className='title-theme leading-[1.10em] tracking-[-0.06em] text-4xl sm:text-[64px] lg:text-[86px] font-medium'>Innovative Solutions in Action</h1>
                <div className='flex justify-center m-auto'>
                <p className='place-self-center text-lg lg:mt-8 md:text-3xl text-center tracking-tighter lg:w-8/12 xl:w-7/12 text-[var(--darkgray)] font-medium md:font-normal px-3 md:px-0 py-5'>Explore how we solve real-world challenges.
                Detailed insights into our impactful solutions.</p>
                </div>
        </div>

        <div className='mx-2 lg:mx-4 xl:mx-0 my-4'>
          <div className='lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-lg shadow-[#DFDFDF] rounded-3xl sm:rounded-[50px] xl:m-auto px-6 py-8 md:px-8 md:py-12 bg-white'>

              <div className='grid sm:grid-cols-3 gap-6 lg:gap-12 lg:px-8 mt-2'>
                  <NavLink to='/case-studies/conrex'><div className='group p-5 lg:p-7 rounded-3xl bg-[#FEDBF0] border-b-4 border-[#F9B3DD] transition ease-in delay-100 hover:bg-[#F9B3DD]'>
                      <h3 className='text-xl font-semibold py-1 text-[var(--blacktext)]'>Conrex</h3>
                      <h6 className='pb-4 text-[var(--blacktext)]'>Enterprise, Process Automation</h6>
                      <div className='h-min overflow-hidden rounded-3xl'>
                      <img src={conrex} alt="Conrex" className='group-hover:scale-125 transition-all duration-500' />
                      </div>
                  </div></NavLink>
                  <NavLink to='/case-studies/abad'><div className='group p-5 lg:p-7 rounded-3xl bg-[#E6DCFD] border-b-4 border-[#D1BCF9] transition ease-in delay-100 hover:bg-[#D1BCF9]'>
                      <h3 className='text-xl font-semibold py-1 text-[var(--blacktext)]'>ABAD</h3>
                      <h6 className='pb-4 text-[var(--blacktext)]'>Enterprise, Solutions</h6>
                      <div className='h-min overflow-hidden rounded-3xl'>
                      <img src={abad} alt="ABAD" className='group-hover:scale-125 transition-all duration-500' />
                      </div>
                  </div></NavLink>
                  <NavLink to='/case-studies/verisurg'><div className='group p-5 lg:p-7 rounded-3xl bg-[#FFEBDA] border-b-4 border-[#FFD1AC] transition ease-in delay-100 hover:bg-[#FFD1AC]'>
                      <h3 className='text-xl font-semibold py-1 text-[var(--blacktext)]'>Verisurg</h3>
                      <h6 className='pb-4 text-[var(--blacktext)]'>Enterprise, Digital Transformation</h6>
                      <div className='h-min overflow-hidden rounded-3xl'>
                      <img src={alcon} alt="Verisurg" className='group-hover:scale-125 transition-all duration-500' />
                      </div>
                  </div></NavLink>
              </div>

              <div className='grid sm:grid-cols-3 gap-6 lg:gap-12 lg:px-8 mt-4 lg:mt-8'>
                  <NavLink to='/case-studies/evolv'><div className='group p-5 lg:p-7 rounded-3xl bg-[#DFECFE] border-b-4 border-[#CEE0FE] transition ease-in delay-100 hover:bg-[#CEE0FE]'>
                      <h3 className='text-xl font-semibold py-1 text-[var(--blacktext)]'>Evolv Technology</h3>
                      <h6 className='pb-4 text-[var(--blacktext)]'>Enterprise, IoT, R&D</h6>
                      <div className='h-min overflow-hidden rounded-3xl'>
                      <img src={EvolvImg} alt="Evolv Technology" className='group-hover:scale-125 transition-all duration-500' />
                      </div>
                  </div></NavLink>
                  <NavLink to='/case-studies/city-of-willoughby'><div className='group p-5 lg:p-7 rounded-3xl bg-[#E6FFE4] border-b-4 border-[#CEF5CC] transition ease-in delay-100 hover:bg-[#CEF5CC]'>
                      <h3 className='text-xl font-semibold py-1 text-[var(--blacktext)]'>City of Willoughby</h3>
                      <h6 className='pb-4 text-[var(--blacktext)]'>Smart City, Platform, Solutions</h6>
                      <div className='h-min overflow-hidden rounded-3xl'>
                      <img src={COWImg} alt="City of Willoughby" className='group-hover:scale-125 transition-all duration-500' />
                      </div>
                  </div></NavLink>
                  <NavLink to='/case-studies/hobby-ideas'><div className='group p-5 lg:p-7 rounded-3xl bg-[#BEDCD7] border-b-4 border-[#97C7BE] transition ease-in delay-100 hover:bg-[#97C7BE]'>
                      <h3 className='text-xl font-semibold py-1 text-[var(--blacktext)]'>HobbyIdeas</h3>
                      <h6 className='pb-4 text-[var(--blacktext)]'>Enterprise, Community, Platform</h6>
                      <div className='h-min overflow-hidden rounded-3xl'>
                      <img src={HobbyIdeasImg} alt="HobbyIdeas" className='group-hover:scale-125 transition-all duration-500' />
                      </div>
                  </div></NavLink>
              </div>

              <div className='grid sm:grid-cols-3 gap-6 lg:gap-12 lg:px-8 mt-4 lg:mt-8'>
                  <NavLink to='/case-studies/go-to-work-america'><div className='group p-5 lg:p-7 rounded-3xl bg-[#FEDBF0] border-b-4 border-[#F9B3DD] transition ease-in delay-100 hover:bg-[#F9B3DD]'>
                      <h3 className='text-xl font-semibold py-1 text-[var(--blacktext)]'>Go To Work America</h3>
                      <h6 className='pb-4 text-[var(--blacktext)]'>Enterprise, Platform</h6>
                      <div className='h-min overflow-hidden rounded-3xl'>
                      <img src={GoToWork} alt="Go-to-work-America" className='group-hover:scale-125 transition-all duration-500' />
                      </div>
                  </div></NavLink>
                  <NavLink to='/case-studies/daily-mental-health-check'><div className='group p-5 lg:p-7 rounded-3xl bg-[#E6DCFD] border-b-4 border-[#D1BCF9] transition ease-in delay-100 hover:bg-[#D1BCF9]'>
                      <h3 className='text-xl font-semibold py-1 text-[var(--blacktext)]'>Daily Mental Health Check</h3>
                      <h6 className='pb-4 text-[var(--blacktext)]'>AI, Community, Platform</h6>
                      <div className='h-min overflow-hidden rounded-3xl'>
                      <img src={DailyMentalHealth} alt="Daily-Mental-Health-Check" className='group-hover:scale-125 transition-all duration-500' />
                      </div>
                  </div></NavLink>
                  <NavLink to='/case-studies/integration#CloudStorageAPI'><div className='group p-5 lg:p-7 rounded-3xl bg-[#FFEBDA] border-b-4 border-[#FFD1AC] transition ease-in delay-100 hover:bg-[#FFD1AC]'>
                      <h3 className='text-xl font-semibold py-1 text-[var(--blacktext)]'>System Integration</h3>
                      <h6 className='pb-4 text-[var(--blacktext)]'>Enterprise, Cloud, API, Data</h6>
                      <div className='h-min overflow-hidden rounded-3xl'>
                      <img src={SystemIntegration} alt="HobbyIdeas" className='group-hover:scale-125 transition-all duration-500' />
                      </div>
                  </div></NavLink>
              </div>

              <div className='grid sm:grid-cols-3 gap-6 lg:gap-12 lg:px-8 mt-4 lg:mt-8'>
                  <NavLink to='/case-studies/all-choices-matter'><div className='group p-5 lg:p-7 rounded-3xl bg-[#DFECFE] border-b-4 border-[#CEE0FE] transition ease-in delay-100 hover:bg-[#CEE0FE]'>
                      <h3 className='text-xl font-semibold py-1 text-[var(--blacktext)]'>Community Platform</h3>
                      <h6 className='pb-4 text-[var(--blacktext)]'>Community, Digital Transformation</h6>
                      <div className='h-min overflow-hidden rounded-3xl'>
                      <img src={AllChoices} alt="Evolv Technology" className='group-hover:scale-125 transition-all duration-500' />
                      </div>
                  </div></NavLink>
                  <NavLink to='/case-studies/antenna-portal'><div className='group p-5 lg:p-7 rounded-3xl bg-[#E6FFE4] border-b-4 border-[#CEF5CC] transition ease-in delay-100 hover:bg-[#CEF5CC]'>
                      <h3 className='text-xl font-semibold py-1 text-[var(--blacktext)]'>Antenna Portal</h3>
                      <h6 className='pb-4 text-[var(--blacktext)]'>Telecom, API, BI</h6>
                      <div className='h-min overflow-hidden rounded-3xl'>
                      <img src={Antenna} alt="City of Willoughby" className='group-hover:scale-125 transition-all duration-500' />
                      </div>
                  </div></NavLink>
                  <NavLink to='/case-studies/granger-delivery'><div className='group p-5 lg:p-7 rounded-3xl bg-[#BEDCD7] border-b-4 border-[#97C7BE] transition ease-in delay-100 hover:bg-[#97C7BE]'>
                      <h3 className='text-xl font-semibold py-1 text-[var(--blacktext)]'>Granger Delivery</h3>
                      <h6 className='pb-4 text-[var(--blacktext)]'>Process Automation, Platform</h6>
                      <div className='h-min overflow-hidden rounded-3xl'>
                      <img src={Granger} alt="HobbyIdeas" className='group-hover:scale-125 transition-all duration-500' />
                      </div>
                  </div></NavLink>
              </div>

              <div className='grid sm:grid-cols-3 gap-6 lg:gap-12 lg:px-8 mt-4 lg:mt-8'>
                  <NavLink to='/case-studies/property-analytics-platform'><div className='group p-5 lg:p-7 rounded-3xl bg-[#FEDBF0] border-b-4 border-[#F9B3DD] transition ease-in delay-100 hover:bg-[#F9B3DD]'>
                      <h3 className='text-xl font-semibold py-1 text-[var(--blacktext)]'>Property Analytics</h3>
                      <h6 className='pb-4 text-[var(--blacktext)]'>Enterprise, AI, Analytics, BI</h6>
                      <div className='h-min overflow-hidden rounded-3xl'>
                      <img src={PropertyAnalytics} alt="Go-to-work-America" className='group-hover:scale-125 transition-all duration-500' />
                      </div>
                  </div></NavLink>
                  <NavLink to='/case-studies/cran-planning'><div className='group p-5 lg:p-7 rounded-3xl bg-[#E6DCFD] border-b-4 border-[#D1BCF9] transition ease-in delay-100 hover:bg-[#D1BCF9]'>
                      <h3 className='text-xl font-semibold py-1 text-[var(--blacktext)]'>CRAN Planning</h3>
                      <h6 className='pb-4 text-[var(--blacktext)]'>Telecom, Automation, AI</h6>
                      <div className='h-min overflow-hidden rounded-3xl'>
                      <img src={CranPlaning} alt="Daily-Mental-Health-Check" className='group-hover:scale-125 transition-all duration-500' />
                      </div>
                  </div></NavLink>
                  <NavLink to='/case-studies/incident-management'><div className='group p-5 lg:p-7 rounded-3xl bg-[#FFEBDA] border-b-4 border-[#FFD1AC] transition ease-in delay-100 hover:bg-[#FFD1AC]'>
                      <h3 className='text-xl font-semibold py-1 text-[var(--blacktext)]'>Incident Management</h3>
                      <h6 className='pb-4 text-[var(--blacktext)]'>Smart City, IoT, Enterprise</h6>
                      <div className='h-min overflow-hidden rounded-3xl'>
                      <img src={Incident} alt="HobbyIdeas" className='group-hover:scale-125 transition-all duration-500' />
                      </div>
                  </div></NavLink>
              </div>

          </div>
        </div>
        {/* case studies ends */}

      {/* Get In Touch Button */}
      <GetInTouchBtn />
      {/* Get In Touch Button ends */}

      {/* Footer */}
      <div><Footer/></div>
      {/* Footer ends */}
      {/* main div ends  */}
    </div>
  )
}

export default CaseStudy