import React, { useEffect } from 'react';
import Navbar from '../Navbar';
import GetInTouchBtn from '../GetInTouchBtn';
import Footer from '../Footer';
import Meta from '../Meta';
import Paging from '../Paging';
import { useState } from "react";
import CloudStorage from '../IntegrationTabs/CloudStorage';
import Healthcare from '../IntegrationTabs/HealthCare';
import SecureDocument from '../IntegrationTabs/SecureDocument';
import PropertyData from '../IntegrationTabs/PropertyData';
import CalendarSystem from '../IntegrationTabs/CalendarSystem';

const Integration = () => {
  const [activeTab, setActiveTab] = useState("menu1");

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [activeTab]);

  const tabs = [
    { id: "menu1", label: "Cloud Storage API", tag: "CloudStorageAPI", content: <CloudStorage/> },
    { id: "menu2", label: "Healthcare Data", tag: "HealthcareData", content: <Healthcare/> },
    { id: "menu3", label: "Secure Document Management", tag: "SecureDocumentManagement", content: <SecureDocument/> },
    { id: "menu4", label: "Property Data Analytics", tag: "PropertyDataAnalytics", content: <PropertyData/> },
    { id: "menu5", label: "Calendar Migration", tag: "CalendarMigration", content: <CalendarSystem/> },
  ];

  const handleTabClick = (tabId, tabTag) => {
    setActiveTab(tabId);
    window.location.hash = tabTag; // Updates the URL hash
  };

  return (
    <>
        <Meta
          title="Notionmind® - Innovative Solutions in Action"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

        {/* header */}
        <div>
          <Navbar/>
        </div>
        {/* header ends */}
        <div className='my-8 sm:my-12'>
            <h4 className='text-[var(--blacktext)] text-center m-auto tracking-wider'>INTEGRATION</h4>
            <div>
              {tabs.map((tab) => (
              <div
                className={`px-4 py-2 ${
                    activeTab === tab.id ? "xl:w-[80%] text-3xl md:text-4xl xl:text-6xl pt-4 lg:pt-6 pb-8 px-2 sm:px-0 text-center m-auto tracking-[-0.06em] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] via-[#103FB8] to-[#2B3135] to-[60%] bg-clip-text text-transparent" : "hidden"
                }`}
                onClick={() => handleTabClick(tab.id, tab.tag)}
                >
                {tab.label}
              </div>
              ))}
            </div>
        </div>

         {/* content section */}
        <div className='mx-2 lg:mx-4 xl:mx-0 my-4 xl:my-12'>
          <div className='lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-lg shadow-[#DFDFDF] rounded-3xl sm:rounded-[50px] xl:m-auto px-4 py-8 md:px-8 md:py-12 bg-white'>

          <div className='my-4 sm:mb-12 lg:mb-16 xl:mt-12 xl:mb-16 xl:max-w-[1000px] m-auto'>
            <div className='grid sm:grid-cols-3 gap-2 sm:gap-2 lg:gap-4 place-items-center'>
                {tabs.map((tab) => (
                <h2
                    key={tab.id}
                    className={`sm:mx-1 ${
                    activeTab === tab.id
                        ? "border-2 border-[var(--notionblue)] bg-[var(--notionblue)] w-[240px] sm:w-[220px] lg:w-72 xl:w-80 text-center place-content-center rounded-lg"
                        : "text-center place-content-center"
                    }`}
                >
                    <div
                    className={`px-4 py-2 w-[240px] sm:w-[220px] lg:w-72 xl:w-80 transition-colors ${
                        activeTab === tab.id ? "text-white font-bold" : "text-[var(--blacktext)] bg-white border-2 border-gray-300 rounded-lg hover:bg-gray-100 hover:font-semibold cursor-pointer"
                    }`}
                    onClick={() => handleTabClick(tab.id, tab.tag)}
                    >
                    {tab.label}
                    </div>
                </h2>
                ))}
              </div>
            </div>

            <div className="tab-content">
                {tabs.map((tab) => (
                <div
                    key={tab.id}
                    id={tab.id}
                    className={`tab-pane ${
                    activeTab === tab.id ? "block" : "hidden"
                    }`}
                >
                {tab.content}
                </div>
                ))}
            </div>

            <div className='my-4 sm:mb-12 lg:mb-16 xl:my-16 xl:max-w-[1000px] m-auto'>
              <div className='grid sm:grid-cols-3 gap-2 sm:gap-2 lg:gap-4 place-items-center'>
                  {tabs.map((tab) => (
                  <h2
                      key={tab.id}
                      className={`sm:mx-1 ${
                      activeTab === tab.id
                          ? "border-2 border-[var(--notionblue)] bg-[var(--notionblue)] w-[240px] sm:w-[220px] lg:w-72 xl:w-80 text-center place-content-center rounded-lg"
                          : "text-center place-content-center"
                      }`}
                  >
                      <div
                      className={`px-4 py-2 w-[240px] sm:w-[220px] lg:w-72 xl:w-80 transition-colors ${
                          activeTab === tab.id ? "text-white font-bold" : "text-[var(--blacktext)] bg-white border-2 border-gray-300 rounded-lg hover:bg-gray-100 hover:font-semibold cursor-pointer"
                      }`}
                      onClick={() => handleTabClick(tab.id, tab.tag)}
                      >
                      {tab.label}
                      </div>
                  </h2>
                  ))}
              </div>
            </div>

          </div>
        </div>

        {/* Next Previous CS section */}
        <Paging
          prevLink="/case-studies/daily-mental-health-check"
          prevTitle="Daily Mental Health Check"
          nextLink="/case-studies/all-choices-matter"
          nextTitle="Community Platform"/>
        {/* Next Previous CS section ends*/}

        {/* Get In Touch Button */}
        <GetInTouchBtn />
        {/* Get In Touch Button ends */}

        {/* Footer */}
        <div><Footer/></div>
        {/* Footer ends */}
        {/* main div ends  */}
    </>
  )
}

export default Integration
