import React from 'react';
import logoGroup from '../images/logoGroup.svg';
import Gallery from './Gallery';
import Footer from './Footer';
import Meta from './Meta';
import Navbar from './Navbar';
import GetInTouchBtn from './GetInTouchBtn';

const About = () => {

  return (
    <div>
        <div>
        <Meta
          title="Notionmind® - About"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

        </div>
        {/* header */}
        <Navbar />
        {/* header ends */}

        {/* hero section */}
        <div className='bg-hero bg-no-repeat m-auto place-content-center my-2 bg-left-top'>
          <div className='heroSection max-w-[1380px] flex flex-col justify-center my-8 md:my-16 m-auto'>
              <h1 className='title-theme leading-[1.10em] tracking-[-0.06em] sm:px-12 pb-1 text-4xl sm:text-[64px] lg:text-[86px] font-[700px]'>Innovating for a Digital Future</h1>
              <p className='flex justify-center place-self-center lg:mt-16 text-lg sm:text-3xl text-center sm:w-10/12 lg:w-8/12 text-[var(--darkgray)] font-medium md:font-normal px-3 md:px-0 py-4 tracking-tighter'>AI, Strategy, Technology, Creativity, and Marketing Combined in One Powerful Team</p>
          </div>        
        {/* hero section ends */}

          <div className='mx-3 lg:mx-4 xl:mx-0 my-8 tracking-tighter'>
              <div className='lg:max-w-[1200px] 2xl:max-w-[1380px] m-auto place-items-center justify-items-center bg-white rounded-3xl sm:rounded-[50px] py-12 sm:p-20 shadow-lg shadow-[#DFDFDF]'>

                <div className="flex items-center justify-center">
                  <div className="text-center max-w-[800px] flex flex-col justify-center">
                    <h5 className='text-center px-3 leading-snug text-lg lg:text-2xl text-[var(--darkgray)] place-self-center'>
                      At Notionmind<span className='align-super text-[12px]'>®</span>, there is more to us than just being a technology company,
                      we are your partners in the process of digital transformation. Outstanding since the
                      inception of the company in 2019, we have carved our niche within the technology sector
                        by assisting businesses from different verticals to integrate the core power of
                        AI, data, and advanced software systems into their operational activities for growth and impact.</h5>
                    <h5 className='text-center px-3 leading-snug text-lg md:text-lg lg:text-2xl text-[var(--darkgray)] mt-8 place-self-center'>
                      We serve businesses through providing them with practical, advanced and progressive digital
                      solutions that enhance revenues and make a difference in the world on a constantly changing
                        backdrop of technology.</h5>
                  </div>
                </div>
                  <div className='justify-items-center m-auto'>
                      <img src={logoGroup} alt="" className='py-12 md:py-14 place-self-center m-auto' />
                  </div>

                    <h3 className='text-center px-2 text-3xl md:text-4xl text-[var(--darkgray)] lg:font-semibold place-self-center'>Our company is built on the principles of </h3>
                    <div className='my-10 place-content-center place-self-center'>
                      <h1 className='text-center font-semibold text-3xl md:text-7xl bg-gradient-to-r from-[#7FC8BF] from-[5%] via-[#018473] to-[#006455] bg-clip-text text-transparent md:pb-1'>Transparency</h1>
                      <h1 className='text-center font-semibold text-3xl md:text-7xl bg-gradient-to-r from-[#ffaa6d] from-[0%] via-[#E36209] to-[#EF3A4E] bg-clip-text text-transparent py-2 lg:py-4'>Reliability</h1>
                      <h1 className='text-center font-semibold text-3xl md:text-7xl bg-gradient-to-r from-[#8A63D2] from-[5%] via-[#8A63D2] to-[#D03592] bg-clip-text text-transparent md:pb-1'>Accountability</h1>
                    </div>
                    <div className="flex items-center justify-center">
                      <div className="text-center max-w-[700px] flex flex-col justify-center">
                        <h5 className='text-center px-3 leading-snug text-lg md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-8 place-self-center'>We build a strong, family-driven culture that shapes everything we do. Known for exceptional customer service, we genuinely care about your success and are fully invested in helping your business thrive.</h5>
                      </div>
                    </div>
                    <div className='mt-8 sm:my-12'>
                      <h1 className='text-center text-2xl sm:text-[48px] lg:text-[52px] tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Meet the Notionmind Team</h1>
                      <h5 className='text-center px-3 leading-snug text-lg md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-2 place-self-center'>We're building business—we're creating a movement.</h5>
                    </div>

                  <div className='my-4 lg:my-8'>
                          <Gallery/>
                  </div>

              </div>
          </div>
        </div>
        {/* About us ends */}

        {/* Get In Touch Button */}
        <GetInTouchBtn />
        {/* Get In Touch Button ends */}

        {/* Footer */}
        <div><Footer/></div>
        {/* Footer ends */}
    </div>
  )
}

export default About