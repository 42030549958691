import React from 'react'

const SecureDocument = () => {
  return (
    <>
    <div className="sm:px-4 lg:px-8 mb-8 lg:mb-20">
      <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Section */}
          <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
              <div className='justify-items-start pt-2'>
                  <div className='flex-col gap-2'>
                  <div className='my-4 lg:my-8'>
                      <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                      <h4 className='mt-2 lg:text-xl xl:text-2xl'>Property Management</h4>
                  </div>
                  <div className='my-4 lg:my-8'>
                      <button className='rounded-md xl:text-xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                      <h4 className='mt-2 lg:text-xl xl:text-2xl'>Real Estate & Property Management</h4>
                  </div>
                  </div>
              </div>
          </div>
          {/* Right Section */}
          <div className="space-y-8 place-content-center">
          {/* Problem */}
          <div>
              <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
              <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug tracking-tighter">
              Implemented a comprehensive secure document handling system for a large property management company using SendSafely API integration. The solution enables secure document uploads, controlled access, and automated processing while maintaining strict security protocols and compliance requirements.
              </p>
          </div>
          </div>
      </div>
    </div>

    <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
      <div className='grid lg:grid-cols-5 gap-4 mt-4 xl:mx-8'>
          <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-4 xl:px-2'>
              <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>The Challenge</h2>
          </div>
          <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-6 sm:px-8'>
          <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
          Document management teams faced multiple challenges:
          </p>

          <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Security Concerns:</p>
          <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
            <li>Sensitive document handling requirements</li>
            <li>Complex authorization needs</li>
            <li>Compliance mandates</li>
          </ul>
          <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Process Inefficiencies:</p>
          <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
            <li>Manual document routing</li>
            <li>Time-consuming access management</li>
            <li>Complex authentication processes</li>
          </ul>

          <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>System Limitations:</p>
          <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
            <li>Limited upload capabilities</li>
            <li>Basic access controls</li>
            <li>Inadequate audit trails</li>
          </ul>

          <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>User Experience Issues:</p>
          <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
            <li>Complex upload procedures</li>
            <li>Difficult document retrieval</li>
            <li>Limited self-service options</li>
          </ul>

          </div>
      </div>
    </div>

    <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
      <div className='grid lg:grid-cols-5 gap-4 mt-4 xl:mx-8'>
          <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-4 xl:px-2'>
              <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>Our Solution</h2>
          </div>
          <div className='col-span-3 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-6 sm:px-8'>

          <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-4'>Send Safely Integration:</p>
          <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
            <li>Custom API wrapper development</li>
            <li>Secure upload system</li>
            <li>Automated access control</li>
          </ul>
          <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Security Framework:</p>
          <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
            <li>One-time passcode generation</li>
            <li>Automatic file expiration</li>
            <li>Comprehensive audit logging</li>
          </ul>

          <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>User Management System:</p>
          <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
            <li>Role-based access controls</li>
            <li>Self-service portal</li>
            <li>Automated notifications</li>
          </ul>

          <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Processing Pipeline:</p>
          <ul className="mt-4 lg:mt-8 leading-tight text-[var(--blacktext)] text-base md:text-[24px] px-2 list-outside space-y-2 text-left list-disc">
            <li>Automated document routing</li>
            <li>Real-time status tracking</li>
            <li>Exception handling system</li>
          </ul>

          </div>
      </div>
    </div>

    <div className='mt-8 lg:mt-16 2xl:mt-20 mb-8'>
      <div className='place-items-center flex flex-col justify-center items-center'>
      <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Technical Implementation</h1>
      </div>
    </div>
    <div className='md:mb-16 xl:mb-20'>
        <div className='grid sm:grid-cols-5 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
            <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Integration Layer</h2>
            </div>
            <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
            <ul className="leading-none text-[var(--blacktext)] text-base md:text-[20px] lg:text-[24px] list-outside space-y-2 text-left list-item">
              <li>REST API endpoints</li>
              <li>Custom authentication handlers</li>
              <li>Webhook processors</li>
            </ul>
            </div>
        </div>
        <div className='grid sm:grid-cols-5 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
            <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Web Portal</h2>
            </div>
            <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
            <ul className="leading-none text-[var(--blacktext)] text-base md:text-[20px] lg:text-[24px] list-outside space-y-2 text-left list-item">
              <li>User-friendly interface</li>
              <li>Document management tools</li>
              <li>Administrative dashboard</li>
            </ul>
            </div>
        </div>
        <div className='grid sm:grid-cols-5 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
            <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Backend System</h2>
            </div>
            <div className='col-span-3 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
              <ul className="leading-none text-[var(--blacktext)] text-base md:text-[20px] lg:text-[24px] list-outside space-y-2 text-left list-item">
                <li>Secure file processing</li>
                <li>Real-time synchronization</li>
                <li>Audit trail management</li>
              </ul>
            </div>
        </div>
    </div>

    <div className='my-12 lg:mb-8 xl:mb-12'>
      <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-6 pt-8 pb-2 lg:py-12 md:px-16'>
          <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Results & Benefits</h1>

          <div className='my-12'>
              <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Operational Improvements</h2>
              <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li>Zero security breaches</li>
                  <li>65% faster document processing</li>
                  <li>75% reduction in support tickets</li>
                  <li>100% compliance maintenance</li>
              </ul>
          </div>

          <div className='my-12'>
              <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Business Impact</h2>
              <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li>Enhanced security posture</li>
                  <li>Improved user satisfaction</li>
                  <li>Reduced operational costs</li>
                  <li>Streamlined workflows</li>
              </ul>
          </div>

          <div className='my-12'>
              <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">ROI Metrics</h2>
              <ul className="mt-2 md:text-2xl px-2 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                  <li>85% positive user feedback</li>
                  <li>70% reduction in complaints</li>
                  <li>50% decrease in handling costs</li>
              </ul>
          </div>
      </div>
    </div>

    <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-20'>
      <div className='place-items-center flex flex-col justify-center items-center'>
      <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
      <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>The Secure Document Management Integration successfully transformed document handling processes through robust security measures and efficient workflows. This implementation demonstrates how secure API integration can enhance both security and user experience.</h5>
      </div>
    </div>

  </>
  )
}

export default SecureDocument