import React, {useState, useEffect, useRef} from 'react';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import { HiOutlineChevronUp } from "react-icons/hi2";
import { collection, addDoc } from "firebase/firestore";
import { db } from '../services/Firebase';
import Meta from './Meta'
import EntNavbar from './EntNavbar';

const Enterprise = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
      fullName: "",
      email: "",
      phone: "",
      company: "",
      service: "",
      description: "",
      budget: "",
      deadline: "",
      createdAt: new Date(),
      ipAddress: "",
    });


    // Update service in formData when selectedOptions changes
    useEffect(() => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        service: selectedOptions, // Assign new service value
      }));
    }, [selectedOptions]); // Dependency array ensures it runs only when selectedOptions changes

    useEffect(() => {
        // Fetch the user's IP address
        fetch("https://api.ipify.org?format=json")
          .then((response) => response.json())
          .then((data) => {
            setFormData((prevState) => ({
              ...prevState,
              ipAddress: data.ip, // Update with the fetched IP
            }));
          })
          .catch((error) => {
            console.error("Error fetching IP address:", error);
          });
    }, []);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };



    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitting(true); // Disable the button
      try {
        // Save form data to Firestore
        await addDoc(collection(db, "enterprise"), formData);
        //alert("Message submitted successfully!");
        //setFormData({ name: "", email: "", message: "" }); // Reset form
        navigate('/thankyou'); // Redirect to /thankyou

      } catch (error) {
        setIsSubmitting(false); // Re-enable the button after submission
        console.error("Error submitting message: ", error);
        alert("Failed to submit the message!");
      }
    };


    // Toggles dropdown visibility
    const toggleDropdown = () => {
      setIsDropdownOpen((prev) => !prev);
    };

    // Updates selected options
    const handleCheckboxChange = (event) => {
      const value = event.target.value;
      setSelectedOptions((prev) =>
        prev.includes(value)
          ? prev.filter((option) => option !== value) // Remove option if unchecked
          : [...prev, value] // Add option if checked
      );
    };
    // Closes dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  //scrolltotop
  const [isVisible, setIsVisible] = useState(false);

  // Toggle button visibility on scroll
  useEffect(() => {
  const toggleVisibility = () => {
      if (window.scrollY > 500) {
      setIsVisible(true);
      } else {
      setIsVisible(false);
      }
  };

  window.addEventListener('scroll', toggleVisibility);

  return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
  };
  //scrolltotopends

  return (

    <div className='bg-[#090909]'>
        <Meta
          title="Notionmind® - Transform Your Enterprise with AI-Powered Innovation"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

        <div>
          {isVisible && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-5 right-5 bg-[#333A3F] text-white p-3 rounded-full shadow-lg"
          >
            <HiOutlineChevronUp size={20} />
          </button>
          )}
        </div>
        {/* header */}
        <EntNavbar />
        {/* header ends */}
        <div className='bg-enterprise bg-no-repeat bg-contain m-auto place-content-center my-2'>

        <div className='mt-8 xl:mt-32'>
          <div className='heroSection max-w-[1380px] flex flex-col justify-center place-items-center my-8 md:my-16 m-auto'>
              <h1 className='pb-1 px-4 text-center lg:w-10/12 leading-[1.10em] tracking-[-0.06em] text-4xl sm:text-[54px] lg:text-[72px] xl:text-[86px] font-medium bg-gradient-to-r from-[#FFFFFF] from-[40%] to-[#626C72] bg-clip-text text-transparent'>Transform Your Enterprise with AI-Powered Innovation</h1>
              <p className='text-white mt-4 lg:mt-8 xl:mt-12 w-10/12 sm:w-8/12 lg:w-6/12 place-self-center text-base md:text-2xl xl:text-3xl text-center md:font-normal px-3 md:px-0 py-4 tracking-tighter'>Notionmind® provides strategic consulting and advanced implementation services for organizations to steer their journey into a digitally transformed future. This AI-first approach ensures your organization is at the forefront of the increasingly competitive environment.</p>
          </div>
        </div>

        <div className='mx-2 lg:mx-4 xl:mx-0 my-4'>
          <div className='lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-sm shadow-[#DFDFDF] rounded-3xl sm:rounded-[50px] m-auto px-3 py-8 md:px-8 md:py-12 bg-[#FFFFFF]'>
            <div className='grid sm:grid-cols-3 sm:gap-4 rounded-xl sm:rounded-[30px] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#626C72] from-[0%] via-[#2B3135] to-[#090A0B] to-80%'>
              <div className='p-8 sm:px-6 lg:px-12 sm:py-16'>
                <h2 className='text-4xl lg:text-5xl xl:text-6xl tracking-tighter bg-gradient-to-r from-[#FFFFFF] from-[20%] to-[#626C72] bg-clip-text text-transparent'>Driving <br />
                Innovation</h2>
              </div>
              <div className='col-span-2 p-8 sm:px-6 lg:px-12 sm:py-16 tracking-tighter'>
                <p className='text-white leading-tight text-lg lg:text-[28px]'>Revolutionize your processes with emerging technologies and smarter systems that position you ahead of competition.</p>
                <p className='text-white leading-tight text-lg lg:text-[28px] font-bold mt-8'>AI & Automation:</p>
                <p className='text-white leading-tight text-lg lg:text-[28px]'>Simplify workflows with intelligent systems.</p>

                <p className='text-white leading-tight text-lg lg:text-[28px] font-bold mt-8'>Digital Transformation:</p>
                <p className='text-white leading-tight text-lg lg:text-[28px]'>Smarter processes for modern businesses.</p>
              </div>
            </div>

            <div className='my-12 grid sm:grid-cols-3 sm:gap-4 rounded-xl sm:rounded-[30px] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#626C72] from-[0%] via-[#2B3135] to-[#090A0B] to-80%'>
              <div className='p-8 sm:px-6 lg:px-10 sm:py-16'>
                <h2 className='text-4xl lg:text-5xl xl:text-6xl tracking-tighter bg-gradient-to-r from-[#FFFFFF] from-[20%] to-[#626C72] bg-clip-text text-transparent'>Optimizing <br/> Operations</h2>
              </div>
              <div className='col-span-2 p-8 sm:px-6 lg:px-12 sm:py-16 tracking-tighter'>
                <p className='text-white leading-tight text-lg lg:text-[28px]'>Maximize operational efficiency with strategic cloud adoption and system modernization.</p>
                <p className='text-white leading-tight text-lg lg:text-[28px] font-bold mt-8'>Data Insights:</p>
                <p className='text-white leading-tight text-lg lg:text-[28px]'>Unlock better decisions with advanced analytics.</p>

                <p className='text-white leading-tight text-lg lg:text-[28px] font-bold mt-8'>Cloud Services:</p>
                <p className='text-white leading-tight text-lg lg:text-[28px]'>Secure, scalable cloud adoption.</p>
                <p className='text-white leading-tight text-lg lg:text-[28px] font-bold mt-8'>System Modernization:</p>
                <p className='text-white leading-tight text-lg lg:text-[28px]'>Revitalize outdated technologies.</p>
                <p className='text-white leading-tight text-lg lg:text-[28px] font-bold mt-8'>Managed Services:</p>
                <p className='text-white leading-tight text-lg lg:text-[28px]'>Proactive support to ensure smooth operations.</p>
              </div>
            </div>

            <div className='my-12 grid sm:grid-cols-3 sm:gap-4 rounded-xl sm:rounded-[30px] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#626C72] from-[0%] via-[#2B3135] to-[#090A0B] to-80%'>
              <div className='p-8 sm:px-6 lg:px-12 sm:py-16'>
                <h2 className='text-4xl lg:text-5xl xl:text-6xl tracking-tighter bg-gradient-to-r from-[#FFFFFF] from-[20%] to-[#626C72] bg-clip-text text-transparent'>Enabling <br/>
                Growth</h2>
              </div>
              <div className='col-span-2 p-8 sm:px-6 lg:px-12 sm:py-16 tracking-tighter'>
                <p className='text-white leading-tight text-lg lg:text-[28px]'>Accelerate business growth with custom-built solutions and expert teams that scale alongside your business.</p>
                <p className='text-white leading-tight text-lg lg:text-[28px] font-bold mt-8'>Custom Solutions:</p>
                <p className='text-white leading-tight text-lg lg:text-[28px]'>Tailored software for unique enterprise needs.</p>

                <p className='text-white leading-tight text-lg lg:text-[28px] font-bold mt-8'>Team Extension:</p>
                <p className='text-white leading-tight text-lg lg:text-[28px]'>Dedicated experts to amplify your capabilities.</p>
              </div>
            </div>

              <div className="mx-auto">
                <div className='mt-20 mb-12'>
                    <h5 className="text-sm lg:text-lg text-[var(--blacktext)] text-center uppercase tracking-wider">
                    Why Partner With Us?
                    </h5>
                    <h1 className='text-center my-4 leading-[1.10em] tracking-[-0.06em] text-4xl md:text-5xl lg:text-6xl bg-gradient-to-bl from-[#626C72] via-[#2B3135] to-[#090A0B] bg-clip-text text-transparent'>Built for Enterprise Success</h1>
                </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8 xl:gap-12 mx-4 tracking-tighter">

                    <div className='group p-5 lg:p-7 rounded-3xl bg-[#F7F7F7] border-b-4 border-[#e7e7e7] transition ease-in delay-100 hover:bg-[#f0f0f0]'>
                        <h3 className="text-2xl lg:text-4xl font-semibold sm:h-20">
                        Secure
                        </h3>
                        <p className="mt-4 lg:mt-8 text-lg">
                        Adherence to the highest security standards for reliable enterprise solutions.
                        </p>
                    </div>

                    <div className='group p-5 lg:p-7 rounded-3xl bg-[#F7F7F7] border-b-4 border-[#e7e7e7] transition ease-in delay-100 hover:bg-[#f0f0f0]'>
                        <h3 className="text-2xl lg:text-4xl font-serif font-semibold">
                        Cost-Saving & Scalable
                        </h3>
                        <p className="mt-4 lg:mt-8 text-lg">
                        Reduce operational inefficiencies and save on traditional triple-figure $$$ costs.
                        </p>
                    </div>

                    <div className='group p-5 lg:p-7 rounded-3xl bg-[#F7F7F7] border-b-4 border-[#e7e7e7] transition ease-in delay-100 hover:bg-[#f0f0f0]'>
                        <h3 className="text-2xl lg:text-4xl font-serif font-semibold">Agile & <br/> Dedicated</h3>
                        <p className="mt-4 lg:mt-8 text-lg">
                        Teams tailored to your needs with scalable, focused, and flexible delivery models.
                        </p>
                    </div>
                    </div>
              </div>

              <div className='mt-12 sm:my-20 lg:my-32 flex justify-center'>
                    <div className='grid md:grid-cols-2 lg:grid-cols-4 tracking-tighter'>
                        <div className='p-2 mx-2 lg:mx-8 mb-4 sm:mb-0 place-items-center'>
                        <h1 className='text-center text-6xl sm:text-8xl font-bold tracking-tighter bg-gradient-to-r from-[#111315] to-[#0887FF]  to-[90%] bg-clip-text text-transparent'>5+</h1>
                        <h5 className='text-lg xl:text-xl text-center text-[var(--blacktext)] mt-2'>Enterprise Clients across diverse industries</h5>
                        </div>
                        <div className='p-2 mx-2 lg:mx-8 mb-4 sm:mb-0 place-items-center'>
                        <h1 className='text-center text-6xl sm:text-8xl font-bold tracking-tighter bg-gradient-to-r from-[#111315] to-[#0887FF]  to-[90%] bg-clip-text text-transparent'>6+</h1>
                        <h5 className='text-lg xl:text-xl text-center text-[var(--blacktext)] mt-2 2xl:w-10/12'>Months Average Tenure</h5>
                        </div>
                        <div className='p-2 mx-2 lg:mx-8 mb-4 sm:mb-0 place-items-center'>
                        <h1 className='text-center text-6xl sm:text-8xl font-bold tracking-tighter bg-gradient-to-r from-[#111315] to-[#0887FF] to-[90%] bg-clip-text text-transparent'>10+</h1>
                        <h5 className='text-lg xl:text-xl text-center text-[var(--blacktext)] mt-2 2xl:w-10/12'>Successful Projects within budget</h5>
                        </div>
                        <div className='p-2 mx-2 lg:mx-8 mb-4 sm:mb-0 place-items-center'>
                        <h1 className='text-center text-6xl sm:text-8xl font-bold tracking-tighter bg-gradient-to-r from-[#111315] to-[#0887FF] to-[90%] bg-clip-text text-transparent'>99%</h1>
                        <h5 className='text-lg xl:text-xl text-center text-[var(--blacktext)] mt-2 2xl:w-10/12'>Client Satisfaction Rate</h5>
                        </div>
                    </div>
              </div>

              <div className='my-12 sm:my-16'>
                <div className='max-w-[1380px] flex flex-col justify-center m-auto'>
                    <h1 className='pb-1 text-center leading-[1.10em] tracking-[-0.06em] text-4xl sm:text-[52px] lg:text-[64px] xl:text-[72px] font-[700px] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] from-[50%] via-[#103FB8] to-[#2B3135] bg-clip-text text-transparent'>Ready to Empower Your Enterprise?</h1>
                    <p className='text-[var(--blacktext)] mt-4 lg:mt-8 xl:mt-12 w-10/12 sm:w-8/12 lg:w-6/12 place-self-center text-lg md:text-2xl xl:text-3xl text-center md:font-normal px-3 md:px-0 tracking-tighter'>Let's discuss how we can transform your business challenges into opportunities.</p>
                </div>
              </div>

              <div className=''>
              <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-4 py-8 lg:py-12 md:px-16'>
                <form className="mx-2 md:mx-8 lg:mx-20 xl:mx-24" onSubmit={handleSubmit}>
                  <div className='mb-4 md:mb-6'>
                    <label htmlFor="fullName" className="block text-sm md:text-base text-[var(--graytext)]">
                      Full Name
                    </label>
                    <input
                      type="text"
                      id="fullName"
                      name="fullName"
                          value={formData.fullName}
                          onChange={handleChange}
                          required
                      className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg sm:rounded-xl shadow-sm focus:border-[var(--notionblue)] focus:ring-[var(--notionblue)]"
                      />
                  </div>

                  <div className='mb-4 md:mb-6'>
                    <label htmlFor="email" className="block text-sm md:text-base text-[var(--graytext)]">
                      Business Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg sm:rounded-xl shadow-sm focus:border-[var(--notionblue)] focus:ring-[var(--notionblue)]"
                      />
                  </div>

                  <div className='mb-4 md:mb-6'>
                    <label htmlFor="phone" className="text-sm md:text-base text-[var(--graytext)]">
                      Phone Number <span className="text-xs text-gray-500 ms-4">We value your privacy. No spam, just updates.</span>
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg sm:rounded-xl shadow-sm focus:border-[var(--notionblue)] focus:ring-[var(--notionblue)]"
                      />
                  </div>

                  <div className='mb-4 md:mb-6'>
                    <label htmlFor="company" className="block text-sm md:text-base text-[var(--graytext)]">
                      Company Name
                    </label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                      required
                      className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg sm:rounded-xl shadow-sm focus:border-[var(--notionblue)] focus:ring-[var(--notionblue)]"
                      />
                  </div>

                  <div className="mb-4 md:mb-6 relative">
                    <label htmlFor="service" className="block text-sm md:text-base text-[var(--graytext)]">
                      Service of Interest
                    </label>
                    {/* dropdown */}
                    <div className="relative w-full" ref={dropdownRef}>
                      {/* Input Field */}
                      <input
                        type="text"
                        value={selectedOptions.join(', ') || 'Select options'}
                        placeholder="Select options"
                        readOnly
                        onClick={toggleDropdown}
                        name="service"
                        required
                        className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg shadow-sm focus:ring-2 focus:ring-[var(--blacktext)] focus:outline-none cursor-pointer"
                      />

                      {isDropdownOpen && (
                        <div className="absolute mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                          <div className="p-4 space-y-2">
                            {['AI & Automation', 'Digital Transformation', 'Data Insights', 'Cloud Services', 'System Modernization', 'Managed Services', 'Custom Solutions', 'Team Extension', 'Others'].map((option) => (
                              <label key={option} className="flex items-center space-x-2">
                                <input
                                  type="checkbox"
                                  value={option}
                                  checked={selectedOptions.includes(option)}
                                  onChange={handleCheckboxChange}
                                  className="form-checkbox text-blue-500 rounded"
                                />
                                <span>{option}</span>
                              </label>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>

                  </div>

                  <div className='mb-4 md:mb-6'>
                    <label htmlFor="description" className="block text-sm md:text-base text-[var(--graytext)]">
                      Project Details/Description
                    </label>
                    <textarea
                      id="description"
                      rows="4"
                      name="description"
                          value={formData.description}
                          onChange={handleChange}
                          required
                      className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg sm:rounded-xl shadow-sm focus:border-[var(--notionblue)] focus:ring-[var(--notionblue)]"
                      />
                  </div>


              <div className='mb-4 md:mb-6'>
                <label htmlFor="budget" className="block text-sm md:text-base text-[var(--graytext)] mb-2">
                Estimated Budget
                </label>
                <div className="flex flex-wrap gap-4">
                  {[
                    { value: "$15K to 30K", label: "$15K to 30K" },
                    { value: "$30K to 50K", label: "$30K to 50K" },
                    { value: "More than $50K", label: "More than $50K" }
                  ].map((option) => (
                    <label
                      key={option.value}
                      className={`px-4 py-2 rounded-lg cursor-pointer border ${
                        formData.budget === option.value
                          ? "bg-[var(--notionblue)] text-white border-[var(--notionblue)]"
                          : "bg-white text-[var(--blacktext)] border-gray-300 hover:bg-gray-100"
                      }`}
                    >
                      <input
                        type="radio"
                        name="budget"
                        value={option.value}
                        checked={formData.budget === option.value}
                        onChange={handleChange}
                        required
                        className="sr-only" // Use screen-reader friendly class
                      />
                      {option.label}
                    </label>
                  ))}
                </div>
              </div>

              <div className='mb-4 md:mb-6'>
                <label htmlFor="deadline" className="block text-sm md:text-base text-[var(--graytext)] mb-2">
                Timeframe/Deadline
                </label>
                <div className="flex flex-wrap gap-4">
                  {[
                    { value: "ASAP", label: "ASAP" },
                    { value: "Next Month", label: "Next Month" },
                    { value: "3 Months", label: "3 Months" },
                    { value: "Not Sure", label: "Not Sure" },
                  ].map((option) => (
                    <label
                      key={option.value}
                      className={`px-4 py-2 rounded-lg cursor-pointer border ${
                        formData.deadline === option.value
                          ? "bg-[var(--notionblue)] text-white border-[var(--notionblue)]"
                          : "bg-white text-[var(--blacktext)] border-gray-300 hover:bg-gray-100"
                      }`}
                    >
                      <input
                        type="radio"
                        name="deadline"
                        value={option.value}
                        checked={formData.deadline === option.value}
                        onChange={handleChange}
                        required
                        className="sr-only" // Use screen-reader friendly class
                      />
                      {option.label}
                    </label>
                  ))}
                </div>
              </div>




                  <div className='place-self-center flex py-4 xl:py-8'>
                    <button disabled={isSubmitting} className="relative button-theme group">
                    <span className="relative px-8 py-2.5 btn-span-theme">
                    {isSubmitting ? "Submitting..." : "Submit Inquiry"}
                    </span>
                    </button>
                  </div>

                  <div className='place-items-center'>
                    <p className='text-center text-sm text-[var(--blacktext)]'>By submitting this form, you agree to our Terms & Conditions and acknowledge that we may collect and process your data as outlined in our Privacy Policy</p>
                  </div>

                </form>

              </div>
              </div>

          </div>
        </div>

        </div>

        {/* Footer */}
        <div><Footer/></div>
        {/* Footer ends */}
        {/* main div ends  */}
    </div>
  )
}

export default Enterprise