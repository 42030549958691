import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Meta from '../Meta';
import Paging from '../Paging';
import GetInTouchBtn from '../GetInTouchBtn';

const GoToWorkAmerica = () => {

  return (
    <div>
      <div>
      <Meta
          title="Notionmind® - Go To Work America – Revolutionizing Job Searching with Instant Connections"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />
      </div>
      {/* header */}
      <div><Navbar/></div>
      {/* header ends */}

      <div className='my-12'>
            <h4 className='text-[var(--blacktext)] text-center m-auto tracking-wider'>CASE STUDY</h4>
            <h1 className='sm:w-[60%] xl:w-[70%] text-3xl md:text-4xl xl:text-6xl pt-4 lg:pt-6 pb-8 px-2 sm:px-0 text-center m-auto tracking-[-0.06em] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] via-[#103FB8] to-[#2B3135] to-[60%] bg-clip-text text-transparent'>Go To Work America - Revolutionizing Job Searching with Instant Connections</h1>
        </div>

        <div className='mx-2 lg:mx-4 xl:mx-0 mt-4 mb-4 lg:mb-8'>
            <div className="lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-lg shadow-[#DFDFDF] bg-white rounded-3xl sm:rounded-[50px] m-auto p-2 md:p-8 lg:p-16">
                <div className="p-6 lg:px-8 mb-8 lg:mb-20">
                    <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {/* Left Section */}
                        <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
                            <div className='justify-items-start pt-2'>
                                <div className='flex-col gap-2'>
                                <div>
                                    <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                                    <h4 className='mt-4 text-2xl'>Go To Work America</h4>
                                </div>
                                <div className='my-8'>
                                    <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                                    <h4 className='mt-4 text-2xl'>Staffing and Recruiting</h4>
                                </div>
                                <div>
                                    <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Project</button>
                                    <h4 className='mt-4 text-2xl'>Revolutionizing Job Searching with Instant Connections</h4>
                                </div>
                                </div>
                            </div>
                        </div>
                        {/* Right Section */}
                        <div className="space-y-8 place-content-center">
                        {/* Problem */}
                        <div>
                            <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
                            <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug">
                            Go To Work America is a mobile job-search platform designed to instantly connect job seekers with employers for low-skill positions. By integrating video resumes, real-time availability toggles, and instant job offers, it simplifies the hiring process while ensuring flexibility and transparency for both parties.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>

                <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                    <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                        <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>                    
                            <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>The Challenge</h2>
                        </div>
                        <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                        Job-seeking applications typically focus on lengthy resumes and complex hiring processes, which are time-consuming and inefficient for filling low-skill, time-sensitive roles. Employers often face difficulties in finding candidates quickly for such positions. Similarly, job seekers struggle to find a platform that simplifies presenting their skills, availability, and rates without requiring traditional resumes.
                        </p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Efficiency:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Need for rapid matching of job seekers with employers.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Flexibility:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Employers need real-time availability updates from potential hires.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Convenience:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Simplifying the application process for low-skill positions without compromising on candidate quality.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Transparency:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Ensuring both employers and employees have clarity regarding skill sets, rates, and certifications.</p>
                        
                        </div>
                    </div>
                </div>

                <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                    <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                        <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>                    
                            <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>Our Solution</h2>
                        </div>
                        <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                        Go To Work America bridges the gap between job seekers and employers by providing a mobile application that streamlines the hiring process.
                        </p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Video Resumes:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Candidates can upload short video resumes highlighting their skills and personality, making it easier for employers to evaluate them quickly.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Real-Time Availability:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Users can toggle their availability status ("Available" or "Not Available"), ensuring employers only reach out to candidates ready to work.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Skill-Based Profiles:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Candidates list their skills, desired hourly rate, and personal details, enabling tailored job offers.</p>
                        
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Instant Offers:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Employers can send job offers, which users can accept or decline with a single tap.</p>
                        
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Hire Certificates:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Employers can issue hire certificates, providing candidates with credibility and a trackable record of employment.</p>
                        
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Admin Dashboard:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>A backend system for managing user data, job offers, and hire certificates, ensuring smooth operation and data analysis.</p>

                        </div>
                    </div>
                </div>

                <div className='mt-8 lg:mt-16 2xl:mt-20 mb-8'>
                    <div className='place-items-center flex flex-col justify-center items-center'>
                    <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Technical Implementation</h1>
                    </div>
                </div>

                <div className='md:mb-16 xl:mb-20'>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Frontend Development</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            User-friendly mobile application with video upload, real-time availability toggles, and instant notifications.
                            </h5>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Backend Development</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            API system for managing user profiles, job offers, availability, and hire certificates with secure authentication.
                            </h5>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Real-Time Features</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            Background processes for syncing availability and delivering immediate job offer notifications.
                            </h5>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Admin Panel</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            Centralized dashboard for monitoring platform activity and managing user data.
                            </h5>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Hosting and Storage</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            Scalable hosting for high traffic, secure media storage, and encrypted communication for data protection.
                            </h5>
                        </div>
                    </div>
                </div>

                <div className='my-12 lg:mb-8 xl:mb-12'>
                    <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-4 pt-8 pb-2 lg:py-12 md:px-16'>
                       <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Results & Benefits</h1>

                       <div className='my-12'>
                            <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">For Employers</h2>
                            <ul className="mt-2 md:text-2xl px-3 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                                <li>Rapid hiring process, reducing the time to fill positions by 70%.</li>
                                <li>Transparent skill evaluation through video resumes.</li>
                                <li>Easy issuance of hire certificates for credibility.</li>
                            </ul>
                        </div>

                        <div className='my-12'>
                            <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">For Job Seekers</h2>
                            <ul className="mt-2 md:text-2xl px-3 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                                <li>Simplified application process with no need for traditional resumes.</li>
                                <li>Greater visibility of hourly rate expectations and skillsets.</li>
                                <li>Flexibility to control availability and accept jobs on their own terms.</li>
                            </ul>
                        </div>

                        <div className='my-12'>
                            <h2 className="text-xl sm:text-2xl font-semibold mb-4 lg:mb-8 text-[var(--darkgray)]">Operational Benefits</h2>
                            <ul className="mt-2 md:text-2xl px-3 text-[var(--darkgray)] list-outside space-y-2 text-left list-disc">
                                <li>The backend system provides detailed insights into user activity, hiring patterns, and market trends.</li>
                                <li>Reduced administrative overhead due to streamlined communication and automation.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-20'>
                    <div className='place-items-center flex flex-col justify-center items-center'>
                    <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
                    <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>Go To Work America revolutionizes the hiring process for low-skill jobs by providing an innovative, user-friendly platform that ensures quick, reliable connections between job seekers and employers. With its real-time availability tracking, video resume integration, and hire certification process, the platform addresses key inefficiencies in the job market while empowering both employers and employees. This case study demonstrates how technology can transform the hiring landscape for time-sensitive roles, delivering tangible benefits for all stakeholders involved.</h5>
                    </div>
                </div>
                
            </div>
        </div>

      {/* Next Previous CS section */}
      <Paging
          prevLink="/case-studies/hobby-ideas"
          prevTitle="HobbyIdeas"
          nextLink="/case-studies/daily-mental-health-check"
          nextTitle="Daily Mental Health Check"/>
        {/* Next Previous CS section ends*/}

        {/* Get In Touch Button */}
          <GetInTouchBtn />
        {/* Get In Touch Button ends */}

      {/* Footer */}
      <div><Footer/></div>
      {/* Footer ends */}
      {/* main div ends  */}
    </div>
  )
}

export default GoToWorkAmerica