import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Meta from '../Meta';
import Paging from '../Paging';
import GetInTouchBtn from '../GetInTouchBtn';

const Conrex = () => {

  return (
    <div>
      <div>
      <Meta
          title="Notionmind® - Real Estate Enterprise Solutions: Conrex/Maymont Homes Digital Transformation"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

      </div>
      {/* header */}
      <div><Navbar/></div>
      {/* header ends */}

      <div className='my-12'>
            <h4 className='text-[var(--blacktext)] text-center m-auto tracking-wider'>CASE STUDY</h4>
            <h1 className='sm:w-[58%] xl:w-[70%] text-3xl md:text-4xl xl:text-6xl pt-4 lg:pt-6 pb-8 px-2 sm:px-0 text-center m-auto tracking-[-0.06em] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] via-[#103FB8] to-[#2B3135] to-[60%] bg-clip-text text-transparent'>Real Estate Enterprise Solutions: Conrex/Maymont Homes Digital Transformation</h1>
        </div>

        <div className='mx-2 lg:mx-4 xl:mx-0 mt-4 mb-4 lg:mb-8'>
            <div className="lg:max-w-[1200px] 2xl:max-w-[1380px] shadow-lg shadow-[#DFDFDF] bg-white rounded-3xl sm:rounded-[50px] m-auto p-2 md:p-8 lg:p-16">
                <div className="p-6 lg:px-8 mb-8 lg:mb-20">
                    <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {/* Left Section */}
                        <div className='lg:border-r-2 lg:border-[#e7e7e7] lg:pe-2 place-content-center'>
                            <div className='justify-items-start pt-2'>
                                <div className='flex-col gap-2'>
                                <div>
                                    <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Client</button>
                                    <h4 className='mt-4 text-2xl'>Conrex Property Management (now Maymont Homes)</h4>
                                </div>
                                <div className='my-8'>
                                    <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Industry</button>
                                    <h4 className='mt-4 text-2xl'>Real Estate Management</h4>
                                </div>
                                <div>
                                    <button className='rounded-md lg:text-2xl justify-self-start border-[var(--blacktext)] border-[1px] py-[2px] px-2 text-[var(--blacktext)] text-[12px] sm:text-sm font-normal'>Project</button>
                                    <h4 className='mt-4 text-2xl'>Enterprise Digital Transformation</h4>
                                </div>
                                </div>
                            </div>
                        </div>
                        {/* Right Section */}
                        <div className="space-y-8 place-content-center">
                        {/* Problem */}
                        <div>
                            <h2 className="text-xl sm:text-2xl font-semibold text-[var(--blacktext)]">Overview</h2>
                            <p className="mt-2 md:text-[20px] lg:text-2xl text-[var(--blacktext)] leading-snug">
                              Conrex Property Management (now Maymont Homes), a real estate enterprise managing over 10,000 single-family rental homes across 26 cities in the Midwest and Southeast, faced challenges in operational efficiency, system integration, and technical limitations.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>

                <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                    <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                        <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>                    
                            <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>The Challenge</h2>
                        </div>
                        <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                        Conrex Property Management struggled with disconnected systems, inefficient workflows, and limited technical capabilities. These issues resulted in poor data integrity, manual processes, and outdated tools, hindering scalability and tenant satisfaction.
                        </p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>System Integration Issues:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Disconnected systems, lack of standardized processes, poor data integrity, and internal knowledge gaps.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Operational Inefficiencies:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Manual data handling, limited real-time visibility, and inefficient lease/tenant management.</p>

                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Technical Limitations:</p>
                        <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Static website functionality, third-party dependency, and constrained storage.</p>
                        </div>
                    </div>
                </div>

                <div className='my-12 grid lg:grid-flow-col lg:py-4 rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                    <div className='grid lg:grid-cols-3 gap-4 mt-4 xl:mx-8'>
                        <div className='py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8 lg:px-2'>                    
                            <h2 className='text-3xl sm:text-5xl tracking-tight text-[var(--blacktext)]'>Our Solution</h2>
                        </div>
                        <div className='col-span-2 py-4 sm:pt-6 xl:pt-8 sm:pb-8 px-4 sm:px-8'>
                            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px]'>
                            The solution involved the integration of several systems to streamline operations, including lead management, tenant communication, and maintenance tracking. Key components included cloud storage, website modernization, and automated workflows, all aimed at improving efficiency and scalability.</p>

                            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Lead to Lease Management System (LMS):</p>
                            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Streamlined leasing process with property listings, lead tracking, and automated handling, integrated with tools like Rently and SMS/email notifications.</p>

                            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Tenant Management System (TMS):</p>
                            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Centralized tenant interactions and maintenance tracking with automated notifications and a tenant portal.</p>

                            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Dispatch Management System (DMS):</p>
                            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Optimized maintenance with real-time updates, vendor metrics, and mobile access.</p>

                            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Cloud Storage Integration:</p>
                            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>SharePoint for efficient document storage and retrieval, reducing costs and improving system performance.</p>

                            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Calendar Migration:</p>
                            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Migrated Gmail to Outlook for better scheduling and event management.</p>

                            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] font-bold mt-9'>Website Modernization:</p>
                            <p className='text-[var(--blacktext)] leading-tight text-base md:text-[24px] mt-3'>Enhanced the digital presence with WordPress, dynamic listings, SEO optimization, and mobile responsiveness.</p>

                        </div>
                    </div>
                </div>

                <div className='mt-8 lg:mt-16 2xl:mt-20 mb-8'>
                    <div className='place-items-center flex flex-col justify-center items-center'>
                    <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Technical Implementation</h1>
                    <h5 className='text-center flex justify-center lg:font-bold xl:w-9/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>Built on QuickBase, Microsoft 365, AWS, and custom APIs, the solution ensured security with role-based access, encryption, and compliance monitoring.</h5>
                    </div>
                </div>
                <div className='md:mb-16 xl:mb-20'>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Phase 1</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            System assessment, process mapping, and integration planning.
                            </h5>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Phase 2</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            Core development with applications, migrations, and testing.
                            </h5>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-3 gap-2 mt-4 xl:mx-8 border-b border-gray-300'>
                        <div className='py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-12'>                    
                            <h2 className='text-xl md:text-2xl tracking-tight font-bold text-[var(--blacktext)]'>Phase 3</h2>                    
                        </div>
                        <div className='col-span-2 py-2 sm:pt-6 xl:pt-8 sm:pb-8 px-4 lg:px-8'>
                            <h5 className='text-[var(--blacktext)] leading-tight text-base md:text-[20px] lg:text-[24px]'>
                            Deployment with training, documentation, and support setup.
                            </h5>
                        </div>
                    </div>
                </div>

                <div className='my-12 lg:mb-8 xl:mb-12'>
                    <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-4 pt-8 pb-2 lg:py-12 md:px-16'>
                       <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Results & Benefits</h1>
                       <div className='text-center my-12 place-items-center flex flex-col justify-center'>
                       <h1 className='text-center text-[24px] md:text-[28px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Operational Impact</h1>
                       <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>40% reduction in operational costs, 75% fewer manual entries, and 60% faster maintenance response.</h5>
                       </div>
                       <div className='text-center my-12 place-items-center flex flex-col justify-center'>
                       <h1 className='text-center text-[24px] md:text-[28px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Business Growth</h1>
                       <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>Improved tenant satisfaction (35%) and profitability through better decision-making and streamlined operations.</h5>
                       </div>
                       <div className='text-center my-12 place-items-center flex flex-col justify-center'>
                       <h1 className='text-center text-[24px] md:text-[28px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>System Performance</h1>
                       <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>99.9% uptime, real-time data synchronization, and improved scalability.</h5>
                       </div>
                    </div>
                </div>

                <div className='my-12 md:mt-16 xl:mt-1 2xl:mt-20'>
                    <div className='place-items-center flex flex-col justify-center items-center'>
                    <h1 className='text-center text-[24px] md:text-[28px] lg:text-[32px] font-semibold tracking-tighter pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Conclusion</h1>
                    <h5 className='text-center lg:w-8/12 px-4 text-sm md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>The digital transformation of Conrex Property Management, now Maymont Homes, successfully addressed critical business challenges by implementing integrated solutions that enhanced operational efficiency, tenant satisfaction, and scalability. By modernizing systems and processes, the company is now better equipped to manage its growing portfolio and is positioned for continued growth and success in the real estate market.</h5>
                    </div>
                </div>
                
            </div>
        </div>

        {/* Next Previous CS section */}
          <Paging
          prevLink="/case-studies/integration"
          prevTitle="Integration"
          nextLink="/case-studies/abad"
          nextTitle="ABAD"/>
        {/* Next Previous CS section ends*/}

        {/* Get In Touch Button */}
          <GetInTouchBtn />
        {/* Get In Touch Button ends */}


      {/* Footer */}
      <div><Footer/></div>
      {/* Footer ends */}
      {/* main div ends  */}
    </div>
  )
}

export default Conrex