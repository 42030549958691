import React, {useState, useRef, useEffect} from 'react';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../services/Firebase';
import Meta from './Meta'
import Navbar from './Navbar';

const Partnership = () => {
  const navigate = useNavigate();


  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
      fullName: "",
      email: "",
      phone: "",
      company: "",
      partnership: "",
      description: "",
      createdAt: new Date(),
      ipAddress: "",
    });

    // Update partnership in formData when selectedOptions changes
    useEffect(() => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        partnership: selectedOptions, // Assign new partnership value
      }));
    }, [selectedOptions]); // Dependency array ensures it runs only when selectedOptions changes

    useEffect(() => {
            // Fetch the user's IP address
            fetch("https://api.ipify.org?format=json")
              .then((response) => response.json())
              .then((data) => {
                setFormData((prevState) => ({
                  ...prevState,
                  ipAddress: data.ip, // Update with the fetched IP
                }));
              })
              .catch((error) => {
                console.error("Error fetching IP address:", error);
              });
    }, []);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };



    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitting(true); // Disable the button
      try {
        // Save form data to Firestore
        await addDoc(collection(db, "partnership"), formData);
        //alert("Message submitted successfully!");
        //setFormData({ name: "", email: "", message: "" }); // Reset form
        navigate('/thankyou'); // Redirect to /thankyou

      } catch (error) {
        setIsSubmitting(false); // Disable the button
        console.error("Error submitting message: ", error);
        alert("Failed to submit the message!");
      }
    };


    // Toggles dropdown visibility
    const toggleDropdown = () => {
      setIsDropdownOpen((prev) => !prev);
    };

    // Updates selected options
    const handleCheckboxChange = (event) => {
      const value = event.target.value;
      setSelectedOptions((prev) =>
        prev.includes(value)
          ? prev.filter((option) => option !== value) // Remove option if unchecked
          : [...prev, value] // Add option if checked
      );
    };
    // Closes dropdown if clicked outside
  useEffect(() => {<Navbar/>
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className='bg-[#F1F8FF]'>
      <div>
      <Meta
          title="Notionmind® - Powering Your Vision with Strategic Partnerships"
          description="Notionmind® Providing assistance to go digital with product consulting, web and app development, AI, data analytics and more."
          keywords="ideation, strategy, product, brands, ai, machine learning, data science, ui, ux, development, python, django, php, ios, android, flutter, seo, marketing, cloud, aws, digitalocean, docker, quickbase"
        />

      </div>
      {/* header */}
      <Navbar/>
      {/* header ends */}

      <div className='bg-partnership bg-no-repeat bg-top place-content-center mt-8 py-8'>

        <div className='my-8 md:my-16 mx-2'>
          <div className='max-w-[1380px] flex flex-col justify-center m-auto place-items-center'>
            <h1 className='title-theme leading-[1.10em] tracking-[-0.06em] lg:w-9/12 text-4xl sm:text-[64px] lg:text-[86px] font-medium pb-1'>Powering Your Vision with Strategic Partnerships</h1>
            <p className='place-self-center mt-8 lg:mt-16 text-lg md:text-3xl text-center text-[var(--darkgray)] font-medium md:font-normal px-3 md:px-0 py-4 tracking-tighter sm:w-[85%] lg:w-[54%]'>
            We team up with companies big and small—from new ventures to big corporations—to create solutions that make a difference. If you're looking for new thinking quicker results, or teams that work as one, we have partnerships that fit what you need.
            </p>
          </div>
        </div>
        {/* hero section ends */}

        <div className='mx-2 lg:mx-4 xl:mx-0 my-6'>
          <div className='lg:max-w-[1200px] 2xl:max-w-[1380px] bg-white rounded-3xl sm:rounded-[50px] m-auto px-3 py-2 sm:px-6 lg:py-8 xl:p-8 shadow-lg shadow-[#DFDFDF]'>

            <div className='m-auto'>
            {/* row 1 */}
            <div className="grid gap-6 lg:gap-8 grid-cols-1 md:grid-cols-2 px-4 sm:px-0 mt-6">

              <div className="rounded-xl sm:rounded-[30px] shadow-lg p-6 lg:p-8 flex flex-col justify-between bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#30A6FF] from-[0%] via-[#1863D8] to-[#103FB8] to-80%">
                {/* Title Section */}
                <p className="pb-1 mb-4 tracking-tighter text-4xl md:text-[42px] lg:text-[48px] md:w-[80%] lg:w-8/12 text-white leading-none">
                Visionary Collaboration</p>

                {/* Description */}
                <div className='mb-10 xl:mb-14 mt-4'>
                  <p className="text-white text-lg lg:text-2xl sm:h-20 2xl:h-fit font-semibold md:w-10/12 xl:w-9/12">
                    For startups or businesses exploring new ideas.
                  </p>
                </div>

                {/* Link */}
                <p className="text-white text-base lg:text-xl w-full sm:w-10/12">
                Get strategic input and innovative solutions to fuel your project.
                </p>
              </div>

              <div className="rounded-xl sm:rounded-[30px] shadow-lg p-6 lg:p-8 flex flex-col justify-between bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#30A6FF] from-[0%] via-[#1863D8] to-[#103FB8] to-80%">
                {/* Title Section */}
                <p className="pb-1 mb-4 tracking-tighter text-4xl md:text-[42px] lg:text-[48px] md:w-[80%] lg:w-8/12 text-white leading-none">
                Agile Partnership</p>

                {/* Description */}
                <div className='mb-10 xl:mb-14 mt-4'>
                  <p className="text-white text-lg lg:text-2xl sm:h-20 2xl:h-fit font-semibold md:w-10/12 xl:w-7/12">
                  Fast-paced teams needing flexibility.
                  </p>
                </div>

                {/* Link */}
                <p className="text-white text-base lg:text-xl w-full sm:w-10/12">
                Adapt quickly to changes and accelerate delivery with flexible workflows.
                </p>
              </div>

            </div>

            {/* row 2 */}
            <div className="grid gap-6 lg:gap-8 grid-cols-1 md:grid-cols-2 px-4 sm:px-0 mt-6 sm:mt-8 mb-8 lg:mb-20">

              <div className="rounded-xl sm:rounded-[30px] shadow-lg p-6 lg:p-8 flex flex-col justify-between bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#30A6FF] from-[0%] via-[#1863D8] to-[#103FB8] to-80%">
                {/* Title Section */}
                <p className="pb-1 mb-4 tracking-tighter text-4xl md:text-[42px] lg:text-[48px] md:w-[80%] lg:w-11/12 text-white leading-none">
                Integrated Team Extension</p>

                {/* Description */}
                <div className='mb-10 xl:mb-14 mt-4'>
                  <p className="text-white text-lg lg:text-2xl sm:h-20 2xl:h-fit font-semibold md:w-10/12 xl:w-7/12">
                  For enterprises seeking expert support.
                  </p>
                </div>

                {/* Link */}
                <p className="text-white text-base lg:text-xl w-full sm:w-10/12">
                Amplify your capabilities by embedding our experts with your in-house teams.
                </p>
              </div>

              <div className="rounded-xl sm:rounded-[30px] shadow-lg p-6 lg:p-8 flex flex-col justify-between bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#30A6FF] from-[0%] via-[#1863D8] to-[#103FB8] to-80%">
                {/* Title Section */}
                <p className="pb-1 mb-4 tracking-tighter text-4xl md:text-[42px] lg:text-[48px] md:w-[80%] lg:w-11/12 text-white leading-none">
                Full-Cycle Project Support</p>

                {/* Description */}
                <div className='mb-10 xl:mb-14 mt-4'>
                  <p className="text-white text-lg lg:text-2xl sm:h-20 2xl:h-fit font-semibold md:w-10/12 xl:w-7/12">
                  For businesses requiring end-to-end solutions.
                  </p>
                </div>

                {/* Link */}
                <p className="text-white text-base lg:text-xl w-full sm:w-10/12">
                From ideation to deployment, we manage every step of your journey.
                </p>
              </div>

            </div>
            </div>

            <div className='my-12 grid lg:grid-flow-col rounded-xl sm:rounded-[30px] bg-[#F6F7F8] border-b-[6px] border-[#DBDFE1] '>
                <div className='lg:col-span-3 rounded-xl sm:rounded-[30px] p-8 sm:px-12 sm:py-12 lg:py-16'>
                  <h2 className='text-4xl sm:text-5xl lg:text-6xl tracking-[-0.06em] text-[var(--blacktext)]'>How We Help Your Projects Succeed</h2>
                </div>
                <div className='lg:col-span-9 rounded-xl sm:rounded-[30px] tracking-tighter pb-8 px-8 sm:ps-8 xl:px-12 sm:py-12 lg:py-16 xl:me-8'>
                  <h5 className='text-[var(--blacktext)] leading-tight text-lg md:text-[28px] xl:w-11/12 xl:float-end'>Empower your
                   projects with innovative solutions and adaptable collaboration,
                   designed to drive growth and deliver measurable outcomes.</h5>
                  <h5 className='text-[var(--blacktext)] leading-tight text-lg md:text-[28px] xl:w-11/12 xl:float-end font-bold mt-9'>Strategic Alignment:</h5>
                  <h5 className='text-[var(--blacktext)] leading-tight text-lg md:text-[24px] mt-3 xl:w-11/12 xl:float-end'>Your goals are our mission. We customize solutions to align perfectly with your business vision.</h5>
                  <h5 className='text-[var(--blacktext)] leading-tight text-lg md:text-[28px] xl:w-11/12 xl:float-end font-bold mt-9'>Adaptive Engagement:</h5>
                  <h5 className='text-[var(--blacktext)] leading-tight text-lg md:text-[24px] mt-3 xl:w-11/12 xl:float-end'>Whether it's a sprint, long-term partnership, or tailored collaboration, we adapt to your needs with precision and flexibility.</h5>
                  <h5 className='text-[var(--blacktext)] leading-tight text-lg md:text-[28px] xl:w-11/12 xl:float-end font-bold mt-9'>Accelerated Impact:</h5>
                  <h5 className='text-[var(--blacktext)] leading-tight text-lg md:text-[24px] mt-3 xl:w-11/12 xl:float-end'>Reduce time-to-market and drive efficiency with agile workflows and expert teams.</h5>
                  <h5 className='text-[var(--blacktext)] leading-tight text-lg md:text-[28px] xl:w-11/12 xl:float-end font-bold mt-9'>Proven Expertise, Measurable Results:</h5>
                  <h5 className='text-[var(--blacktext)] leading-tight text-lg md:text-[24px] mt-3 xl:w-11/12 xl:float-end'>Trusted by clients for delivering measurable outcomes with clear KPIs and industry-leading practices.</h5>
                </div>
            </div>

            <div className='mt-12 lg:my-24 xl:my-28 flex justify-center'>
              <div className='grid md:grid-cols-2 lg:grid-cols-4'>
                  <div className='p-2 mx-2 lg:mx-8 mb-4 sm:mb-0 tracking-tighter place-items-center'>
                  <h1 className='text-center text-6xl sm:text-8xl font-bold tracking-tighter bg-gradient-to-r from-[#111315] to-[#0887FF]  to-[90%] bg-clip-text text-transparent'>25+</h1>
                  <h5 className='text-lg 2xl:text-xl text-center text-[var(--blacktext)] mt-2'>Years of combined industry expertise</h5>
                  </div>
                  <div className='p-2 mx-2 lg:mx-8 mb-4 sm:mb-0 tracking-tighter place-items-center'>
                  <h1 className='text-center text-6xl sm:text-8xl font-bold tracking-tighter bg-gradient-to-r from-[#111315] to-[#0887FF]  to-[90%] bg-clip-text text-transparent'>90%</h1>
                  <h5 className='text-lg 2xl:text-xl text-center text-[var(--blacktext)] mt-2 2xl:w-11/12'>Retention with satisfied long-term clientse</h5>
                  </div>
                  <div className='p-2 mx-2 lg:mx-8 mb-4 sm:mb-0 tracking-tighter place-items-center'>
                  <h1 className='text-center text-6xl sm:text-8xl font-bold tracking-tighter bg-gradient-to-r from-[#111315] to-[#0887FF] to-[90%] bg-clip-text text-transparent'>30+</h1>
                  <h5 className='text-lg 2xl:text-xl text-center text-[var(--blacktext)] mt-2 2xl:w-10/12'>Delivered Projects within time & budget</h5>
                  </div>
                  <div className='p-2 mx-2 lg:mx-8 mb-4 sm:mb-0 tracking-tighter place-items-center'>
                  <h1 className='text-center text-6xl sm:text-8xl font-bold tracking-tighter bg-gradient-to-r from-[#111315] to-[#0887FF] to-[90%] bg-clip-text text-transparent'>50%</h1>
                  <h5 className='text-lg 2xl:text-xl text-center text-[var(--blacktext)] mt-2 2xl:w-11/12'>Faster time-to-market using Agile models</h5>
                  </div>
              </div>
            </div>

            <div>
              <div className='mt-16'>
                <div className='flex flex-col justify-center place-items-center m-auto tracking-tighter'>
                <h1 className='text-center text-2xl md:text-[28px] lg:text-[32px] font-semibold pb-2 lg:pb-0 text-[var(--darkgray)] place-self-center'>Why Partnerships Save You More Than Just Money</h1>
                <h5 className='text-center text-lg lg:w-8/12 xl:w-6/12 px-4 md:text-lg lg:text-2xl text-[var(--darkgray)] lg:py-6 place-self-center'>Our long-term partnerships cut costs, speed up delivery, and drive innovation - keeping your business ready for the future..</h5>
                </div>
                <div className="mt-8 2xl:mt-4 rounded-lg flex justify-center place-items-center">
                  <table className="table-auto w-fit text-lg text-left bg-white shadow-sm tracking-tighter">
                    <thead className="bg-[#F6F7F8] border-y border-x-gray-300">
                      <tr className='border-x border-gray-300'>
                        <th className="px-4 lg:pe-20 xl:pe-36 py-2">Feature</th>
                        <th className="px-4 lg:pe-12 xl:pe-20 py-2">Individual Projects</th>
                        <th className="px-4 lg:pe-10 xl:pe-16 py-2">Long-Term Partnerships</th>
                      </tr>
                    </thead>
                    <tbody className='border-y border-x border-gray-300'>
                      <tr className='border-b border-gray-300'>
                        <td className="text-sm sm:text-base font-semibold px-4 py-2">Cost Per Hour</td>
                        <td className="text-sm sm:text-base px-4 py-2">$$$</td>
                        <td className="text-sm sm:text-base px-4 py-2">$$</td>
                      </tr>
                      <tr className='border-b border-gray-300'>
                        <td className="text-sm sm:text-base font-semibold px-4 py-2">Delivery Speed</td>
                        <td className="text-sm sm:text-base px-4 py-2">Moderate</td>
                        <td className="text-sm sm:text-base px-4 py-2">Fast (Streamlined Processes)</td>
                      </tr>
                      <tr className='border-b border-gray-300'>
                        <td className="text-sm sm:text-base font-semibold px-4 py-2">Team Familiarity</td>
                        <td className="text-sm sm:text-base px-4 py-2">New team every time</td>
                        <td className="text-sm sm:text-base px-4 py-2">Dedicated Experts</td>
                      </tr>
                      <tr className='border-b border-gray-300'>
                        <td className="text-sm sm:text-base font-semibold px-4 py-2">Scalability</td>
                        <td className="text-sm sm:text-base px-4 py-2">Limited</td>
                        <td className="text-sm sm:text-base px-4 py-2">Flexible & Scalable</td>
                      </tr>
                      <tr className='border-b border-gray-300'>
                        <td className="text-sm sm:text-base font-semibold px-4 py-2">Innovation Potential</td>
                        <td className="text-sm sm:text-base px-4 py-2">Project-Specific</td>
                        <td className="text-sm sm:text-base px-4 py-2">Continuous Improvements</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div>
              <div className='max-w-[1380px] flex flex-col justify-center mt-20 mb-8 md:mt-32 md:mb-16 m-auto'>
                  <h1 className='text-center leading-[1.10em] tracking-[-0.06em] text-4xl sm:text-[52px] lg:text-[64px] xl:text-[72px] font-[700px] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[#0888FF] from-[50%] via-[#103FB8] to-[#2B3135] bg-clip-text text-transparent'>Let's Build Success Together</h1>
                  <p className='text-[var(--blacktext)] mt-4 lg:mt-8 xl:mt-12 w-full sm:w-8/12 lg:w-6/12 xl:w-7/12 2xl:w-5/12 place-self-center text-lg md:text-2xl xl:text-3xl text-center md:font-normal px-3 md:px-0 tracking-tighter'>Take the first step towards transforming your business—reach out today!</p>
              </div>
            </div>
            <div className='mb-4 lg:mb-8 xl:mb-12'>
              <div className='lg:max-w-[700px] xl:max-w-[865px] m-auto bg-[#F6F7F8] rounded-3xl px-4 py-8 lg:py-12 md:px-16'>
                <form className="mx-2 md:mx-8 lg:mx-20 xl:mx-24" onSubmit={handleSubmit}>
                  <div className='mb-4 md:mb-6'>
                    <label htmlFor="fullName" className="block text-sm md:text-base text-[var(--graytext)]">
                      Full Name
                    </label>
                    <input
                      type="text"
                      id="fullName"
                      name="fullName"
                          value={formData.fullName}
                          onChange={handleChange}
                          required
                      className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg sm:rounded-xl shadow-sm focus:border-[var(--notionblue)] focus:ring-[var(--notionblue)]"
                      />
                  </div>

                  <div className='mb-4 md:mb-6'>
                    <label htmlFor="email" className="block text-sm md:text-base text-[var(--graytext)]">
                      Business Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                      className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg sm:rounded-xl shadow-sm focus:border-[var(--notionblue)] focus:ring-[var(--notionblue)]"
                      />
                  </div>

                  <div className='mb-4 md:mb-6'>
                    <label htmlFor="phone" className="text-sm md:text-base text-[var(--graytext)]">
                      Phone Number <span className="text-xs text-gray-500 ms-4">We value your privacy. No spam, just updates.</span>
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                      className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg sm:rounded-xl shadow-sm focus:border-[var(--notionblue)] focus:ring-[var(--notionblue)]"
                      />
                  </div>

                  <div className='mb-4 md:mb-6'>
                    <label htmlFor="company" className="block text-sm md:text-base text-[var(--graytext)]">
                      Company Name
                    </label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                          value={formData.company}
                          onChange={handleChange}
                          required
                      className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg sm:rounded-xl shadow-sm focus:border-[var(--notionblue)] focus:ring-[var(--notionblue)]"
                      />
                  </div>

                  <div className="mb-4 md:mb-6 relative">
                    <label htmlFor="partnership" className="block text-sm md:text-base text-[var(--graytext)]">
                    Partnership Type
                    </label>
                    {/* dropdown */}
                    <div className="relative w-full" ref={dropdownRef}>
                      {/* Input Field */}
                      <input
                        type="text"
                        value={selectedOptions.join(', ') || 'Select options'}
                        placeholder="Select options"
                        readOnly
                        onClick={toggleDropdown}
                        name="partnership"
                        required
                        className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg shadow-sm focus:ring-2 focus:ring-[var(--blacktext)] focus:outline-none cursor-pointer"
                      />

                      {isDropdownOpen && (
                        <div className="absolute mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                          <div className="p-4 space-y-2">
                            {['Visionary Collaboration', 'Agile Partnership', 'Integrated Team Extension', 'Full-Cycle Project Support', 'Others'].map((option) => (
                              <label key={option} className="flex items-center space-x-2">
                                <input
                                  type="checkbox"
                                  value={option}
                                  checked={selectedOptions.includes(option)}
                                  onChange={handleCheckboxChange}
                                  className="form-checkbox text-blue-500 rounded"
                                />
                                <span>{option}</span>
                              </label>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>

                  </div>



                  <div className='mb-4 md:mb-6'>
                    <label htmlFor="description" className="block text-sm md:text-base text-[var(--graytext)]">
                    Describe your project or how we can help
                    </label>
                    <textarea
                      id="description"
                      rows="4"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      required
                      className="text-sm md:text-base mt-1 block w-full px-4 py-2 rounded-lg sm:rounded-xl shadow-sm focus:border-[var(--notionblue)] focus:ring-[var(--notionblue)]"
                      />
                  </div>

                  <div className='place-self-center flex py-4 xl:py-8'>
                    <button disabled={isSubmitting} className="relative button-theme group">
                    <span className="relative px-8 py-2.5 btn-span-theme">
                    {isSubmitting ? "Submitting..." : "Submit Inquiry"}
                    </span>
                    </button>
                  </div>

                  <div className='place-items-center'>
                    <p className='text-center text-sm text-[var(--blacktext)]'>By submitting this form, you agree to our Terms & Conditions and acknowledge that we may collect and process your data as outlined in our Privacy Policy</p>
                  </div>

                </form>
              </div>
            </div>



          </div>
        </div>

      </div>


      {/* Footer */}
      <div><Footer/></div>
      {/* Footer ends */}
      {/* main div ends  */}
    </div>
  )
}

export default Partnership